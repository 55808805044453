import {Paper, Stack, Typography} from "@mui/material";
import Image from "react-bootstrap/Image";
import XDILogo from "../assets/partners/xdi.png"
import XDLFGLogo from "../assets/partners/xdlfg.png"
import XDItaliaLogo from "../assets/partners/xd_italia.png"
import {Link} from "react-router-dom";
import {useIsMobileHook} from "../utils";
import BasePage from "./BasePage";

const aboutText =
    <p>
        XDLoadout is a small project run by just one person. Initially I just threw it
        together as a personal project to
        practice some web dev skills (I'm a backend engineer professionally) but I really appreciate the support I've
        seen for the site. Since then, the project has grown and grown.
        <br/><br/>
        So far the XDefiant community has been a really positive place to be and I want this tool to
        be powered by the community so please <a style={{color: '#ddd'}}
                                                 href="https://twitter.com/XDLoadout">share</a> any
        suggestions/wishes/issues you have and I'd love to put them into action!
    </p>

const partners = [
    {
        name: "XDI.gg",
        description: "XDI is the leading competitive XDefiant resource and community. The XDI discord server is where you'll find the best and most passionate XDefiant players.",
        link: "https://xdi.gg",
        image: XDILogo
    },
    {
        name: "XDLFG",
        description: "XDLFG is a buzzing discord server and community where you can find players to party up with.",
        link: "https://discord.com/invite/XDLFG",
        image: XDLFGLogo
    },
    {
        name: "XDefiant Italia",
        description: "XDefiant Italia la community italiana di XDefiant online e su discord, attiva dal 2021, dedicata a tenere i giocatori aggiornati su novità, eventi speciali e competizioni del gioco.",
        link: "https://playxdefiant.it/",
        image: XDItaliaLogo
    }
]

const AboutPage = ({}) => {
    const isMobile = useIsMobileHook()
    return <BasePage title='About XDLoadout'>
        <Typography color='text.primary'>
            {aboutText}
        </Typography>
        <Typography variant='h5' color='text.primary' sx={{pt: '35px'}}>
            Friends & Partners
        </Typography>
        <Stack width={isMobile ? '100%' : '85%'} alignSelf='center' alignItems='stretch'>
            {partners.map((partner) =>
                <Link to={partner.link} style={{textDecoration: 'none'}}>
                    <Paper sx={{p: isMobile ? '10px' : '10px 20px', m: '10px', '&:hover': {backgroundColor: '#282B2F'}}}>
                        <Stack direction='row' minHeight='80px'>
                            <Image
                                style={{paddingRight: '10px', objectFit: 'contain'}}
                                width='80px'
                                src={partner.image}
                                fluid
                                rounded
                            />
                            <Stack>
                                <Typography variant='midHead' mb='5px'>
                                    {partner.name}
                                </Typography>
                                <Typography variant={isMobile ? 'smallHead' : null}>
                                    {partner.description}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                </Link>
            )}
        </Stack>
    </BasePage>
}

export default AboutPage