import { List, Stack, Typography } from "@mui/material";
import { ItemPaper } from "./WeaponBuilder";
import WeaponStat from "./WeaponStat";
import DamageRangeMultipliers from "./DamageRangeMultipliers";
import { useTranslation } from "react-i18next";
import { Namespaces } from "../config/localisation/Localisation";

export const allStats = [
    {
        label: "Short Range",
        unit: "m",
        getter: (w) => w.dmgRanges?.[0]?.modifier,
        attachmentOnly: true
    },
    {
        label: "Long Range",
        unit: "m",
        getter: (w) => w.dmgRanges?.[0]?.modifier,
        attachmentOnly: true
    },
    {
        label: "Damage",
        unit: "",
        getter: (w) => w.damage,
        attachmentOnly: true
    },
    {
        label: "Fire Rate",
        unit: "rpm",
        getter: (w) => w.rateOfFire,
    },
    {
        label: "ADS Time",
        unit: "ms",
        getter: (w) => w.adsTime,
        lowerIsBetter: true
    },
    {
        label: "Sprint to Fire Time",
        unit: "ms",
        getter: (w) => w.sprintToFire,
        lowerIsBetter: true
    },
    {
        label: "Move Speed",
        unit: "m/s",
        getter: (w) => w.moveSpeed,
        statRound: 2
    },
    {
        label: "ADS Move Speed",
        unit: "m/s",
        getter: (w) => w.adsMoveSpeed,
        statRound: 2
    },
    {
        label: "Mag Size",
        unit: "",
        getter: (w) => w.magSize,
        deltaType: 'absolute'
    },
    {
        label: "Reload Time",
        unit: "ms",
        getter: (w) => w.reloadTime,
        lowerIsBetter: true
    },
    {
        label: "Vertical Recoil",
        unit: "%",
        getter: (w) => w.recoil?.vertical,
        lowerIsBetter: true
    },
    {
        label: "Horizontal Recoil",
        unit: "%",
        getter: (w) => w.recoil?.horizontal,
        lowerIsBetter: true
    },
    {
        label: "Recoil Recovery",
        unit: "%",
        getter: (w) => w.recoil?.recovery,
    },
    {
        label: "Aiming Stability",
        unit: "%",
        getter: (w) => w.aimStability,
    },
    {
        label: "Hipfire Spread",
        unit: "°",
        getter: (w) => w.hipfire,
        lowerIsBetter: true,
        statRound: 1
    },
    {
        label: "Minimap Visibility Duration",
        unit: "s",
        getter: (w) => w.minimapVisibilityDuration,
        lowerIsBetter: true,
        statRound: 1
    },
    {
        label: "Bullet Penetration",
        unit: "s",
        getter: (w) => w.penetration,
    },
    {
        label: "Headshot Damage",
        unit: "",
        getter: (w) => w.headshotDamage,
        attachmentOnly: true
    },
]

const multipliers = [
    {
        label: "Head",
        unit: "x",
        getter: (w) => w.multipliers?.head ?? 1,
        statRound: 2,
    },
    {
        label: "Chest/Shoulders",
        unit: "x",
        getter: (w) => w.multipliers?.chest ?? 1,
        statRound: 2,
    },
    {
        label: "Lower Body",
        unit: "x",
        getter: (w) => w.multipliers?.body ?? 1,
        statRound: 2,
    },
]



function WeaponStats({ customWeapon, baseWeapon, noMargin }) {
    const { t } = useTranslation(Namespaces.weaponStats)
    return (
        <ItemPaper sx={{ height: { xs: 'auto', md: customWeapon == null ? 'auto' : '35vh' }, m: noMargin ? '0' : null }}>
            <Stack sx={{ height: '100%', overflowY: 'scroll' }}>
                {
                    customWeapon == null ? <Typography textAlign='center'>{t('noWeapon')}</Typography> :
                        [<DamageRangeMultipliers customWeapon={customWeapon} baseWeapon={baseWeapon} />,
                        <List dense>
                            {
                                allStats
                                    .filter((stat) => stat.attachmentOnly !== true)
                                    .map((stat) =>
                                        <WeaponStat label={t(stat.label)}
                                            unit={stat.unit}
                                            lowerIsBetter={stat.lowerIsBetter}
                                            customWeapon={customWeapon}
                                            baseWeapon={baseWeapon}
                                            getStat={stat.getter}
                                            statRound={stat.statRound}
                                            deltaType={stat.deltaType}
                                        />
                                    )
                            }
                        </List>]
                }
            </Stack>
        </ItemPaper>
    )

}

export default WeaponStats
