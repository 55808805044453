import { Stack, Typography } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import Lightweight from '../../assets/weapons/attachments/barrel_lightweight.png';
import RapidFire from '../../assets/weapons/attachments/barrel_rapidfire.png';
import MuzzleBooster from '../../assets/weapons/attachments/muzzle_muzzlebooster.png';
import BarrelExtender from '../../assets/weapons/attachments/muzzle_barrelextender.png';
import ChromeLined from '../../assets/weapons/attachments/barrel_chromelined.png';
import QuickDraw from '../../assets/weapons/attachments/grip_quickdraw.png';
import MuzzleBreak from '../../assets/weapons/attachments/muzzle_muzzlebreak.png';
import Heavy from '../../assets/weapons/attachments/barrel_heavy.png';
import SpasFolded from '../../assets/weapons/attachments/stock_folded_spas-12.png';
import MobileAd from "../../components/ads/MobileAd";
import { useIsMobileHook, weaponImageName } from "../../utils";
import { ContentWeaponImage } from "../UpdatesPage";
import DesktopHorizontalBannerAd from "../../components/ads/DesktopHorizontalBannerAd";
import WeaponSelector from "../../builder/gunsmith/WeaponSelect";
import { allAttachments } from "../../builder/AttachmentData";
import TTKGraph from "../../builder/TTKGraph";
import { DEFAULTS, SettingsContext } from "../../context/SettingsContext";
import PhantomNerf from "../../assets/updates/phantom-nerf.png";


function Buff({ title, oldTTK, newTTK, oldSTK, newSTK }) {
    const isMobile = useIsMobileHook()
    title = isMobile ? title.replaceAll('Headshots', 'HS').replaceAll('Headshot', 'HS') : title;
    return <Stack direction='row' alignItems='center' width='100%'>
        <Typography variant='content' minWidth='20%'><b>{title}:</b></Typography>
        <Typography variant='content' minWidth={isMobile ? '45%' : '20%'}>TTK: <s
            style={{ color: 'red' }}>{oldTTK}</s> > <a style={{ color: 'green' }}>{newTTK}</a> ms</Typography>
        <Typography variant='content' minWidth={isMobile ? '35%' : '20%'}>STK: <s
            style={{ color: 'red' }}>{oldSTK}</s> > <b style={{ color: 'green' }}>{newSTK}</b></Typography>
    </Stack>
}

const BuildsHere = ({ weapon }) => <><a
    href={'/meta/' + weapon.toLowerCase().replaceAll(' ', '-').replaceAll('.', '')}
    style={{ color: 'white', textDecoration: 'underline', fontSize: '1.2rem' }}>
    Builds Here
</a><br /><br /></>

export const patchUpdates = () => [
    {
        title: 'Y1S2.2 Update - Phantom Nerf',
        date: new Date(1727425942000),
        // mainImage: PlayerStats1,
        description: <>
            <img src={PhantomNerf} style={{ width: '100%'}} alt='Phantom Nerf' />
            <p>
                It's finally here! Phantom base health has been nerfed from 120 to 115. This is a welcome change for most
                players as the 20 extra health with no downsides is incredibly strong and has been the meta for a long time.
            </p>
            <p>
                As usual, I won't be breaking down other aspects of the patch, just the new base health as this has a direct
                impact on TTK.

                Below you'll find the guns that benefit most from these changes as well as a usefull tool to compare the old
                and new TTK for all weapons!
            </p>
            <p style={{ marginTop: '20px' }}>
                <i style={{ fontSize: '1.2rem', lineHeight: 2 }}><b>Biggest Winners</b></i><br />

                <ContentWeaponImage weapon='LVOA-C' />
                <b>LVOA-C</b><br />
                - Shots to Kill (0-17m) - <s style={{ color: 'red' }}>8</s> <b style={{ color: 'green' }}>7</b><br />
                - Time to Kill (0-17m) - <s style={{ color: 'red' }}>525</s> <b style={{ color: 'green' }}>450</b> ms<br />
                The LVOA-C has been a very strong AR since it was first released in Season 1, but it always had one pitfall.
                Dealing 17 damage at close range meant that it took 2 extra shots to kill a Phantom compared to a normal player.
                Now though, it only takes 1 extra, giving a very respectable TTK of 450ms vs Phantoms.

                <ContentWeaponImage weapon='M249' />
                <b>M249</b><br />
                - Shots to Kill (0-31m) - <s style={{ color: 'red' }}>6</s> <b style={{ color: 'green' }}>5</b><br />
                - Time to Kill (0-31m) - <s style={{ color: 'red' }}>500</s> <b style={{ color: 'green' }}>400</b> ms<br />
                As if it needed to get any better the M249 now doesn't care if you're a Phantom or not, it kills in 5 shots
                at close range either way! The M249 gets a 20% TTK decrease against Phantoms within 32m!

                <Stack width='100%'>
                    <MobileAd nitroSlot='patch-s2-2-phantom' />
                    <DesktopHorizontalBannerAd adSlot='patch-s2-2-phantom' />
                </Stack>

                <ContentWeaponImage weapon='M4A1' />
                <b>M4A1</b><br />
                - Shots to Kill (0-38m, 1 Headshot) - <s style={{ color: 'red' }}>7</s> <b style={{ color: 'green' }}>6</b><br />
                - Time to Kill (0-38m, 1 Headshot) - <s style={{ color: 'red' }}>497</s> <b style={{ color: 'green' }}>414</b> ms<br />
                At first glace it seems that this change doesn't affect the M4, but when you factor in headshots you can see that it
                only takes one headshot to get the same TTK for Phantoms and non-Phantoms, a blistering 414ms!

                <ContentWeaponImage weapon='RPK-74' />
                <b>RPK-74</b><br />
                - Shots to Kill (0-38m, 1 Headshot) - <s style={{ color: 'red' }}>6</s> <b style={{ color: 'green' }}>5</b><br />
                - Time to Kill (0-38m, 1 Headshot) - <s style={{ color: 'red' }}>571</s> <b style={{ color: 'green' }}>457</b> ms<br />
                Just like the M249, the RPK will also now kill Phantoms in the same number of shot as any other faction at close range,
                a 20% reduction!
            </p>
            <p style={{ marginTop: '20px' }}>
                <b style={{ fontSize: '1.2rem', lineHeight: 2 }}>Phantom Nerf TTK Tool</b><br />
                Use the tool below to compare TTK/STK pre and post-patch for all weapons!
            </p>
            <TTKCompare />
        </>
    }, {
        title: 'Season 2 Update',
        date: new Date(1727425942000),
        // mainImage: PlayerStats1,
        description: <>
            <p>
                Season 2 brings 3 new weapons as well as some balancing to attachments that really shakes up the meta,
                let's break down what it all means, starting with the updates to existing attachments.
            </p>
            <p style={{ marginTop: '20px' }}>
                <i style={{ fontSize: '1.2rem', lineHeight: 2 }}><b>Attachment Updates</b></i><br />
                <Stack direction='row' height='100px'><img src={MuzzleBooster} /><img src={RapidFire} /></Stack>
                <b>Rapid Fire Barrel</b><br />
                - <i>Added short/medium range penalty of 25 percent.</i><br />
                - <i>Removed horizontal/vertical recoil penalty of 20 percent.</i><br />
                - <i>Added ADS speed penalty of 15 percent.</i><br /><br />
                <b>Muzzle Booster</b><br />
                - <i>Added short/medium range penalty of 12.5 percent.</i><br />
                - <i>Removed horizontal/vertical recoil penalty of 10 percent.</i><br />
                - <i>Added ADS speed penalty of 7.5 percent.</i><br /><br />

                The Rapid Fire Barrel has been a staple of the meta for a long time now, but with the new changes it
                will rarely be worth the downsides. Rapid Fire was mainly used on ARs and helped the AK and ACR to really
                compete with SMGs at closer ranges while maintaining a great TTK at longer ranges. With a huge 25% nerf
                to damage range though, it creates a much bigger tradeoff forcing you to give up range as well as slowing
                down handling speeds, crucial for those fights against SMGs.<br /><br />
                <Stack width='100%'>
                    <MobileAd nitroSlot='patch-s2-attachments-1' />
                </Stack>
                The Muzzle Booster was usually not worth stacking with Rapid Fire and now it's almost never a good idea. Now
                though, the Muzzle Booster is a much more viable option on its own where the tradeoffs from the Rapid Fire barrel
                are just too much. The Muzzle Booster is still a decent option for those that want their ARs to feel a bit more
                flexible, but it's no longer a 100% must to use a Fire Rate attachment on ARs, you need to consider your playstyle
                a little more when choosing your build.<br /><br />
                In general, this is a great change for diversity in the meta. The Rapid Fire Barrel was such a no brainer on many weapons,
                and now builds should cater more to your role/playstyle. Also, since Rapid Fire and Muzzle Booster were mostly used on
                ARs and not on SMGs, this makes SMGs a lot stronger relative to the ACR and AK. It also makes the flex ARs like
                the LVOA-C and MDR really shine more in their roles. If you were previously using the AK or ACR really aggressively,
                definitely consider switching to these flex ARs or even the new PP-19 SMG (more on that below).<br /><br />
                The true victim of this change is the M16. The M16 benefitted massively from Rapid Fire, making it easier to hit all 3 shots
                of a burst on a fast moving enemy. Now though, an extra <i>-10% Headshot Damage</i> has been added to both Rapid Fire
                and Muzzle Booster, making it impossible to hit one-burst-kills using these attachments. The M16 is still great, but avoid
                these attachments.
                <Stack direction='row' height='100px'><img src={Lightweight} /></Stack>
                <b>Lightweight Barrel</b><br />
                - <i>Added short/medium range penalty of 10 percent.</i><br />
                - <i>Removed horizontal/vertical recoil penalty of 10 percent.</i><br /><br />
                With Rapid Fire out for most AR players, many will start considering the Lightweight Barrel. Now the lightweight barrel
                won't hurt your damage range, but will add recoil. If you were already using ther Rapid Fire barrel then this added recoil
                shouldn't be an issue. A good approach could be to use the Lightweight Barrel as part of a focus on making your AR snappier
                to make up for the loss in TTK from the Rapid Fire nerf.<br /><br />
                This is also a great change for SMGs since they already have short range and it's not usually a good idea to make it
                even shorter, now you would be trading that for recoil but it's an interesting option for the hyper agressive SMGs for
                whom handling is everything.
            </p>
            <Stack width='100%'>
                <MobileAd nitroSlot='patch-s2-attachments-2' />
            </Stack>
            <p style={{ marginTop: '20px' }}>
                <i style={{ fontSize: '1.2rem', lineHeight: 2 }}><b>PP-19</b></i><br />
                <ContentWeaponImage weapon='PP-19' />
                <BuildsHere weapon='PP-19' />
                The PP-19 is the first time that we get our hands on a brand new SMG. A welcome change when the meta has
                been so heavily dominated by the MP7. Is it any good though? Well... yeah it is.<br /><br />
                <b>Key Points</b><br />
                - <i>Incredible 356ms TTK out to 19m (MP7 is 353ms out to 17m)</i><br />
                - <i>Huge 50 round mag as standard</i><br />
                - <i>Slower 675rpm fire rate compared to other SMGs</i><br />
                - <i>Slower 210ms ADS time and Sprint to Fire time</i><br /><br />
                With great range comes great responsibility. The PP-19 has a great TTK and great range in the SMG class, but the slower
                rate of fire means that missing shots is especially punishing, and the slower handling speeds mean that you need to be a
                little more considered with your engagements. Just like we have the flex ARs like the LVOA-C and MDR, the PP-19 is a more
                flexible SMG that will thrive on midsized maps.<br /><br />
                Where the PP-19 shines best, in my opinion, is on the flank. Catching enemies by suprise means that the slower handling
                is less punishing and the 50 round mag means that you don't have to worry about running out of ammo when you walk around a
                corner to find 3 freebies looking the other way.<br />
                <Stack direction='row' height='80px'><img src={BarrelExtender} /><img src={MuzzleBooster} /><img src={ChromeLined} /></Stack>
                <b>Key Attachments</b><br />
                - <i><b>Barrel Extender:</b> Lean into the extra range to extend that great TTK out to 21m</i><br />
                - <i><b>Muzzle Booster:</b> Boost that fire rate and you'll be able to outgun the MP7 with a 347ms TTK</i><br />
                - <i><b>Chrome Lined Barrel:</b> Extend the range even further, or offset the range lost from the Muzzle Booster</i><br />
            </p>
            <Stack width='100%'>
                <MobileAd nitroSlot='patch-s2-pp19' />
                <DesktopHorizontalBannerAd adSlot='patch-notes-s2-pp19' />
            </Stack>
            <p style={{ marginTop: '20px' }}>
                <i style={{ fontSize: '1.2rem', lineHeight: 2 }}><b>L86</b></i><br />
                <ContentWeaponImage weapon='L86' />
                <BuildsHere weapon='L86' />
                LMGs are super strong in XDefiant, but underused due to their slow movement and handling speeds. Maybe the L86 can be the
                weapon to change that.<br /><br />
                <b>Key Points</b><br />
                - <i>Incredible 358ms TTK out to 35m (46m with one headshot!)</i><br />
                - <i>375ms ADS and Sprint to Fire time</i><br />
                - <i>Smaller mag = faster reload time (30 rounds, 3 seconds)</i><br />
                - <i>448ms TTK against Phantoms (far lower than M60, M249, ACR, AK etc.)</i><br /><br />
                The damage range stats on the L86 are fantastic and it handles much faster than its LMG counterparts,
                making the potential of this gun huge. It still handles a fair bit slower than ARs, even with attachments,
                but for a slower Main AR playstyle this gun should be very interesting.<br /><br />
                A major downside to the L86 is that it has a somewhat bouncy recoil pattern, so you may want tom use some attachments to get
                that under control if you're using it from a distance.<br />
                <Stack direction='row' height='75px' overflow='hidden'><img src={MuzzleBreak} style={{ marginRight: '8px' }} /><img src={Lightweight} /><img src={QuickDraw} /></Stack>
                <b>Key Attachments</b><br />
                - <i><b>Muzzle Break:</b> Adds important recoil stability. Sacrifices some range, but the L86 can afford it</i><br />
                - <i><b>Lightweight Barrel:</b> If you want this to feel more like an AR, the lightweight barrel will really help.</i><br />
                - <i><b>Quick Draw Grip:</b> Vital for getting that AR-like feel.</i><br />
            </p>
            <p style={{ marginTop: '20px' }}>
                <i style={{ fontSize: '1.2rem', lineHeight: 2 }}><b>SPAS-12</b></i><br />
                <ContentWeaponImage weapon='SPAS-12' />
                <BuildsHere weapon='SPAS-12' />
                Shotguns had a controversial buff in Season 1 and this new entry into the weapon class could be the strongest one yet.<br /><br />
                <b>Key Points</b><br />
                - <i>Small damage range drop off.</i><br />
                - <i>Best one-shot potential of all shotguns</i><br />
                - <i>Lots of viable builds to suit your needs</i><br />
                The damage range stats on the SPAS-12 are insane. In theory it can one shot out to 35m if all pellets hit the target, but the spread
                won't allow that. This means that you don't have to worry too much about attachments that lower range, making it a great candidate for
                a Rapid Fire + Muzzle Booster combo. If you're more focused on ensuring one-shot-kills, try the Heavy barrel out to lower the number of
                pellets that need to hit the enemy. There are also a wealth of Stock options like Removed and Folded that we <br /><br />
                <Stack direction='row' height='75px' overflow='hidden'><img src={RapidFire} /><img src={Heavy} style={{ marginRight: '8px' }} /><img src={SpasFolded} /></Stack>
                <b>Key Attachments</b><br />
                - <i><b>Rapid Fire Barrel:</b> Don't worry about the damage range, it's spread that holds this shotgun back.</i><br />
                - <i><b>Heavy Barrel:</b> If you want this to feel more like an AR, the lightweight barrel will really help.</i><br />
                - <i><b>Folded Stock:</b> Makes you look cool as fuck (and move faster)</i><br />
            </p>
        </>
    },
    {
        title: 'Weapon Balancing - Y1S1.2 Update',
        date: new Date(1721214149865),
        // mainImage: PlayerStats1,
        description: <>
            <p>
                We're finally getting our first real weapon balancing update since the release of XDefiant! With the
                Y1S1.2 update we'll see some buffs to the somewhat underused MDR, M4 and MP5 as well as further
                nerfs to
                all 3 snipers. Here I'm going to break down what the new changes will mean in terms of real ingame
                scenarios.
            </p>
            <p style={{ marginTop: '30px' }}>
                <i style={{ fontSize: '1.2rem', lineHeight: 2 }}><b>MDR</b></i><br />
                <ContentWeaponImage weapon='MDR' />
                - <i>MDR short-range damage increased from 17 to 19.</i><br />
                - <i>MDR ADS speed improved from 300ms to 250ms.</i><br /><br />
                The MDR is definitely the biggest winner in this patch. The increased ADS time speaks for itself,
                the
                MDR becomes on par with the LVOA-C for weapon handling, allowing you to free up some attachment
                slots
                that you might have been using before. The damage increase make a big difference As you can see, the
                MDR is now way more viable, especially against phantoms. The heavy barrel also becomes an
                interesting
                option to reduce the number of headshots required for a lower shot to kill.<br /><br />
                <b style={{ lineHeight: 2 }}>Against Normal Enemies (100HP) - 0-20m</b><br />
                <Buff title='1-2 Headshots' oldTTK={392} newTTK={314} oldSTK={6} newSTK={5} />
                <Buff title='3+ Headshots' oldTTK={314} newTTK={235} oldSTK={5} newSTK={4} />
                <i style={{ lineHeight: 2 }}>With Heavy Barrel</i><br />
                <Buff title='0-1 Headshots' oldTTK={436} newTTK={349} oldSTK={6} newSTK={5} />
                <Buff title='3 Headshots' oldTTK={349} newTTK={261} oldSTK={5} newSTK={4} />
                <br />
                <b style={{ lineHeight: 2 }}>Against Phantoms (120HP) - 0-20m</b><br />
                <Buff title='0 Headshots' oldTTK={549} newTTK={471} oldSTK={8} newSTK={7} />
                <Buff title='1-2 Headshots' oldTTK={471} newTTK={392} oldSTK={7} newSTK={6} />
                <Buff title='4 Headshots' oldTTK={392} newTTK={314} oldSTK={6} newSTK={5} />
                <i style={{ lineHeight: 2 }}>With Heavy Barrel</i><br />
                <Buff title='0 Headshots' oldTTK={523} newTTK={436} oldSTK={7} newSTK={6} />
                <Buff title='3-4 Headshots' oldTTK={436} newTTK={349} oldSTK={6} newSTK={5} />
            </p>
            <Stack width='100%'>
                <MobileAd nitroSlot='updates-mdr' />
            </Stack>
            <p style={{ marginTop: '30px' }}>
                <i style={{ fontSize: '1.2rem', lineHeight: 2 }}><b>M4A1</b></i><br />
                <ContentWeaponImage weapon='M4A1' />
                - <i>M4A1 short-range damage increased from 17 to 18.</i><br /><br />
                Not as game-changing as the MDR buff, but great to see another gun that becomes particularly better
                against phantoms. It's also now possible to get an incredibly low 248ms TTK with the M4 if you hit 4
                headshots!<br /><br />
                <b style={{ lineHeight: 2 }}>Against Normal Enemies (100HP) - 0-38m</b><br />
                <Buff title='2 Headshots' oldTTK={414} newTTK={331} oldSTK={6} newSTK={5} />
                <Buff title='4 Headshots' oldTTK={331} newTTK={248} oldSTK={5} newSTK={4} />
                <i style={{ lineHeight: 2 }}>With Heavy Barrel (not recommended)</i><br />
                <Buff title='1 Headshot' oldTTK={460} newTTK={368} oldSTK={6} newSTK={5} />
                <br />
                <b style={{ lineHeight: 2 }}>Against Phantoms (120HP) - 0-38m</b><br />
                <Buff title='0 Headshots' oldTTK={579} newTTK={497} oldSTK={8} newSTK={7} />
                <Buff title='2 Headshots' oldTTK={414} newTTK={414} oldSTK={7} newSTK={6} />
                <i style={{ lineHeight: 2 }}>With Heavy Barrel</i><br />
                <Buff title='1 Headshot' oldTTK={552} newTTK={460} oldSTK={7} newSTK={6} />
                <Buff title='4 Headshots' oldTTK={460} newTTK={368} oldSTK={6} newSTK={5} />
            </p>
            <Stack width='100%'>
                <MobileAd nitroSlot='updates-m4a1' />
            </Stack>
            <p style={{ marginTop: '30px' }}>
                <i style={{ fontSize: '1.2rem', lineHeight: 2 }}><b>MP5A2</b></i><br />
                <ContentWeaponImage weapon='MP5A2' />
                - <i>MP5A2 medium-range damage increased from 13 to 14.</i><br />
                - <i>Headshot-damage multiplier increased from 1.35x to 1.4x.</i><br />
                - <i>Short range increased from 16m to 18m.</i><br />
                - <i>Medium range increased from 25m to 27m.</i><br /><br />
                These changes really work on solidifying the MP5 as a longer range SMG option while also increasing
                its best TTK potential by making a 4 headshot kill possible. These changes aren't meta breaking but
                they do give the MP5 a new niche of its own.<br /><br />
                <b style={{ lineHeight: 2 }}>Against Normal Enemies (100HP) - 0-18m</b><br />
                <Buff title='4 Headshots' oldTTK={300} newTTK={225} oldSTK={5} newSTK={4} />
                <br />
                <b style={{ lineHeight: 2 }}>Against Normal Enemies (100HP) - 18-27m</b><br />
                <Buff title='1 Headshot' oldTTK={525} newTTK={450} oldSTK={8} newSTK={7} />
                <Buff title='3-4 Headshots' oldTTK={450} newTTK={375} oldSTK={7} newSTK={6} />
                <Buff title='5 Headshots' oldTTK={375} newTTK={300} oldSTK={6} newSTK={5} />
                <i style={{ lineHeight: 2 }}>With Heavy Barrel (not recommended)</i><br />
                <Buff title='0 Headshot' oldTTK={583} newTTK={500} oldSTK={8} newSTK={7} />
                <Buff title='2-3 Headshots' oldTTK={500} newTTK={417} oldSTK={7} newSTK={6} />
                <Buff title='5 Headshots' oldTTK={417} newTTK={333} oldSTK={6} newSTK={5} />
                <br />
                <b style={{ lineHeight: 2 }}>Against Phantoms (120HP) - 18-27m</b><br />
                <Buff title='0 Headshots' oldTTK={675} newTTK={600} oldSTK={10} newSTK={9} />
                <Buff title='2-3 Headshots' oldTTK={600} newTTK={525} oldSTK={9} newSTK={8} />
                <Buff title='4-5 Headshots' oldTTK={525} newTTK={450} oldSTK={8} newSTK={7} />
                <Buff title='6 Headshots' oldTTK={450} newTTK={375} oldSTK={7} newSTK={6} />
                <i style={{ lineHeight: 2 }}>With Heavy Barrel (not recommmended)</i><br />
                <Buff title='0 Headshots' oldTTK={667} newTTK={583} oldSTK={9} newSTK={8} />
                <Buff title='3-4 Headshots' oldTTK={583} newTTK={500} oldSTK={8} newSTK={7} />
                <Buff title='5 Headshots' oldTTK={583} newTTK={417} oldSTK={8} newSTK={6} />
                <Buff title='6 Headshots' oldTTK={500} newTTK={417} oldSTK={7} newSTK={6} />
            </p>
            <Stack width='100%'>
                <MobileAd nitroSlot='updates-mp5' />
            </Stack>
            <p>
                <i style={{ fontSize: '1.2rem', lineHeight: 2 }}><b>Sniper Nerfs</b></i><br />
                <ContentWeaponImage weapon='L115' />
                - <i>M44 Sprint Out Time (sprint-to-shoot time) increased from 350ms to 400ms.</i><br />
                - <i>TAC-50 Sprint Out Time increased from 600ms to 625ms.</i><br />
                - <i>L115 sprint-shoot time increased from 550ms to 575ms.</i><br />
                - <i>The sniper rifles (M44, TAC-50, and L115) got a small increase in spread when shooting from the
                    hip.</i><br /><br />
                I'm sure these will be very controversial since snipers have already received multiple flinch
                updates. I don't really think this hugely affects the viability of snipers, I think they will remain
                very strong, but it will slow down the most aggressive snipers.
            </p>
        </>
    }
]

const TTKCompare = () => {
    const [weapon, setWeapon] = useState(null)
    const { settings, setSettings } = useContext(SettingsContext)

    useEffect(() => {
        setSettings({
            ...DEFAULTS,
            weaponBreakdown: [
                {
                    label: 'Phantom Health (115)',
                    settings: {
                        ...DEFAULTS,
                        enemyHealth: 115
                    }
                },
                {
                    label: 'Old Phantom Health (120)',
                    settings: {
                        ...DEFAULTS,
                        enemyHealth: 120
                    }
                }
            ]
        })
    }, [])

    const setLoadout = (weapon) => setWeapon({
        weapon,
        attachments: weapon?.type === 'Sniper Rifle' ? { Optic: allAttachments.Optic.find(o => o.id === 'o7') } : {}
    })

    return <Stack>
        <WeaponSelector selectedLoadout={weapon} setSelectedWeapon={setLoadout} />
        <TTKGraph loadout={weapon} sections={false} />
    </Stack>
}
