import {Icon} from "@mui/material";
import Discord from '../assets/icons/discord.svg'
import Twitch from '../assets/icons/twitch.svg'
import Kick from '../assets/icons/kick.svg'
import Tiktok from '../assets/icons/tiktok.svg'
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    imageIcon: {
        display: 'flex',
        height: 'inherit',
        width: 'inherit'
    },
    iconRoot: {
        textAlign: 'center'
    }
});

const BaseIcon = ({sx, alt, src}) => {
    const classes = useStyles();
    return <Icon classes={{root: classes.iconRoot}} sx={sx}>
        <img draggable={false} src={src} className={classes.imageIcon} alt={alt}/>
    </Icon>
}
export const DiscordIcon = ({sx}) => <BaseIcon sx={sx} src={Discord} alt='discord'/>

export const TwitchIcon = ({sx}) => <BaseIcon sx={{...sx, pt: '1px', fontSize: '1.25rem'}} src={Twitch} alt='twitch'/>

export const KickIcon = ({sx}) => <BaseIcon sx={{...sx, p: '0 3px'}} src={Kick} alt='kick'/>
export const TiktokIcon = ({sx}) => <BaseIcon sx={{...sx, fontSize: '1.4rem'}} src={Tiktok} alt='tiktok'/>