import {useIsMobileHook} from "../../utils";
import React from "react";
import {Stack} from "@mui/material";
import AdsenseComponent from "./AdsenseComponent";
import {useLocation} from "react-router-dom";
import NitroAd, {AdSizes} from "./NitroAds";

export const MobileSlots = {
    RESPONSIVE_1: "2583969452",
    HORIZONTAL_1: "6167991248",
    HORIZONTAL_2: "6906357848",
    HORIZONTAL_3: "1654031165",
    HORIZONTAL_4: "3597328918",
    HORIZONTAL_5: "9472811438",
    ANCHOR: "3254145184"

}

const MobileAd = ({slot = '9823505111', m = '5px 0', nitroSlot = 'not-defined', nitroSize = AdSizes.MobileBanner}) => {
    const location = useLocation()
    const isMobile = useIsMobileHook()
    return isMobile ?
        <Stack width='100vw' alignItems='center' justifyContent='center' alignSelf='center' minHeight='10px' m={m} >
            { window.config?.nitro ? <NitroAd key={`${slot}-${location.key}`} adSlot={'mobile-' + nitroSlot} adSizes={nitroSize}/>
                : <AdsenseComponent adClient="ca-pub-9475011578827400" key={`${slot}-${location.key}`} adSlot={slot} mobile={slot !== MobileSlots.RESPONSIVE_1}/>}
        </Stack>
        : null
}

export default MobileAd