import React from "react";
import {UserContextProvider} from "./UserContext";
import {FeedbackContextProvider} from "./UserFeedback";
import {SettingsContextProvider} from "./SettingsContext";

const RootContextProvider = ({children}) => {
    return <UserContextProvider>
        <FeedbackContextProvider>
            <SettingsContextProvider>
                {children}
            </SettingsContextProvider>
        </FeedbackContextProvider>
    </UserContextProvider>
}

export default RootContextProvider