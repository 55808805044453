import {Stack} from "@mui/material";
import {allAttachments} from "../AttachmentData";
import {ItemPaper} from "../WeaponBuilder";
import {useEffect, useState} from "react";
import GunsmithBottomBar from "./GunsmithBottomBar";
import GunsmithTopBar from "./GunsmithTopBar";
import GunsmithEditor from "./GunsmithEditor";

const Gunsmith = ({
                      setSelectedWeapon,
                      setSelectedAttachments,
                      dialogOpen,
                      setDialogOpen,
                      savedLoadouts,
                      selectedLoadout,
                      setSavedLoadouts,
                      setSelectedLoadout
                  }) => {
    const [availableAttachments, setAvailableAttachments] = useState([])

    useEffect(() => {
        if (selectedLoadout?.weapon?.attachments == null) {
            setAvailableAttachments([]);
            return
        }
        let filteredAttachments = {...allAttachments}
        Object.keys(filteredAttachments)
            .forEach(type => filteredAttachments[type] = filteredAttachments[type].filter((a) => selectedLoadout.weapon.attachments.includes(a.id)))
        setAvailableAttachments(filteredAttachments)
    }, [selectedLoadout])

    return (
        <ItemPaper sx={{height: {xs: 'auto', md: '35vh'}}}>
            <Stack sx={{height: '100%'}}>
                <GunsmithTopBar {...{dialogOpen, setDialogOpen, setSelectedWeapon, savedLoadouts, selectedLoadout, setSavedLoadouts, setSelectedLoadout, setSelectedAttachments}} />
                <GunsmithEditor selectedLoadout={selectedLoadout}
                                availableAttachments={availableAttachments}
                                setSelectedAttachments={setSelectedAttachments}/>
                <GunsmithBottomBar selectedLoadout={selectedLoadout}
                                   setSelectedAttachments={setSelectedAttachments}
                                   availableAttachments={availableAttachments}/>
            </Stack>
        </ItemPaper>
    )
}

export default Gunsmith;
