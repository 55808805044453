import { Stack, Typography } from "@mui/material";
import Chart from "chart.js/auto";
import annotationPlugin from "chartjs-plugin-annotation";
import zoomPlugin from "chartjs-plugin-zoom";
import i18n from "i18next";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Actions, userStatsSession } from "../../../api/user/UserStatsSession";
import CrownIcon from "../../../assets/icons/crown.png";
import { Namespaces } from "../../../config/localisation/Localisation";
import { UserContext } from "../../../context/UserContext";
import { useIsMobileHook } from "../../../utils";
import { Panel } from "../../StatTrackPage";
import MatchSummary, { getPerGameStat, matchResult } from "./session/MatchSummary";
import { SimpleButton, StatsButtons, StatsTrackingContext } from "./StatsTracking";
import { getStat, Stats, StatsPanel, StatsSpans } from "./UserStats";
import DesktopHorizontalBannerAd from './../../../components/ads/DesktopHorizontalBannerAd';

const enrich = (matches) => matches.map(m => ({
    ...m,
    ...Object.entries(m.matches.gamemodes).filter(e => (e[1].losses + e[1].wins) > 0).map(e => ({
        gamemode: e[0],
        victory: e[1].wins - e[1].losses
    }))[0] ?? { gamemode: 'Unknown', victory: 0 },
    left: m.matches.completed.left > 0,
    updated: new Date(m.updated * 1000).getTime()
}))

export function SessionPanel() {
    const { stats, setStats } = useContext(StatsTrackingContext)
    const { user } = useContext(UserContext)
    const { t } = useTranslation(Namespaces.playerStats)

    const [statsUpdate, setStatsUpdate] = useState()
    const [updateTimer, setUpdateTimer] = useState()
    const [currentStat, setCurrentStat] = useState(Stats.find(s => s.label === 'kills'))

    const setTimer = (time = 60 * 3) => setUpdateTimer({
        timer: time,
        interval: time > 0 ? setTimeout(() => setTimer(time - 1), 1000) : null
    })

    useEffect(() => {
        if (stats.currentSession?.active) {
            if (statsUpdate == null) {
                setStatsUpdate(
                    setInterval(() => userStatsSession(user.id, Actions.update).then(r => setStats(r)),
                        // 1000 * 20))
                        1000 * 60 * 3))
            }
            if (updateTimer == null) setTimer()
        }
        if (stats.currentSession?.active !== true) {
            if (statsUpdate != null) clearInterval(statsUpdate)
            if (updateTimer != null) {
                clearTimeout(updateTimer?.interval)
                setUpdateTimer(null)
            }
        }
    }, [stats, statsUpdate])

    const startStatsSession = () => userStatsSession(user.id, Actions.start).then(r => setStats(r))
    const endStatsSession = () => userStatsSession(user.id, Actions.end).then(r => setStats(r))

    const sessionActive = stats.currentSession != null;
    const session = sessionActive ? stats.currentSession : stats.sessionHistory ? stats.sessionHistory[stats.sessionHistory.length - 1] : null

    return <>

        <Panel>
            <Typography variant='small' textAlign='center'>{t('trackingSessionNote')}</Typography>
        </Panel>

        <Panel>
            <Stack direction='row' justifyContent='space-between'>
                <Typography
                    variant='midHead'>{t(sessionActive ? 'currentSession' : 'lastSession')}</Typography>
                {stats.sessionHistory == null && sessionActive ? null :
                    <Stack direction='row' alignItems='center' color='white'>
                        {sessionActive ?
                            <>
                                <SimpleButton text='endSession' onClick={endStatsSession} noMargin />
                                <Typography variant='smallMid' m='0 10px'>Session
                                    started: {new Date(session.start * 1000).toLocaleTimeString()}</Typography>
                                {updateTimer?.timer ? <Typography variant='smallHead' ml='10px'>{t('nextUpdate')}: {Math.floor(updateTimer.timer / 60)}:{(updateTimer.timer % 60) < 9 ? '0' : ''}{updateTimer.timer % 60}</Typography> : null}
                            </> :
                            <>
                                <SimpleButton text='startSession' onClick={startStatsSession} noMargin />
                                <Typography variant='smallHead' ml='10px'>{`${t('lastSession')}: ${new Date(session.end * 1000).toLocaleString()}`}</Typography>
                            </>
                        }
                    </Stack>
                }
            </Stack>
            <SessionGraph stats={enrich(session.matches)} currentStat={currentStat} latest={stats.latest} />
            <StatsButtons stat={currentStat} setStat={setCurrentStat} filters={['session']} />
        </Panel>

        <Panel>
            <Typography variant='midHead'>Session Stats</Typography>
            {session.overall != null ?
                <StatsPanel isOwnProfile stats={stats} statsSpan={StatsSpans.Session} setStatsSpan={() => {
                }} session={session} /> : null}
        </Panel>
        <DesktopHorizontalBannerAd adSlot='session-tracking'/>
        <Panel>
            <MatchSummary matches={enrich(session.matches)} latest={stats.latest} />
        </Panel>
    </>
}

const CrownImage = () => {
    const image = new Image(30, 30);
    image.src = CrownIcon;
    return image
}

export const CrownPoint = CrownImage()

const SessionGraph = ({ stats, currentStat, latest }) => {
    const isMobile = useIsMobileHook()
    const { t } = useTranslation(Namespaces.playerStats)

    const graphRef = useRef()


    useEffect(() => {
        Chart.register(zoomPlugin, annotationPlugin)
        const chart = new Chart(graphRef.current, {
            type: 'bar',
            data: {
                labels: stats.map(v => new Date(v.updated).toLocaleTimeString().substring(0, 5)),
                datasets: [
                    {
                        label: 'mvp',
                        type: 'line',
                        data: stats.map(s => s.mvp.count > 0 ? s : { updated: s.updated }),
                        // data: yValues.map(currentStat.graphValue ?
                        //     y => currentStat.graphValue(currentStat.getter(y))
                        //     : currentStat.getter),
                        // pointRadius: 6,
                        pointStyle: CrownPoint,
                        // backgroundColor: 'rgb(243,203,7)',
                        // borderColor: stats.map(s => s.victory > 0 ? 'rgb(1,73,10)' : s.victory < 0 ? 'rgb(152,0,0)' : 'rgb(15,98,155)'),
                        borderWidth: 0,
                        // yAxisID: 'y',
                    },
                    {
                        label: t(currentStat.label),
                        data: stats,
                        // data: yValues.map(currentStat.graphValue ?
                        //     y => currentStat.graphValue(currentStat.getter(y))
                        //     : currentStat.getter),
                        backgroundColor: stats.map(s => s.victory > 0 ? 'rgba(38,178,48,0.5)' : s.victory < 0 || s.left ? 'rgba(250, 0, 0, 0.3)' : 'rgba(17,123,197, 0.3)'),
                        borderColor: stats.map(s => s.victory > 0 ? 'rgb(1,73,10)' : s.victory < 0 || s.left ? 'rgb(152,0,0)' : 'rgb(15,98,155)'),
                        borderWidth: 2,
                        // yAxisID: 'y',
                    },

                    // {
                    //     data: yValues.map(y => y.matches.completed.total - yValues[0].matches.completed.total),
                    //     borderColor: 'red',
                    //     fill: true,
                    //     yAxisID: 'y1',
                    //
                    // }
                ]
            },
            options: {
                parsing: {
                    xAxisKey: 'updated',
                    yAxisKey: currentStat.path,
                },
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    // point: {
                    //     pointStyle: 'line',
                    //     radius: 0
                    // }
                },
                interaction: {
                    intersect: false,
                    axis: 'x'
                },
                plugins: {
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            title: (ctx) => `${ctx[0].raw.gamemode} - ${ctx[0].label}`,
                            afterTitle: (ctx) => matchResult(ctx[0].raw),
                            label: (context) => context.dataset.label === 'mvp' ? null
                                : `${context.dataset.label}: ${currentStat.revGraphValue ? currentStat.revGraphValue(context.parsed.y)
                                    : Math.round(context.parsed.y * 1000) / 1000}`,
                        },
                        usePointStyle: true,
                    },
                    legend: {
                        display: false,
                        position: 'bottom',
                        labels: {
                            pointStyle: 'line',
                            usePointStyle: true,
                            color: '#ccc'
                        }
                    },
                    title: { display: false },
                    zoom: {
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            pinch: {
                                enabled: true
                            },
                            mode: 'x',
                        },
                        pan: {
                            enabled: true,
                        },
                        // limits: {
                        //     x: {min: 0, max: 150},
                        //     y: {min: 0, max: 5000}
                        // }
                    },
                    annotation: {
                        annotations: {
                            line1: {
                                type: 'line',
                                display: true,
                                yMin: getPerGameStat(latest, currentStat) ?? getStat(latest, currentStat),
                                yMax: getPerGameStat(latest, currentStat) ?? getStat(latest, currentStat),
                                borderColor: 'rgba(255, 0, 0, 0.5)',
                                opacity: '75%',
                                borderWidth: 3,
                                z: -100,
                                label: {
                                    content: t('career'),
                                    display: true,
                                    position: 'start',
                                    yAdjust: 0
                                }
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: t('date'),
                            color: '#ccc'
                        },
                        grid: {
                            color: '#333'
                        },
                        ticks: {
                            // callback: (label) => new Date(label * 1000).toLocaleTimeString(),
                            color: '#ccc'
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: `${t(currentStat.label)} ${currentStat.unit ? `(${currentStat.unit})` : ''}`,
                            color: '#ccc'
                        },
                        grid: {
                            color: '#666'
                        },
                        ticks: {
                            callback: (label, index, labels) => currentStat.revGraphValue == null ? Math.round(label * 1000) / 1000 : currentStat.revGraphValue(label),
                            color: '#ccc'
                        }
                    },
                    // y1: {
                    //     type: 'linear',
                    //     display: true,
                    //     position: 'right',
                    //
                    //     // grid line settings
                    //     grid: {
                    //         drawOnChartArea: false, // only want the grid lines for one axis to show up
                    //     }
                    // }
                }
            }
        })
        return () => {
            chart.destroy()
        }
    },
        [stats, currentStat, i18n.resolvedLanguage]
    )

    return <div style={{ marginTop: '10px', height: isMobile ? 300 : 500, width: isMobile ? 325 : '100%' }}>
        <canvas ref={graphRef} id="user-stats-chart" style={{ width: 750 }}></canvas>
    </div>
}

export const StatsGraphPanel = (props) =>
    <Stack p='10px' m='10px 0' backgroundColor='rgb(0,0,0,0.25)'
        borderRadius='15px' border='1px solid #aaa'
        width='calc(100% - 22px)'>
        <SessionGraph {...props} />
    </Stack>

export default SessionGraph