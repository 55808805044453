import { Avatar, Skeleton, Stack, ToggleButtonGroup, Typography } from "@mui/material";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { getUserProfile } from "../api/user/UserProfile";
import { numberFormatter, useIsMobileHook } from "../utils";
import { MainGradient } from "../App";
import { useNavigate, useParams } from "react-router-dom";
import { Namespaces } from "../config/localisation/Localisation";
import { useTranslation } from "react-i18next";
import { PlayerProgression } from "./profile/stats/UserStats";
import BasePage from "./BasePage";
import { LinkStatsDialog } from "./profile/stats/AddStats";
import DesktopHorizontalBannerAd from "../components/ads/DesktopHorizontalBannerAd";
import MobileAd from "../components/ads/MobileAd";
import { LightToggleButton } from "../builder/TTKGraph";
import { UserContext } from "../context/UserContext";
import { AdSizes } from "../components/ads/NitroAds";
import { InputIcon } from "./profile/UserProfile"

const LeaderboardCategories = {
    rank: {
        name: 'rank',
    },
    level: {
        name: 'level',
    },
    kills: {
        name: 'kills',
        season: true,
    },
    kdRatio: {
        name: 'kdRatio',
        season: true,
    },
    wins: {
        name: 'wins',
        season: true,
    },
    mvps: {
        name: 'mvps',
        season: true,
    }
}

const LeaderboardsPage = () => {
    const isMobile = useIsMobileHook()
    const { category, subcategory } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation(Namespaces.playerStats)
    const [dialogOpen, setDialogOpen] = useState(false)
    return <BasePage title={t('leaderboardsTitle')}>
        <ToggleButtonGroup
            orientation={isMobile ? 'vertical' : 'horizontal'}
            value={category}
            exclusive
            onChange={(e, category) => category != null ? navigate(`/leaderboards/${category}${LeaderboardCategories[category].season ? '/season' : ''}`) : null}
            aria-label="leaderboard-category-select"
            sx={isMobile ? { mb: '15px', maxHeight: '50px', flexWrap: 'wrap', borderRadius: '5px', overflow: 'clip' } : { alignSelf: 'center', height: '30px', mb: '15px' }}
        >
            {Object.values(LeaderboardCategories).map(cat =>
                <LightToggleButton sx={{ height: '10px' }} value={cat.name} aria-label={cat.name}>
                    {t(cat.name + 'Title')}
                </LightToggleButton>
            )}
        </ToggleButtonGroup>
        <Typography variant='smallHead' color='white' fontStyle='oblique' mb='5px' width='100%'>{t('leaderboardLink') + ' '}
            <a style={{ color: '#ccc', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => setDialogOpen(true)}>{t('how')}</a></Typography>
        <Leaderboards category={category} subcategory={subcategory}/>
        <LinkStatsDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
    </BasePage>
}

function renderValue(data, category) {
    if (category === 'kdRatio') return Math.round(data[category] * 100) / 100
    if (category === 'rank') return data[category]
    if (category === 'level') return ''
    return numberFormatter.format(data[category])
}

const LeaderboardProfile = ({ data, category }) => {
    const isMobile = useIsMobileHook()
    const navigate = useNavigate()
    const { user } = useContext(UserContext)
    return <Stack direction='row'
        onClick={() => navigate(`/profile/${data.user.profileUrl}`)}
        sx={{
            p: isMobile ? '5px 10px' : '5px 20px',
            m: '5px 0',
            mb: user?.id === data.id ? '10px' : null,
            alignItems: 'center',
            justifyContent: 'space-between',
            color: 'white',
            backgroundImage: MainGradient,
            borderRadius: '8px',
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            boxShadow: user?.id === data.id ? '0 0 10px white' : '0 0 5px #141414',
            '&:hover': { boxShadow: '0 0 5px #aaa' }
        }}>
        <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
            <Stack direction='row' alignItems='center'>
                <Typography variant='midHead' fontStyle='normal' fontWeight='normal' mr={isMobile ? '5px' : '15px'}
                    minWidth='30px'>{data.position}.</Typography>
                <Avatar src={data.user.icon} sx={{
                    height: 36,
                    width: 36,
                    boxShadow: isMobile ? '0 0 6px 0 white' : '0 0 10px 0 white',
                    mr: '15px'
                }} />
                <Typography variant={isMobile ? 'smallMidHead' : 'midHead'} fontWeight='normal'
                    mr='10px'>{data.user.displayName}</Typography>
                    <InputIcon input={data.user.input} />
                {isMobile ? null : <PlayerProgression stats={{
                    rank: category === 'rank' ? null : data.rank,
                    level: category === 'level' ? null : data.level
                }} small={isMobile} />}
                <Stack display={isMobile ? 'none' : null}>
                    {data.user.loadouts?.length > 0 ? <Typography variant='small' sx={{
                        p: '3px 5px',
                        borderRadius: '5px',
                        mt: '2px',
                        backgroundColor: 'rgb(0,0,0,0.3)',
                        textAlign: 'center'
                    }}>View Loadouts</Typography> : null}
                </Stack>
            </Stack>
            <Stack direction={isMobile ? 'column' : 'row'} alignItems='center' minWidth='80px'>
                {['rank', 'level'].includes(category) ? <PlayerProgression stats={{
                    rank: category === 'rank' ? data.rank : null,
                    level: category === 'level' ? data.level : null
                }} small={isMobile} /> : null}
                <Typography variant={isMobile ? 'small' : 'smallMidHead'} ml={isMobile ? null : '10px'}
                    minWidth={isMobile ? null : category === 'level' ? '50px' : '120px'}>{renderValue(data, category)}</Typography>
            </Stack>
        </Stack>
    </Stack>
}

const Leaderboard = ({ users, category, subcategory = 'career' }) => {
    const { t } = useTranslation(Namespaces.playerStats)
    const isMobile = useIsMobileHook()

    return <Stack alignItems='center' width='100%' p='15px 25px 20px' m='5px' backgroundColor='rgba(0,0,0,0.3)'
        borderRadius='5px' position='relative'>
        <SubcategorySelector category={category} subcategory={subcategory}/>
        <Typography variant='midHead' p='0 5px' alignSelf='start'>{t(category + 'Title')}</Typography>
        <Stack mt='5px' width={isMobile ? '100%' : 'calc(100% - 30px)'}>
            {users === 'loading' ? <LeaderboardSkeleton /> :
                <Stack mt='5px'>
                    {users.user && users.user.position !== -1 ? <LeaderboardProfile data={users.user} category={category} /> : null}
                    {users.entries.flatMap((p, i) => [
                        i > 0 && i % 20 === 0 ?
                            isMobile ? <MobileAd nitroSlot={`leaderboard-${category}-${i / 20}`} nitroSize={AdSizes.MobileLarge} /> :
                                <DesktopHorizontalBannerAd adSlot={`leaderboard-${category}-${i / 20}`} />
                            : null,
                        <LeaderboardProfile data={p} category={category} />])}
                </Stack>
            }
        </Stack>
        {/*<Stack mt='10px' p='5px 15px' width='fit-content' border='2px solid #999' borderRadius='5px'*/}
        {/*       onClick={() => setExpanded(expanded + 1)}*/}
        {/*       sx={{*/}
        {/*           display: expanded * pageSize >= section.profiles.length ? 'none' : null,*/}
        {/*           cursor: 'pointer',*/}
        {/*           '&:hover': {backgroundColor: 'rgba(60,60,60,0.5)'}*/}
        {/*       }}>*/}
        {/*    <Typography fontSize='0.9rem' color='white'>{t('seeMore')}</Typography>*/}
        {/*</Stack>*/}
    </Stack>
}

const Leaderboards = ({ category, subcategory }) => {
    const { t } = useTranslation(Namespaces.playerStats)
    const [leaderboardData, setLeaderboardData] = useState('loading')

    useEffect(() => {
        setLeaderboardData('loading')
        getUserProfile('leaderboards', category, subcategory)
            .then(data => setLeaderboardData(data))
    }, [category, subcategory])

    return leaderboardData === 'failed' ?
        <Stack alignItems='center'>
            <Typography color='white'>{t('failedGet')}</Typography>
        </Stack>
        :
        <Stack width='100%' alignItems='center'>
            <Leaderboard users={leaderboardData} category={category} subcategory={subcategory}/>
        </Stack>
}

const LeaderboardSkeleton = () => <Stack width='100%'>
    <Skeleton variant="rounded" height='50px' sx={{ mb: '15px' }} />
    <Skeleton variant="rounded" height='50px' sx={{ mb: '15px' }} />
    <Skeleton variant="rounded" height='50px' sx={{ mb: '15px' }} />
</Stack>

export default LeaderboardsPage

function SubcategorySelector({category, subcategory}) {
    const { t } = useTranslation(Namespaces.playerStats)
    const navigate = useNavigate()
    const isMobile = useIsMobileHook()

    return LeaderboardCategories[category].season ? <Stack position='absolute' top='10px' right='10px'>
        <ToggleButtonGroup
            value={subcategory}
            exclusive
            onChange={(e, sub) => sub != null ? navigate(`/leaderboards/${category}${sub === 'season' ? '/season' : ''}`) : null}
            aria-label="leaderboard-subcategory-select"
            sx={isMobile ? { mb: '15px', maxHeight: '50px', flexWrap: 'wrap', borderRadius: '5px', overflow: 'clip' } : { alignSelf: 'center', height: '30px', mb: '15px' }}
        >
            {['season', 'career'].map(sub => <LightToggleButton sx={{ height: '10px' }} value={sub} aria-label={sub}>
                {t(sub)}
            </LightToggleButton>
            )}
        </ToggleButtonGroup>
    </Stack> : null
}
