import {Stack, Typography} from "@mui/material";
import _ from 'underscore'
import {allWeapons} from "./WeaponData";
import {ItemPaper} from "./WeaponBuilder";
import LoadSavedLoadouts from "./gunsmith/LoadSavedLoadouts";
import {SaveWeaponInput} from "./gunsmith/GunsmithTopBar";
import {WeaponImage} from "../components/WeaponImage";
import WeaponSelector from "./gunsmith/WeaponSelect";
import {Namespaces} from "../config/localisation/Localisation";
import {useTranslation} from "react-i18next";

export const weaponsByClass = _.groupBy(allWeapons, (weapon) => weapon.type);

const MobileWeapons = ({selectedLoadout, setSelectedWeapon, setSelectedLoadout, savedLoadouts, setSavedLoadouts}) => {
    const {t} = useTranslation(Namespaces.weapons)
    return (
        <ItemPaper height={{xs: 'auto', md: '30vh'}}>
            <Stack height='100%' alignItems='center'>
                <Stack width='100%' direction='row' justifyContent='space-between' pb='5px'>
                    <Typography fontSize='1.25rem' lineHeight={1.1}>{t('weapons')}</Typography>
                    <LoadSavedLoadouts setSelectedLoadout={setSelectedLoadout} setSelectedWeapon={setSelectedWeapon} setSavedLoadouts={setSavedLoadouts} savedLoadouts={savedLoadouts}/>
                </Stack>
                <WeaponSelector selectedLoadout={selectedLoadout} setSelectedWeapon={setSelectedWeapon}/>
                <WeaponImage weapon={selectedLoadout?.weapon?.name} style={{
                    margin: '5px 0',
                    maxHeight: '80px',
                    width: '100%',
                    objectFit: 'contain'
                }}/>
                <SaveWeaponInput savedLoadouts={savedLoadouts} setSavedLoadouts={setSavedLoadouts}
                                 selectedLoadout={selectedLoadout} setSelectedLoadout={setSelectedLoadout}/>
            </Stack>
        </ItemPaper>
    )
}

export default MobileWeapons;
