import {ListItem, Typography} from "@mui/material";

export const displayDelta = (delta, isPercentage) => {
    return (Math.sign(delta) > 0 ? '+' : '') + delta + (isPercentage ? '%' : '')
}

const roundTo = (number, places) => Math.round(number * Math.pow(10, places ?? 0)) / Math.pow(10, places ?? 0)
const deltaRoundTo = (number, places) => roundTo(number, places ?? 1)

export function getStatColor(lowerIsBetter, delta) {
    return (lowerIsBetter === true ? Math.sign(delta) < 0 : Math.sign(delta) > 0) ? '#32CD32' : '#FF0800';
}

function WeaponStat({label, unit, lowerIsBetter, customWeapon, baseWeapon, getStat, statRound, deltaType, multiplier}) {
    let customStat = getStat(customWeapon);
    let baseStat = getStat(baseWeapon);
    let delta = deltaType === 'absolute' ?
        customStat - baseStat
        : (customStat - baseStat) * 100 / baseStat


    let positive = Math.sign(delta)
    let color = getStatColor(lowerIsBetter, delta)
    return (
        <ListItem sx={{p: '3px 12px'}}>
            <Typography variant='small' paddingRight='10px'>
                {label}:
            </Typography>
            {
                positive === 0 ? null :
                    <Typography variant='small' paddingRight='5px' sx={{color: '#888', textDecoration: 'line-through'}}>
                        {baseStat}{unit}
                    </Typography>
            }
            <Typography variant='small' paddingRight='10px'>
                {roundTo(customStat, statRound)}{unit}
            </Typography>
            {
                positive === 0 ? null :
                    <Typography variant='small' sx={{color}}>
                        {
                            displayDelta(roundTo(delta, 1), deltaType !== 'absolute')
                        }
                    </Typography>
            }
        </ListItem>
    )
}

export default WeaponStat;
