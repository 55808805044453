import * as React from 'react';
import {useContext} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {
    Avatar,
    Badge,
    Box,
    CircularProgress,
    Grow,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    Typography
} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {Bookmark, Logout, Person} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";
import {DiscordIcon} from "./components/Icons";
import {logoutUser} from "./auth/AuthHandler";
import {UserContext} from "./context/UserContext";
import {Namespaces} from "./config/localisation/Localisation";
import {useTranslation} from "react-i18next";


export const Screens = {
    Login: "login",
    Signup: "signup",
    Notifications: "notifications"
}

export const LoginScreen = () => {
    const {t} = useTranslation(Namespaces.misc)
    return <Stack alignItems='center'>
        <Typography textAlign='center' sx={{mb: '10px'}}>
            {t('loginMain')}
        </Typography>
        <Button sx={{backgroundColor: '#5865F2', color: 'white', width: '250px'}}
                href={process.env.REACT_APP_DISCORD_AUTH_URL}>
            <Stack direction='row'>
                <DiscordIcon sx={{mr: '8px'}}/>
                {t('loginWithDiscord')}
            </Stack>
        </Button>
        <Typography textAlign='center' sx={{mt: '10px', fontSize: '0.9rem'}}>
            {t('loginPrivacy')} <a href='/privacy-policy.html' style={{color: 'white'}}>{t('privacyPolicy')}</a>.
        </Typography>
    </Stack>
}

export const userProfilePath = (user) => `/profile/${user?.profileUrl ?? user?.id}`

export const LoginButton = () => {
    const {t} = useTranslation(Namespaces.misc)
    const navigate = useNavigate()
    const {user, setUser, userLoading} = useContext(UserContext)

    const [modalOpen, setModalOpen] = React.useState(false)
    const [screen, setScreen] = React.useState(Screens.Login)
    const [userNotifs, setUserNotifs] = React.useState(0)

    const [anchorEl, setAnchorEl] = React.useState(null)
    const userMenuOpen = Boolean(anchorEl)

    // useEffect(() => {
    // if (userContext.id != null) {
    //     console.log(userContext.id)
    //     getInvitesByUser(userContext.id).then(response => response.json().then(response => {
    //         console.log(response);
    //         setUserNotifs(response.invites)
    //     }))
    // }
    // }, [userContext.id]);

    const handleUserClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleUserMenuClose = () => {
        setAnchorEl(null);
    };

    const handleClickOpen = (screen) => {
        setScreen(screen)
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    const handleLogout = () => {
        logoutUser()
        setUser(null)
        handleUserMenuClose()
        navigate('/')
    }

    const getScreen = (currentScreen) => {
        switch (currentScreen) {
            case Screens.Login:
                return <LoginScreen/>
            // case Screens.Signup:
            // return <SignupScreen setScreen={setScreen} closeModal={closeModal}/>
            // case Screens.Notifications:
            // return <NotificationScreen userNotifs={userNotifs} closeModal={closeModal}/>
        }
    }

    return (
        <Stack>
            {
                user != null ?
                    <IconButton
                        onClick={handleUserClick}
                        color="inherit"
                        size="small"
                        aria-controls={userMenuOpen ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={userMenuOpen ? 'true' : undefined}
                    >
                        <Badge color="error" badgeContent={userMenuOpen ? 0 : userNotifs.length}>
                            <Avatar src={user.icon} sx={{height: 35, width: 35, boxShadow: '0 0 10px 0 white'}}/>
                        </Badge>
                    </IconButton>
                    : userLoading ? <CircularProgress color='inherit' size='1.5rem'/>
                        : <AccountCircleIcon fontSize='large' onClick={() => handleClickOpen(Screens.Login)}/>
                // <Button color="inherit" onClick={() => handleClickOpen(Screens.Login)}>Login</Button>
            }
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={userMenuOpen}
                onClose={handleUserMenuClose}
                onClick={handleUserMenuClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <Link to={userProfilePath(user)} style={{textDecoration: 'none'}}>
                    <MenuItem sx={{color: 'white'}}>
                        <ListItemIcon>
                            <Badge color="error" badgeContent={userNotifs.length}>
                                <Person fontSize="small" sx={{color: '#CCC'}}/>
                            </Badge>
                        </ListItemIcon>
                        {t('profile')}
                    </MenuItem>
                </Link>
                <Link to={`/bookmarks`} style={{textDecoration: 'none'}}>
                    <MenuItem sx={{color: 'white'}}>
                        <ListItemIcon>
                            <Bookmark fontSize="small" sx={{color: '#CCC'}}/>
                        </ListItemIcon>
                        {t('bookmarks')}
                    </MenuItem>
                </Link>
                {/*<MenuItem onClick={handleUserMenuClose}>*/}
                {/*    <ListItemIcon>*/}
                {/*        <Settings fontSize="small" sx={{color: '#CCC'}}/>*/}
                {/*    </ListItemIcon>*/}
                {/*    Settings*/}
                {/*</MenuItem>*/}
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" sx={{color: '#CCC'}}/>
                    </ListItemIcon>
                    {t('logout')}
                </MenuItem>
            </Menu>
            <Dialog open={modalOpen} onClose={closeModal} fullWidth maxWidth="xs" sx={{transition: 'height 2s'}}>
                <Grow in={modalOpen}>
                    <Box component="form"
                         sx={{
                             display: "flex",
                             flexDirection: "column",
                             padding: '20px',
                             transition: 'height 2s'
                         }}>
                        {getScreen(screen)}
                    </Box>
                </Grow>
            </Dialog>
        </Stack>
    );
}

export default LoginButton