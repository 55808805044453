import {Stack, Typography} from "@mui/material";
import * as React from "react";
import {useContext, useState} from "react";
import {API_URL} from "../api/API";
import {LoginScreen} from "../LoginButton";
import {useIsMobileHook} from "../utils";
import {UserContext} from "../context/UserContext";

const aboutText =
    <p>
        {/*If you have an XDLoadout early access code, enter your code below with your discord username.<br/>*/}
        {/*Important: make sure to use your <i>username</i>, not display name.*/}
        XDLoadout Early-Access is over, logging in with discord is now public to all!
    </p>

const inputStyles = (value) => {
    return {
        margin: '3px',
        minWidth: {xs: '150px', md: '180px'},
        input: {
            color: value == null ? '#999' : 'white',
            fontSize: '0.9rem'
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'white'
            }
        }
    }
}

const redeem = (code, username, setResult) => fetch(API_URL + 'redeem-code', {
    method: 'POST',
    body: JSON.stringify({
        code,
        username
    }),
}).then((response) => {
    if (response.ok) {
        setResult('SUCCESS')
        return true
    }
    return response.json().then(response => {
        throw new Error(response.code)
    })
}).catch(e => {console.log(e.message); setResult(e.message)})

const strings = {
    'SUCCESS': 'Early access redeemed successfully!',
    'USERNAME_INVALID': 'Invalid discord username.',
    'ACCESS_CODE_INVALID': 'Invalid access code.'
}

const RedeemCodePage = () => {
    const [username, setUsername] = useState(null)
    const [accessCode, setAccessCode] = useState(null)
    const [focusedInput, setFocusedInput] = useState(null)
    const [result, setResult] = useState(null)
    const isMobile = useIsMobileHook()
    const {user, userLoading} = useContext(UserContext)

    return (
        <Stack width='100%' alignItems='center' mt='30px'>
            <Stack width='60%' alignItems='center'>
                <Typography variant='h5' color='text.primary' fontWeight='bold' fontStyle='oblique' textAlign='center'>
                    Redeem Early Access Code
                </Typography>
                <Typography color='text.primary' textAlign='center'>
                    {aboutText}
                </Typography>
                <Stack display={user || userLoading ? 'none' : null} width={isMobile ? '95%':'80%'} alignItems='center' justifyContent='center' m={isMobile ? '5px' : '20px'}>
                    <h2 style={{
                        textAlign: 'center',
                        color: 'white',
                        fontSize: '1.2rem',
                        lineHeight: '1.25',
                        fontWeight: 'normal',
                        margin: '15px 0',
                        fontStyle: 'oblique',
                        display: isMobile ? 'none' : null
                    }}>
                        Create a profile to save your own loadouts and save your favourite meta builds from other users!
                    </h2>
                    <Stack p='10px 20px' borderRadius='5px' backgroundColor='rgba(0,0,0,0.35)' color='white'>
                        <LoginScreen/>
                    </Stack>
                </Stack>
                {/*<Stack width='50%'>*/}
                {/*    <TextField size='small' variant='outlined'*/}
                {/*               value={username ?? (focusedInput === 'username' ? '' : 'Discord Username')}*/}
                {/*               onFocus={() => setFocusedInput('username')}*/}
                {/*               onBlur={() => setFocusedInput(focusedInput === 'username' ? null : focusedInput)}*/}
                {/*               onChange={e => {*/}
                {/*                   let newUsername = e.target.value.trim()*/}
                {/*                       .replaceAll('@', '')*/}
                {/*                       .replaceAll('/', '')*/}
                {/*                       .replaceAll('<', '')*/}
                {/*                       .replaceAll('>', '')*/}
                {/*                   newUsername = newUsername === '' ? null : newUsername*/}
                {/*                   setUsername(newUsername)*/}
                {/*               }}*/}
                {/*               sx={inputStyles(username)}*/}
                {/*               error={result === 'USERNAME_INVALID'}*/}
                {/*    />*/}
                {/*    <TextField size='small' variant='outlined'*/}
                {/*               value={accessCode ?? (focusedInput === 'accessCode' ? '' : 'Access Code')}*/}
                {/*               onFocus={() => setFocusedInput('accessCode')}*/}
                {/*               onBlur={() => setFocusedInput(focusedInput === 'accessCode' ? null : focusedInput)}*/}
                {/*               onChange={e => {*/}
                {/*                   let newAccessCode = e.target.value.trim()*/}
                {/*                       .replaceAll('@', '')*/}
                {/*                       .replaceAll('/', '')*/}
                {/*                       .replaceAll('<', '')*/}
                {/*                       .replaceAll('>', '')*/}
                {/*                   newAccessCode = newAccessCode === '' ? null : newAccessCode*/}
                {/*                   setAccessCode(newAccessCode)*/}
                {/*               }}*/}
                {/*               sx={inputStyles(accessCode)}*/}
                {/*               error={result === 'ACCESS_CODE_INVALID'}*/}
                {/*    />*/}
                {/*    <Button variant="contained" onClick={() => redeem(accessCode, username, setResult)}>*/}
                {/*        Get Early Access*/}
                {/*    </Button>*/}
                {/*    {*/}
                {/*        result == null ? null :*/}
                {/*            <Typography mt='8px' textAlign='center' color={result === 'SUCCESS' ? 'green' : 'red'}>{strings[result]}</Typography>*/}
                {/*    }*/}
                {/*</Stack>*/}
            </Stack>
        </Stack>
    )
}

export default RedeemCodePage