import {IconButton, InputBase, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import ShareLoadout from "../share/ShareLoadout";
import {Save} from "@mui/icons-material";
import {generateLoadoutId} from "../WeaponBuilder";
import LoadSavedLoadouts from "./LoadSavedLoadouts";
import WeaponSelector from "./WeaponSelect";
import {profanityFilter} from "../../utils";
import {useTranslation} from "react-i18next";
import {Namespaces} from "../../config/localisation/Localisation";

export const SaveWeaponInput = ({
                             savedLoadouts,
                             selectedLoadout,
                             setSavedLoadouts,
                             setSelectedLoadout
                         }) => {
    const {t} = useTranslation(Namespaces.builder)
    const [nameInput, setNameInputState] = useState('')
    const [inputError, setInputError] = useState(false)
    const setNameInput = (name) => {
        name = name.replace(/[^\w\sà-üÀ-Ü\-._!]+/g, '').substring(0, 20)
        setNameInputState(name)
        setInputError(false)
    }

    const saveWeapon = () => {
        if (profanityFilter.exists(nameInput)) {
            setNameInput(profanityFilter.censor(nameInput))
            setInputError(true)
            return
        }
        if (selectedLoadout?.weapon == null || savedLoadouts.length > 49 || nameInput.trim().length < 1) {
            setInputError(true)
            return
        }
        let newLoadout = selectedLoadout?.name === nameInput.trim() ?
            {
                ...selectedLoadout,
                attachments: selectedLoadout?.attachments,
                loadoutId: generateLoadoutId(selectedLoadout)
            }
            :
            {
                weapon: selectedLoadout.weapon,
                attachments: selectedLoadout?.attachments,
                name: nameInput.trim(),
                id: crypto.randomUUID(),
                loadoutId: generateLoadoutId(selectedLoadout)
            }
        const newSavedLoadouts = savedLoadouts.filter(savedLoadout => savedLoadout.name !== newLoadout.name || savedLoadout.id !== newLoadout.id)
        newSavedLoadouts.push(newLoadout)
        setSavedLoadouts(newSavedLoadouts)
        setSelectedLoadout(newLoadout)
        setNameInput("")
    }

    useEffect(() => setNameInput(selectedLoadout?.name ?? ''),[selectedLoadout])

    return <Stack direction="row" sx={{display: selectedLoadout?.weapon == null ? 'none' : null}}>
        <InputBase id="loadout-name" value={nameInput}
                   placeholder={t("loadoutName")}
                   autoComplete='off'
                   onChange={(e) => setNameInput(e.target.value)}
                   sx={{
                       maxWidth: '220px',
                       fontStyle: "oblique",
                       fontWeight: "bold",
                       fontSize: "1.2rem",
                       textAlign: 'right',
                       ml: "8px",
                       "& label.Mui-focused": {
                           color: "white",
                       },
                       "& .MuiOutlinedInput-root": {
                           "&.Mui-focused fieldset": {
                               borderColor: "white"
                           }
                       }
                   }} error={inputError}/>
        <IconButton onClick={saveWeapon} sx={{p: "5px"}}>
            <Save sx={{color: "white"}}/>
        </IconButton>
    </Stack>;
}

const GunsmithTopBar = (props) => {
    const {
        setSelectedWeapon, dialogOpen, setDialogOpen, savedLoadouts,
        selectedLoadout, setSavedLoadouts, setSelectedLoadout, setSelectedAttachments
    } = props

    const saveWeaponProps = {
        savedLoadouts,
        selectedLoadout,
        setSavedLoadouts,
        setSelectedLoadout
    }


    return <Stack sx={{width: '100%'}} direction='row' justifyContent='space-between'>
        <Stack direction='row'>
            <WeaponSelector selectedLoadout={selectedLoadout} setSelectedWeapon={setSelectedWeapon}/>
            <LoadSavedLoadouts {...{savedLoadouts, setSelectedWeapon, setSelectedAttachments, setSelectedLoadout, setSavedLoadouts}}/>
        </Stack>
        <SaveWeaponInput {...saveWeaponProps}/>
        <Stack sx={{display: selectedLoadout?.weapon == null ? 'none' : null}} direction='row' alignItems='center'>
            <Stack direction='row' sx={{
                pt: '3px',
                transition: 'filter 0.3s ease',
                '&:hover': {
                    filter: 'drop-shadow(0px 0px 5px rgb(200 200 200))',
                    background: 'none'
                }
            }}>
                <ShareLoadout label loadout={selectedLoadout}
                              weapon={selectedLoadout?.weapon} attachments={selectedLoadout?.attachments}
                              open={dialogOpen} setOpen={setDialogOpen}/>
            </Stack>
        </Stack>
    </Stack>
}

export default GunsmithTopBar
