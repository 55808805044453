import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {IconButton, MobileStepper, Stack} from "@mui/material";
import SwipeableViews from 'react-swipeable-views';
import {ProfileLoadout} from "../ProfileLoadouts";
import {useEffect, useState} from "react";
import {useIsMobileHook} from "../../../utils";

const FavouriteLoadouts = ({loadouts, isOwnProfile}) => {
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = Math.min(5, loadouts.length);
    const isMobile = useIsMobileHook()

    useEffect(() => {
        if (activeStep >= maxSteps) setActiveStep(Math.max(0, maxSteps - 1))
    }, [maxSteps])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    if (loadouts.length < 1) return null

    return (
        <Stack sx={{m: '30px 0 40px 0', width: '100%', position: 'relative'}}>
            <SwipeableViews
                axis='x'
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                style={{zIndex: 1}}
            >
                {loadouts.map((loadout, index) => (
                    Math.abs(activeStep - index) <= 2 ? (
                        <Stack key={`favourite-loadout-${loadout.id}`} style={{padding: '0 5px'}}>
                            <ProfileLoadout isOwnProfile={isOwnProfile} loadout={loadout} hideButtons={['delete']}/>
                        </Stack>
                    ) : null
                ))}
            </SwipeableViews>
            <MobileStepper
                sx={{
                    padding: 0,
                    color: 'white',
                    backgroundColor: 'transparent',
                    position: 'absolute',
                    top: '40%',
                    right: {xs: '0', md: '-7%'},
                    width: {xs: '100%', md: '114%'},
                    height: 'calc(60% + 5px)',
                    alignItems: 'flex-end'
                }}
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    isMobile ? <div/> : <IconButton onClick={handleNext} disabled={activeStep === maxSteps - 1} sx={{
                        alignSelf: 'start', '&:hover': {
                            filter: 'drop-shadow(0px 0px 6px rgb(200 200 200))',
                            background: 'none'
                        }
                    }}>
                        <KeyboardArrowRight sx={{color: 'white', fontSize: '3rem'}}/>
                    </IconButton>
                }
                backButton={
                    isMobile ? <div/> : <IconButton onClick={handleBack} disabled={activeStep === 0} sx={{
                        alignSelf: 'start', '&:hover': {
                            filter: 'drop-shadow(0px 0px 6px rgb(200 200 200))',
                            background: 'none'
                        }
                    }}>
                        <KeyboardArrowLeft sx={{color: 'white', fontSize: '3rem'}}/>
                    </IconButton>
                }
            />
        </Stack>
    )
}

export default FavouriteLoadouts