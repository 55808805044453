import {Stack, Typography} from "@mui/material";
import * as React from "react";
import ProfileUrlField from "./ProfileUrlField";
import {useTranslation} from "react-i18next";
import {Namespaces} from "../../../config/localisation/Localisation";

const UserProfilePanel = () => {
    const {t} = useTranslation(Namespaces.profiles)
    return <Stack sx={{backgroundColor: 'rgba(0, 0, 0, 0.2)', p: {xs: '5px 10px', md: '5px 15px'}, m: {xs: 0, md: '5px'}, borderRadius: '10px'}}>
        <Typography sx={{color: '#ccc', fontSize: {xs: '0.7rem', md: '0.8rem'}, textAlign: 'center', lineHeight: {xs: '0.8rem', md: 'auto'}}}>
            {t('userPanelText')}
        </Typography>
        <ProfileUrlField/>
    </Stack>
}

export default UserProfilePanel