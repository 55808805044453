import {API_URL} from "../API";
import {decodeWeapons} from "../../builder/WeaponBuilder";

export const getLoadout = (id) =>
    fetch(`${API_URL}loadout/${id}`)
        .then(response => {
            if (response.ok) return response.json()
            throw new Error(`Response status was ${response.status}`)
        })
        .then(l => decodeWeapons([l])[0])

export const getLoadouts = (ids) =>
    fetch(`${API_URL}loadouts?ids=${ids.reduce((a, b) => `${a}${a && b ? ',' : ''}${b}`, '')}`)
        .then(response => {
            if (response.ok) return response.json()
            throw new Error(`Response status was ${response.status}`)
        })
        .then(l => decodeWeapons(l))