import {API_URL} from "../API";

export const likeLoadout = (loadoutId) => loadoutActions(loadoutId, 'like')
export const unlikeLoadout = (loadoutId) => loadoutActions(loadoutId, 'unlike')
export const bookmarkLoadout = (loadoutId) => loadoutActions(loadoutId, 'bookmark')
export const unbookmarkLoadout = (loadoutId) => loadoutActions(loadoutId, 'unbookmark')

const loadoutActions = (loadoutId, action) =>
    fetch(`${API_URL}loadouts/${loadoutId}?action=${action}`, {
        method: 'PUT',
        credentials: 'include'
    }).then((response) => {
        if (response.ok) {
            return response.json()
        }
        return response.json().then(response => {
            throw new Error(response.code)
        })
    })