import {Box, CircularProgress, Stack, ToggleButtonGroup, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getMetaLoadouts} from "../../api/API";
import {ProfileLoadout} from "../../pages/profile/ProfileLoadouts";
import MobileAd, {MobileSlots} from "../../components/ads/MobileAd";
import {Namespaces} from "../../config/localisation/Localisation";
import {useTranslation} from "react-i18next";
import {useIsMobileHook} from "../../utils";
import {LightToggleButton} from "../TTKGraph";
import {useLocation} from "react-router-dom";
import NitroAd, {AdSizes} from "../../components/ads/NitroAds";

const Loadouts = ({title, loadouts}) => {
    const {t} = useTranslation(Namespaces.meta)
    const isMobile = useIsMobileHook()
    return loadouts?.length < 1 ? null : <Stack width='calc(100% - 20px)'>
        <Typography variant='midHead' textAlign={isMobile ? 'center' : 'left'}>
            {t(title)}
        </Typography>
        {loadouts.map((loadout) => <ProfileLoadout isOwnProfile={false} loadout={loadout} showImage={false}/>)}
    </Stack>
}

const LoadoutsSelector = ({title, loadouts}) => {
    const {t} = useTranslation(Namespaces.meta)
    const isMobile = useIsMobileHook()
    const [selected, setSelected] = useState(0)
    return loadouts?.length < 1 ? null : <Stack width='calc(100% - 20px)'>
        <Stack position='relative' width='100%' m={isMobile ? '5px 0' : '10px 0'}>
            <Typography variant='midHead' textAlign={isMobile ? 'center' : 'left'}>{t(title)}</Typography>
            <Stack position={isMobile ? null : 'absolute'} width='100%' alignItems='center'>
                {loadouts.length > 1 ? <ToggleButtonGroup
                    value={selected}
                    exclusive
                    onChange={(e, i) => {
                        if (i != null) setSelected(i)
                    }}
                    aria-label="meta-category-select"
                    sx={{height: '30px', mb: isMobile ? 0 : '10px'}}
                >
                    {loadouts.filter(l => l.label).map((l, i) =>
                        <LightToggleButton value={i} aria-label="left aligned">
                            {t(l.label)}
                        </LightToggleButton>)}
                </ToggleButtonGroup> : null }
            </Stack>
        </Stack>
        <ProfileLoadout isOwnProfile={false} loadout={loadouts[selected]} showImage={false}/>
    </Stack>
}

const FeaturedLoadouts = ({weapon, category}) => {
    const location = useLocation()
    const isMobile = useIsMobileHook()
    const [loadouts, setLoadouts] = useState("loading")

    useEffect(() => {
        if (weapon == null) return
        getMetaLoadouts(weapon, category)
            .then(response => setLoadouts(response))
    }, [weapon, category])

    if (loadouts === "loading") return <CircularProgress/>

    return (
        <Stack alignItems='center' width='100%'>
            <Box sx={{
                p: '5px',
                m: {xs: 0, md: '5px 10px'},
                maxWidth: {xs: '90%', md: '70%'},
                borderRadius: '3px',
                backgroundColor: 'rgba(0,0,0,0.2)',
                display: loadouts.description ? null : 'none'
            }}>
                <Typography textAlign='center' fontStyle='oblique'
                            fontSize={{xs: '0.8rem', md: '1rem'}}>
                    {loadouts.description}
                </Typography>
            </Box>
            <MobileAd slot={MobileSlots.HORIZONTAL_5} nitroSlot={`meta-${category}-weapon-1`}/>
            <LoadoutsSelector title='xdlRecommended' loadouts={loadouts.xdl}/>
            {isMobile ? null : <Stack height='100px' alignItems='center' alignSelf='center' justifyContent='center' width='100%' mb='5px'>
                <NitroAd key={`featured-desk-${location.key}`} adSlot='desktop-meta-weapon' adSizes={AdSizes.DesktopHorizontalBanner}/>
            </Stack>}
            {loadouts.xdl?.length < 1 || loadouts.featured?.length < 1 ? null :
                <MobileAd nitroSlot={`meta-${category}-weapon-2`} nitroSize={AdSizes.MobileLarge}/>}
            <Loadouts title='featuredLoadouts' loadouts={loadouts.featured}/>
        </Stack>
    )
}
export default FeaturedLoadouts