import {useIsMobileHook} from "../../utils";
import {useLocation} from "react-router-dom";
import {Stack} from "@mui/material";
import AdsenseComponent from "./AdsenseComponent";
import React from "react";
import NitroAd, {AdSizes} from "./NitroAds";

const MobileAnchorAd = () => {
    const isMobile = useIsMobileHook()
    const location = useLocation()
    return isMobile ? <Stack>
            <Stack width='100vw' minHeight='100px'/>
            {window.config?.anchor ? <NitroAd key={`anchor-${location.key}`} adSlot='mobile-anchor-auto' adSizes={AdSizes.NitroAnchor}/>
                : <Stack sx={{
                    position: 'fixed',
                    bottom: '0',
                    left: '0',
                    width: '100vw',
                    height: '100px',
                    zIndex: 9999,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    WebkitBackdropFilter: 'blur(2px)',
                    backdropFilter: 'blur(2px)',
                    overflow: 'clip'
                }}>
                    {window.config?.nitro ?
                        <NitroAd key={`anchor-${location.key}`} adSlot='mobile-anchor' adSizes={AdSizes.MobileAnchor}/>
                        : <AdsenseComponent adClient="ca-pub-9475011578827400" adSlot="3254145184"
                                            key={`mobile-anchor-${location.key}`} mobile={true}/>}
                </Stack>
            }
        </Stack>
        : null
}

export default MobileAnchorAd