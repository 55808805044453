import * as React from "react";
import {createContext, useEffect, useRef, useState} from "react";
import {Alert, Snackbar} from "@mui/material";

export const FeedbackContext = createContext(null)

export const FeedbackType = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error'
}

export const FeedbackContextProvider = ({children}) => {
    const [text, setText] = useState('')
    const [type, setType] = useState(FeedbackType.INFO)
    const [semaphore, setSemaphore] = useState(0)
    const semaphoreRef = useRef(0);

    useEffect(() => {
        semaphoreRef.current = semaphore;
    }, [semaphore]);

    const triggerFeedback = (text, type = FeedbackType.INFO) => {
        setText(text)
        setType(type)
        setSemaphore(semaphore + 1)
        setTimeout(() => {setSemaphore(Math.max(0, semaphoreRef.current - 1))}, 5000)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return
        setSemaphore(0)
    }

    return <FeedbackContext.Provider value={{triggerFeedback}}>
        {children}
        <Snackbar
            open={semaphore > 0}
            onClose={handleClose}
        >
            <Alert
                onClose={handleClose}
                severity={type}
                variant="filled"
                sx={{width: '100%'}}
            >
                {text}
            </Alert>
        </Snackbar>
    </FeedbackContext.Provider>

}