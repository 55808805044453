import {useState} from "react";
import {IconButton, List, ListItem, ListItemButton, ListItemText, Stack, Typography} from "@mui/material";
import GlowButton from "../../components/GlowButton";
import {FolderOpen} from "@mui/icons-material";
import {Overlay} from "../../components/Overlay";
import DeleteIcon from "@mui/icons-material/Delete";
import {WeaponImage} from "../../components/WeaponImage";
import {useIsMobileHook} from "../../utils";
import {useTranslation} from "react-i18next";
import {Namespaces} from "../../config/localisation/Localisation";

const LoadSavedLoadouts = ({
                               savedLoadouts,
                               setSelectedWeapon,
                               setSelectedLoadout,
                               setSavedLoadouts
                           }) => {
    const {t} = useTranslation(Namespaces.builder)

    const deleteWeapon = (loadout, event) => {
        const i = savedLoadouts.findIndex(l => l.id === loadout.id)
        savedLoadouts.splice(i, 1)
        setSavedLoadouts([...savedLoadouts])
        setSelectedWeapon(null)
        event.stopPropagation()
    }

    const isMobile = useIsMobileHook()
    const [overlayActive, setOverlayActive] = useState(false)

    return <Stack direction='row' sx={{alignItems: 'center'}}>
        <GlowButton label={t('loadSavedLoadouts')} icon={<FolderOpen/>} onClick={() => setOverlayActive(true)}/>
        <Overlay active={overlayActive} setActive={setOverlayActive}>
            <Stack sx={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                <Stack sx={{
                    width: {xs: '90%', md:'40%'},
                    maxWidth: {xs: '85%', md: '700px'},
                    maxHeight: {xs: '80%', md:'60%'},
                    p: {xs: '10px 15px', md: '20px 25px'},
                    borderRadius: '5px',
                    backgroundColor: 'background.paper'
                }}>
                    <Typography fontSize='1.25rem'>{t('savedLoadouts')}</Typography>
                    <Typography fontSize='0.7rem' fontStyle='oblique'>
                        {
                            savedLoadouts.length > 0 ?
                                t('selectALoadout') : t('noSavedLoadouts')
                        }
                    </Typography>
                    {
                        <List sx={{
                            width: '100%',
                            flexGrow: 1,
                            maxHeight: '100%',
                            overflowY: 'scroll',
                        }}
                        >
                            {
                                Object.values(savedLoadouts).map(l => {
                                        return <ListItemButton
                                            key={`saved-loadout-${l.id}`}
                                            onClick={(e) => {
                                                if (e.target.checked == null) {
                                                    setSelectedLoadout(l)
                                                }
                                            }}
                                            sx={{padding: '5px'}}
                                        >
                                            <ListItem secondaryAction={
                                                <IconButton edge="end" aria-label="delete"
                                                            onClick={(e) => deleteWeapon(l, e)}>
                                                    <DeleteIcon sx={{color: 'text.secondary', mr: '10px'}}/>
                                                </IconButton>
                                            }
                                            >
                                                {/*<Checkbox checked={isShownOnGraph(l)}*/}
                                                {/*          sx={{color: 'white'}}*/}
                                                {/*          onChange={() => toggleFromGraph(l)}/>*/}
                                                <Stack
                                                    sx={{flexGrow: 0, minWidth: '25%', mr: '20px', alignItems: 'center'}}>
                                                    <WeaponImage sillhouette weapon={l.weapon.name} style={{
                                                        height: 'auto',
                                                        maxHeight: isMobile ? '30px' : '35px',
                                                        maxWidth: isMobile ? '80px' :'110px'
                                                    }}/>
                                                </Stack>
                                                <ListItemText primary={l.name} secondary={l.weapon.name}
                                                              sx={{flexGrow: 0, textWrap: 'nowrap'}}/>
                                            </ListItem>
                                        </ListItemButton>;
                                    }
                                )
                            }
                        </List>
                    }
                </Stack>
            </Stack>
        </Overlay>
    </Stack>
}

export default LoadSavedLoadouts