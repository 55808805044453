import { Skeleton, Stack, Typography } from "@mui/material";
import * as React from "react";
import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CareerImage from '../../../assets/careernav.png';
import SessionImage from '../../../assets/sessionnav.png';
import { Namespaces } from "../../../config/localisation/Localisation";
import { UserContext } from "../../../context/UserContext";
import { useIsMobileHook } from "../../../utils";
import { Panel } from './../../StatTrackPage';
import CareerPanel from "./CareerPanel";
import { SessionPanel } from "./SessionPanel";
import { LoginScreen } from "../../../LoginButton";
import UserStats, { Stats } from "./UserStats";

const numberOfGamesToTargetKd = (kills, deaths, kpg, targetKd, avgKd) => ((targetKd * deaths) - kills) / (kpg * (avgKd - targetKd))
const avgKdToTargetKd = (kills, deaths, kpg, targetKd, games) => (kpg * games * targetKd) / (kills + (kpg * games) - (targetKd * deaths))

export const StatsButtons = ({ setStat, stat, filters = [] }) =>
    <Stack direction='row' width='100%' flexWrap='wrap' alignItems='center' justifyContent='center'>
        {Stats.filter(s => filters.find(f => (s.filters ?? []).includes(f)) == null).map(s => <SimpleButton
            onClick={() => setStat(s)}
            selected={stat === s} text={s.label}
            size='small' key={s.label + '-button'} />)}
    </Stack>

function Target({ data }) {
    const targetKD = Math.round(data.latest.kdr * 100) / 100 + 0.05
    const avgKd = 2;
    const kpg = data.latest.kills.killsPerGame;
    const kills = data.latest.kills.total;
    const deaths = kills / data.latest.kdr;
    const games = 50;

    return <Stack>
        <Typography variant='mid'>Improve K/D to {targetKD}: Average
            a {avgKdToTargetKd(kills, deaths, kpg, targetKD, games)} in your next {games} games</Typography>
        <Typography variant='mid'>Improve K/D to {targetKD}: Average a {avgKd} K/D
            for {numberOfGamesToTargetKd(kills, deaths, kpg, targetKD, avgKd)} games</Typography>
    </Stack>
}


export const SimpleButton = ({ onClick, selected, text, size = 'normal', suffix = '', noMargin = false }) => {
    const Sizes = {
        small: { p: '3px', m: '3px', variant: 'small' },
        normal: { p: '5px', m: '5px' },
    }
    const { t } = useTranslation(Namespaces.playerStats)
    return <Typography sx={{ ...Sizes[size], cursor: 'pointer', userSelect: 'none', ...noMargin ? { margin: 0 } : {} }}
        backgroundColor={selected ? 'rgba(50,50,50,0.2)' : 'rgba(0,0,0,0.2)'}
        borderRadius='5px'
        boxShadow={selected ? '0 0 3px #ccc' : null}
        variant={Sizes[size].variant}
        color='white'
        onClick={onClick}>
        {t(text) + suffix}
    </Typography>;
}

const StatsTracking = () => {
    const { user, userLoading } = useContext(UserContext)
    const { t } = useTranslation(Namespaces.playerStats)
    const { page } = useParams()

    const statsData = user?.profile?.stats?.latest

    return <>
        {page !== 'session' && page !== 'career' && <h1 style={{ margin: '5px 0', color: 'white', fontSize: '1.5rem', fontStyle: 'oblique', textAlign: 'center', textShadow: '0 0 15px black' }}>
            {t('trackingMainTitle')}
        </h1>}
        <Panel>
            <Typography variant='smallHead' textAlign='center'>
                {(page === 'session' ? t('trackingSessionDesc')
                    : page === 'career' ? t('trackingCareerDesc')
                        : t('trackingMainDesc')).split('|').map((line, i) =>
                            <>
                                {i !== 0 && <><br /><br /></>}
                                {line}
                            </>)}

            </Typography>
        </Panel>
        {userLoading ? <StatsSkeleton /> :
            user == null ? <Login />
                : statsData ? <Stack alignItems='center' width='100%'>
                    <StatTrackingContextProvider>
                        <TrackerNav page={page} />
                        {page === 'session' && <SessionPanel />}
                        {page === 'career' && <CareerPanel />}
                    </StatTrackingContextProvider>
                </Stack> : <UserStats isOwnProfile={true} />
        }
    </>

}

const Login = () => {
    const { t } = useTranslation(Namespaces.playerStats)
    const isMobile = useIsMobileHook()
    return <Stack width={isMobile ? '95%' : '80%'} alignItems='center' justifyContent='center'>
        <Stack p='10px 20px' mt='10px' borderRadius='5px' backgroundColor='rgba(0,0,0,0.35)' color='white'>
            <LoginScreen />
        </Stack>
    </Stack>
}

const TrackerNav = ({ page }) => {
    const isMobile = useIsMobileHook()
    const { t } = useTranslation(Namespaces.playerStats)
    const navigate = useNavigate()
    return page ? <Stack direction='row'>
        {/* <SimpleButton text={t('careerTracker')} selected={page === 'career'}
            onClick={() => navigate('/stats/career')} /> */}
        {/* <SimpleButton text={t('sessionTracker')} selected={page === 'session'}
            onClick={() => navigate('/stats/session')} /> */}
    </Stack> :
        <Stack>
            {['career'].map(pg =>
                <Stack width={isMobile ? 'calc(100% - 50px)' : '500px'} justifyContent='center' alignItems='center'
                    sx={{
                        m: '10px 5px', p: '15px 20px', borderRadius: '10px', backgroundColor: 'rgba(0,0,0,0.3)',
                        boxShadow: '0 0 5px transparent', transition: 'box-shadow 0.3s ease', '&:hover': { boxShadow: '0 0 5px rgba(255,255,255, 0.8)' }
                    }}
                    onClick={() => navigate('/stats/' + pg)}>
                    <img src={pg === 'career' ? CareerImage : SessionImage} alt={pg + ' icon'} style={{ width: '100%', height: 'auto', borderRadius: '5px', outline: '1px solid #555' }} />
                    <Typography variant='midHead' textAlign='center' mt='15px'>{t(pg + 'Tracker')}</Typography>
                </Stack>)}
            <div style={{ position: 'relative' }}>
                <Typography position='absolute' fontSize='1.8rem' fontStyle='oblique' color='white' width='100%' top='40%' textAlign='center' sx={{ textShadow: '0 0 10px black, 0 0 10px black, 0 0 20px black' }} zIndex={5}>Coming Soon</Typography>
                <Stack width={isMobile ? 'calc(100% - 54px)' : '500px'} justifyContent='center' alignItems='center'
                    sx={{
                        m: '10px 5px', p: '15px 20px', borderRadius: '10px', backgroundColor: 'rgba(0,0,0,0.3)',
                        opacity: 0.5, border: '2px dashed white'
                    }}>
                    <img src={SessionImage} alt={'session icon'} style={{ width: '100%', height: 'auto', borderRadius: '5px', outline: '1px solid #555' }} />
                    <Typography variant='midHead' textAlign='center' mt='15px'>{t('sessionTracker')}</Typography>
                </Stack>
            </div>
        </Stack>
}

export const StatsTrackingContext = createContext(null)

const StatTrackingContextProvider = ({ children }) => {
    const { user } = useContext(UserContext)
    const [stats, setStats] = useState(user.profile.stats)

    return <StatsTrackingContext.Provider value={{ stats, setStats }}>
        {children}
    </StatsTrackingContext.Provider>
}

const StatsSkeleton = () => <Stack width='100%'>
    <Skeleton variant="rounded" height='50px' sx={{ mb: '15px' }} />
    <Skeleton variant="rounded" height='50px' sx={{ mb: '15px' }} />
    <Skeleton variant="rounded" height='50px' sx={{ mb: '15px' }} />
</Stack>

export default StatsTracking