import {List, ListItem, ListItemButton, ListItemText, Stack, Tooltip, Typography} from "@mui/material";
import {ItemPaper} from "../WeaponBuilder";
import ShareLoadout from "../share/ShareLoadout";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Remove';
import RemoveIcon from '@mui/icons-material/Close';

import {useEffect, useState} from "react";
import {AttachmentEffects} from "../share/AttachmentsSummary";
import {RandomiseButton} from "../MobileWeapons";
import {lowerOffset, randomInt} from "../../utils";
import {allAttachments} from "../AttachmentData";
import RandomiseIcon from "@mui/icons-material/Casino";
import {Namespaces} from "../../config/localisation/Localisation";
import {useTranslation} from "react-i18next";

export const displayAttachmentName = (attachment) => attachment.name.split('[')[0].trim()
export const slotsFull = (selectedAttachments) => Object.values(selectedAttachments).filter((s) => s != null).length > 4;

function MobileAttachments({setSelectedAttachments, selectedLoadout, dialogOpen, setDialogOpen}) {
    const [openSlot, setOpenSlot] = useState()
    const [availableAttachments, setAvailableAttachments] = useState([])

    const handleAttachmentSelection = (attachment, slot) => {
        if (selectedLoadout.attachments[slot] === attachment) {
            selectedLoadout.attachments[slot] = null
        } else {
            if (slotsFull(selectedLoadout.attachments) && selectedLoadout.attachments[slot] == null) return
            selectedLoadout.attachments[slot] = attachment;
        }
        setSelectedAttachments({...selectedLoadout.attachments})
    };
    const RandomiseButton = () => {
        const {t} = useTranslation(Namespaces.builder)
        return <Tooltip title={t('randomAttachments')} slotProps={lowerOffset}>
            <RandomiseIcon onClick={() => {
                const slots = Object.keys(availableAttachments).filter((key) => availableAttachments[key].length > 0)
                while (slots.length > 5) {
                    slots.splice(randomInt(slots.length), 1);
                }

                const attachments = {}
                slots.forEach((slot) => attachments[slot] = availableAttachments[slot][randomInt(availableAttachments[slot].length)])
                setSelectedAttachments({...attachments})
            }} sx={{
                transition: 'filter 0.3s ease',
                '&:hover': {
                    filter: 'drop-shadow(0px 0px 6px rgb(200 200 200))',
                    background: 'none'
                }
            }}/>
        </Tooltip>;
    }
    const removeAttachment = (slot) => {
        selectedLoadout.attachments[slot] = null
        setSelectedAttachments({...selectedLoadout.attachments})
    }

    useEffect(() => {
        if (selectedLoadout?.weapon?.attachments == null) {
            setAvailableAttachments([]);
            return
        }
        let filteredAttachments = {...allAttachments}
        Object.keys(filteredAttachments)
            .forEach(type => filteredAttachments[type] = filteredAttachments[type].filter((a) => selectedLoadout.weapon.attachments.includes(a.id)))
        setAvailableAttachments(filteredAttachments)
    }, [selectedLoadout?.weapon])

    const toggleSlot = (slot) => openSlot === slot ? setOpenSlot(null) : setOpenSlot(slot)
    const {t} = useTranslation(Namespaces.attachments)

    if (selectedLoadout?.weapon == null) return null

    return (
        <ItemPaper>
            <Stack sx={{height: '100%', width: '100%', position: 'relative', overflow: 'hidden'}}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Stack direction='row' alignItems='center'>
                    <Typography variant='h6' sx={{pl: '5px', mr: '10px'}}>Attachments</Typography>
                        <RandomiseButton/>
                    </Stack>
                    <ShareLoadout loadout={selectedLoadout} open={dialogOpen} setOpen={setDialogOpen}/>
                </Stack>
                <List dense sx={{
                    width: '100%',
                    height: '100%',
                    maxWidth: 360,
                    overflow: 'hidden',
                }}>
                    {Object.keys(availableAttachments)
                        .filter((slot) => availableAttachments[slot]?.length > 0)
                        .map((slot) =>
                            <ListItem key={`item-attachment-${slot}`}
                                      sx={{
                                          flexDirection: 'column',
                                      }}>
                                <Stack direction='row' justifyContent='space-between'
                                       alignItems='center' width='100%'>
                                    <ListItemText
                                        primary={`${t(slot)}${selectedLoadout.attachments[slot] != null ? ' - ' + t(displayAttachmentName(selectedLoadout.attachments[slot])) : ''}`}/>
                                    {openSlot === slot ?
                                        <CloseIcon onClick={() => toggleSlot(slot)}/> :
                                        selectedLoadout.attachments[slot] != null ?
                                            <RemoveIcon onClick={() => removeAttachment(slot)}/> :
                                            slotsFull(selectedLoadout.attachments) ? null :
                                                <AddIcon onClick={() => toggleSlot(slot)}/>}
                                </Stack>
                            </ListItem>
                        )}
                </List>
                {
                    openSlot != null ?
                        <Stack sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            borderRadius: '5px',
                            backgroundColor: 'background.paper',
                            zIndex: 5
                        }}>
                            <Stack sx={{
                                width: '100%',
                                flexDirection: 'row',
                                justifyContent: 'end',
                                zIndex: 6,
                                position: 'absolute'
                            }}>
                                <RemoveIcon color='white' onClick={() => toggleSlot(openSlot)}/>
                            </Stack>
                            <List sx={{
                                p: 0,
                                height: '100%',
                                overflowY: 'scroll',
                                '&::-webkit-scrollbar': {display: 'none'}
                            }}>
                                {availableAttachments[openSlot].map((attachment) => (
                                    <ListItemButton
                                        selected={selectedLoadout.attachments[openSlot] === attachment}
                                        onClick={() => {
                                            handleAttachmentSelection(attachment, openSlot);
                                            toggleSlot(openSlot)
                                        }}
                                        sx={{p: 0}}
                                    >
                                        <ListItem sx={{
                                            flexDirection: 'column',
                                            alignItems: 'start',
                                            m: '5px 10px',
                                            p: '10px',
                                            backgroundColor: 'rgb(100,100,100,0.1)',
                                            borderRadius: '5px'
                                        }}
                                                  key={`item-attachment-${openSlot}-${displayAttachmentName(attachment)}`}>
                                            <ListItemText sx={{alignSelf: 'center', fontSize: '1rem', m: 0}}
                                                          primary={t(displayAttachmentName(attachment))}/>
                                            <AttachmentEffects effects={attachment.effects} fontSize='0.7rem'/>
                                        </ListItem>
                                    </ListItemButton>
                                ))}
                            </List>
                        </Stack> : null
                }
            </Stack>
        </ItemPaper>
    )
}

export default MobileAttachments;
