import {useParams} from "react-router-dom"
import React, {createContext, useContext, useEffect, useState} from "react"
import {getUserProfile} from "../../api/user/UserProfile"
import {Skeleton, Stack} from "@mui/material"
import UserProfile from "./UserProfile"
import {UserContext} from "../../context/UserContext"
import {TitleText} from "../../components/Text"
import Echelon from "../../assets/game/samir-echelon.png"

const LOADING = "loading"
const LoadingSkeleton = () => {
    return <Stack sx={{opacity: '80%'}}>
        <Stack direction='row' sx={{m: '15px 0', width: '100%', alignItems: 'center'}}>
            <Skeleton variant="rounded" height={180} sx={{mr: '20px', flexGrow: 1}}/>
            <Skeleton variant="circular" height={150} width={150}/>
        </Stack>
        <Skeleton variant="rounded" height='20vh' sx={{mb: '15px'}}/>
        <Skeleton variant="rounded" height='20vh' sx={{mb: '15px'}}/>
        <Skeleton variant="rounded" height='20vh' sx={{mb: '15px'}}/>
    </Stack>
}

const UserProfileContextProvider = ({children, value}) =>
    <UserProfileContext.Provider value={value}>
        {children}
    </UserProfileContext.Provider>

export const UserProfileContext = createContext(null)

const ProfilePage = () => {

    const profileUserId = useParams().userId
    const {user} = useContext(UserContext)

    const [userProfile, setUserProfile] = useState(LOADING)


    const isOwnProfile = (user?.id === profileUserId) || user?.profileUrl === profileUserId

    useEffect(() => {
        if (!isOwnProfile) getUserProfile(profileUserId).then((profile) => setUserProfile(profile)).catch(e => setUserProfile('failed'))
    }, [isOwnProfile, profileUserId])

    useEffect(() => {
        if (isOwnProfile) setUserProfile({
            ...user.profile,
            loadouts: user.loadouts.filter(l => l.public),
            icon: user.icon
        })
    }, [isOwnProfile, user])

    return userProfile === LOADING ? <LoadingSkeleton/> :
        userProfile === 'failed' ?
            <Stack width='100%' alignItems='center' mt='20px'>
                <img draggable={false} src={Echelon} style={{
                    WebkitMaskImage: '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))',
                    maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 70%, rgba(0,0,0,0) 90%)',
                    height: 'auto',
                    maxWidth: '100%',
                    objectFit: 'contain'
                }} alt='echelon-samir'/>
                <TitleText>
                    User not found
                </TitleText>

            </Stack> :
            <UserProfileContextProvider value={{userProfile, setUserProfile}}>
                <UserProfile data={userProfile} isOwnProfile={isOwnProfile}/>
            </UserProfileContextProvider>
}

export default ProfilePage