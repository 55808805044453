import {useIsMobileHook} from "../../utils";
import {Stack, Typography} from "@mui/material";
import xdiLogo from "../../assets/partners/xdi.png";

const XDI = (isMobile) =>
    <a target='_blank' href='https://xdi.gg'>
        <img draggable={false} src={xdiLogo} height={'20px'} alt='xdi.gg logo'
             style={{padding: '3px 3px 0 8px'}}/>
    </a>

const mapToImage = {
    xdi: XDI
}

const CollabTag = ({user}) => {
    const isMobile = useIsMobileHook()

    if (user?.collab == null) return null
    const image = mapToImage[user.collab](isMobile)
    if (image == null) return null

    return <Stack direction='row'
                  sx={{
                      pt: '3px',
                      justifyContent: 'end',
                      alignItems: 'center',
                      width: '98%',
                      opacity: '75%'
                  }}>
        <Typography fontStyle='oblique' fontSize={{xs: '0.8rem', md: '0.9rem'}}>
            in collaboration with
        </Typography>
        {image}
    </Stack>;
}

export default CollabTag