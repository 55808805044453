import * as React from "react";
import {useEffect, useState} from "react";
import {weaponsByClass} from "../../../builder/MobileWeapons";
import {Accordion, AccordionDetails, AccordionSummary, Stack, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {ProfileLoadout} from "../ProfileLoadouts";
import {useIsMobileHook} from "../../../utils";
import {useTranslation} from "react-i18next";
import {Namespaces} from "../../../config/localisation/Localisation";

const TransparentAccordion = ({children, defaultExpanded}) => <Accordion elevation={0} disableGutters
                                                                         defaultExpanded={defaultExpanded}
                                                                         sx={{backgroundColor: 'transparent'}}
                                                                         children={children}/>

const NumberDisplay = ({number}) => {
    return <div style={{
        margin: '0 8px',
        height: '18px',
        width: '18px',
        backgroundColor: '#fff',
        color: '#27292f',
        textAlign: 'center',
        borderRadius: '20px',
        paddingRight: `${2 * (number.toString().length - 1)}px`
    }}>{number}</div>;
}

const LoadoutsByWeapon = ({weapons, loadouts, isOwnProfile}) => {
    const {t} = useTranslation(Namespaces.weapons)
    return weapons.map((weapon, i) => {
            const loadoutsForWeapon = loadouts.filter(loadout => loadout.weapon.name === weapon.name);
            return <TransparentAccordion key={`loadouts-weapon-${weapon.name}`} defaultExpanded={i === 0} elevation={0}
                                         disableGutters>
                <AccordionSummary
                    sx={{p: 0}}
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                    aria-controls={`${weapon.name}-content`}
                    id={`${weapon.name}-header`}
                >
                    <Stack direction='row' alignItems='center'>
                        <Typography>{t(weapon.name)}</Typography>
                        <NumberDisplay number={loadoutsForWeapon.length}/>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{p: 0}}>
                    {loadoutsForWeapon.map(loadout => <ProfileLoadout key={`loadout-${loadout.id}`} loadout={loadout}
                                                                      isOwnProfile={isOwnProfile}/>)}
                </AccordionDetails>
            </TransparentAccordion>;
        }
    )
}

const ClassAccordionDivider = () => {
    const isMobile = useIsMobileHook()
    return <div style={{
        height: '1px',
        position: 'absolute',
        width: isMobile ? '100%' : '105%',
        top: 0,
        left: isMobile ? 0 : '-2.5%',
        background: 'radial-gradient(circle, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.7) 95%, rgba(255,255,255,0) 100%)',
    }}/>;
}

const LoadoutsByClass = ({loadouts, isOwnProfile}) => {
    const {t} = useTranslation(Namespaces.weapons)
    const [existingClasses, setExistingClasses] = useState({})
    useEffect(() => {
        const classes = {}
        Object.keys(weaponsByClass)
            .filter((type) => loadouts.filter((loadout) => loadout.weapon.type === type).length > 0)
            .forEach((type) => classes[type] = weaponsByClass[type].filter((weapon) => loadouts.filter((loadout) => loadout.weapon.name === weapon.name).length > 0))

        setExistingClasses({...classes})
    }, [loadouts.length])

    return <Stack sx={{mb: '20px'}}>
        {Object.keys(existingClasses).map((type, i) =>
            <TransparentAccordion key={`class-loadouts-${type}`} defaultExpanded={i === 0}>
                <AccordionSummary
                    sx={{p: 0}}
                    expandIcon={<ExpandMoreIcon sx={{color: 'white'}}/>}
                    aria-controls={`${type}-content`}
                    id={`${type}-header`}
                >
                    {i === 0 ? null : <ClassAccordionDivider/>}
                    <Stack direction='row' alignItems='center'>
                        <Typography>{t(type)}</Typography>
                        <NumberDisplay number={loadouts.filter((l) => l.weapon.type === type).length}/>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{p: {xs: '0 8px 8px', md: '0 16px 8px'}}}>
                    <LoadoutsByWeapon weapons={existingClasses[type]} loadouts={loadouts} isOwnProfile={isOwnProfile}/>
                </AccordionDetails>
            </TransparentAccordion>
        )}
    </Stack>
}

export default LoadoutsByClass