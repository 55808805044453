import {Stack, Typography} from "@mui/material";
import {compoundAttachment} from "../../builder/WeaponBuilder";
import {Namespaces} from "../../config/localisation/Localisation";
import {useTranslation} from "react-i18next";

const conditions = [
    {
        highlightCondition: a => a.dmgRanges[0].modifier > 1,
        lowlightCondition: a => a.dmgRanges[0].modifier < 1,
        label: 'damageRange'
    },
    {
        highlightCondition: a => (a.recoil.vertical + a.recoil.horizontal) < 1.95,
        lowlightCondition: a => (a.recoil.vertical + a.recoil.horizontal) > 2.05,
        label: 'recoilControl'
    },
    {
        highlightCondition: a => a.recoil.recovery > 1.05,
        lowlightCondition: a => a.recoil.recovery < 0.95,
        label: 'recoilRecovery'
    },
    {
        highlightCondition: a => a.rateOfFire > 1,
        lowlightCondition: a => a.rateOfFire < 1,
        label: 'Fire Rate'
    },
    {
        highlightCondition: a => a.adsTime < 1,
        lowlightCondition: a => a.adsTime > 1,
        label: 'adsSpeed'
    },
    {
        highlightCondition: a => a.adsTime < 1,
        lowlightCondition: a => a.adsTime > 1,
        label: 'sprintToFire'
    },
    {
        highlightCondition: a => a.moveSpeed > 1,
        lowlightCondition: a => a.moveSpeed < 1,
        label: 'moveSpeed'
    },
    {
        highlightCondition: a => a.adsMoveSpeed > 1,
        lowlightCondition: a => a.adsMoveSpeed < 1,
        label: 'adsMoveSpeed'
    },
]

const HighLowLight = ({stat, sign}) => {
    const {t} = useTranslation(Namespaces.weaponStats)
    return (
        <Stack direction='row' alignItems='center' sx={{p: 0}}>
            <Typography
                sx={{
                    fontSize: '1.25rem',
                    lineHeight: {xs: 0.7, md: 1},
                    fontWeight: 'bold',
                    color: sign === '+' ? 'green' : 'red',
                    p: '0 3px 5px 0'
                }}>
                {sign}
            </Typography>
            <Typography fontSize={{xs: '0.85rem', md: '0.9rem'}}>{t(stat)}</Typography>
        </Stack>
    )
}

const LoadoutHighlights = ({attachments, isMobile}) => {
    const highlights = []
    const lowlights = []
    const attachment = compoundAttachment(attachments);
    conditions.forEach(c => {
        if (c.highlightCondition(attachment)) highlights.push(c.label)
        if (c.lowlightCondition(attachment)) lowlights.push(c.label)
    })
    return (
        <Stack direction={isMobile ? 'row' : 'column'}
               sx={{color: 'white', width: isMobile ? '100%' : 'auto', mr: isMobile ? null : '15px', justifyContent: 'space-evenly'}}>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 0 : 2} justifyContent={isMobile ? 'start' : 'end'}>
                {highlights.map(stat => <HighLowLight key={`highlight-${stat}`} stat={stat} sign='+'/>)}
            </Stack>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 0 : 2} justifyContent={isMobile ? 'start' : 'end'}>
                {lowlights.map(stat => <HighLowLight key={`lowlight-${stat}`} stat={stat} sign='-'/>)}
            </Stack>
        </Stack>
    )
}

export default LoadoutHighlights