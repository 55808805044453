import {API_URL} from "../API";

export const Actions = {
    start: 'START',
    update: 'UPDATE',
    end: 'END'
}
export const userStatsSession = (userId, action) =>
    fetch(`${API_URL}user-stats-session/${userId}?sessionAction=${action}`)//, {credentials: 'include'})
        .then((response) => {
            if (response.ok) {
                return response.json()
            }
            throw new Error(`Response status was ${response.status}`)
        })