import * as React from "react";

const translations = {
    pages: {
        weaponBuilder: "Weapon Builder",
        meta: "Meta",
        profiles: "Profiles",
        more: "More",
        updates: "Updates",
        about: "About",
        leaderboards: "Leaderboards",
        statsTracker: 'Stats Tracker',
        patchNotes: 'Patch Notes'
    },
    weapons: {
        weapons: 'Weapons',
        selectWeapon: "Select Weapon",
        'Assault Rifle': 'Assault Rifle',
        arShort: 'AR',
        SMG: 'SMG',
        LMG: 'LMG',
        lmgShort: 'LMG',
        Shotgun: 'Shotgun',
        sgShort: 'SG',
        'Marksman Rifle': 'Marksman Rifle',
        'dmrShort': 'DMR',
        'Sniper Rifle': 'Sniper Rifle',
        sniperShort: 'Sniper',
        Pistol: 'Pistol',
        ACR: 'ACR',
        M4A1: 'M4A1',
        'AK-47': 'AK-47',
        M16A4: 'M16A4',
        MDR: 'MDR',
        MP5A2: 'MP5A2',
        MP7: 'MP7',
        P90: 'P90',
        'Vector .45': 'Vector .45',
        M249: 'M249',
        'RPK-74': 'RPK-74',
        M60: 'M60',
        M870: 'M870',
        'AA-12': 'AA-12',
        'Double-Barreled': 'Double-Barreled',
        doubleBarreledShort: 'Double-B.',
        'MK 20 SSR': 'MK 20 SSR',
        SVD: 'SVD',
        M44: 'M44',
        'TAC-50': 'TAC-50',
        M1911: 'M1911',
        '686 Magnum': '686 Magnum',
        '93R': '93R',
        M9: 'M9',
        D50: 'D50'
    },
    attachments: {
        attachments: 'Attachments',
        'Muzzle': 'Muzzle',
        'Barrel': 'Barrel',
        'Front_Rail': 'Front Rail',
        'Optic': 'Optic',
        'Magazine': 'Magazine',
        'Rear_Grip': 'Rear Grip',
        'Stock': 'Stock',
        'Lightweight Suppressor': 'Lightweight Suppressor',
        'Muzzle Booster': 'Muzzle Booster',
        'Barrel Extender': 'Barrel Extender',
        'Muzzle Break': 'Muzzle Break',
        Compensator: 'Compensator',
        Suppressor: 'Suppressor',
        Recon: 'Recon',
        Stabilizing: 'Stabilizing',
        'Rapid Fire': 'Rapid Fire',
        Heavy: 'Heavy',
        Stealth: 'Stealth',
        Lightweight: 'Lightweight',
        'Chrome Lined': 'Chrome Lined',
        Choke: 'Choke',
        'Angled Grip': 'Angled Grip',
        'Superlight Grip': 'Superlight Grip',
        'Vertical Grip': 'Vertical Grip',
        'Tactical Grip': 'Tactical Grip',
        'Small Vertical Grip': 'Small Vertical Grip',
        'PEQ-15 Laser': 'PEQ-15 Laser',
        Handstop: 'Handstop',
        'Leather-Wrapped Foregrip': 'Leather-Wrapped Foregrip',
        'Pistol Grip': 'Pistol Grip',
        'Reflex Sight': 'Reflex Sight',
        'Holographic Sight': 'Holographic Sight',
        '3x Scope': '3x Scope',
        'ACOG Scope (4x)': 'ACOG Scope (4x)',
        'Variable Zoom Scope (4x-8x)': 'Variable Zoom Scope (4x-8x)',
        'CQBSS Scope (8x)': 'CQBSS Scope (8x)',
        'Sniper Scope (12x)': 'Sniper Scope (12x)',
        'Red Dot Sight': 'Red Dot Sight',
        'Quick Mag': 'Quick Mag',
        'Lightweight Mag': 'Lightweight Mag',
        'Extended Mag': 'Extended Mag',
        'Fast Mag': 'Fast Mag',
        'Grip Tape': 'Grip Tape',
        'Fabric Grip': 'Fabric Grip',
        'Leather-Wrapped Grip': 'Leather-Wrapped Grip',
        'Heavy Grip': 'Heavy Grip',
        'Lightweight Grip': 'Lightweight Grip',
        'Quick Draw Grip': 'Quick Draw Grip',
        'Padded Stock': 'Padded Stock',
        'Precision Stock': 'Precision Stock',
        'Reinforced Stock': 'Reinforced Stock',
        'Lightweight Stock': 'Lightweight Stock',
        'Removed Stock': 'Removed Stock',
        'Folded Stock': 'Folded Stock',
        'Collapsed Stock': 'Collapsed Stock'
    },
    weaponStats: {
        noWeapon: 'Select a weapon to view stats',
        ttk: 'Time to Kill',
        stk: 'Shots to Kill',
        damageRanges: 'Damage Ranges',
        short: 'Short',
        mid: 'Mid',
        long: 'Long',
        range: 'Range',
        'Minimap Visibility Range': 'Minimap Visibility Range',
        'Minimap Visibility Duration': 'Minimap Visibility Time',
        'Fire Rate': 'Fire Rate',
        'Damage': 'Damage',
        'Recoil Recovery': 'Recoil Recovery',
        'Vertical Recoil': 'Vertical Recoil',
        'Horizontal Recoil': 'Horizontal Recoil',
        'Short Range': 'Short Range',
        'Long Range': 'Mid Range',
        'Sprint to Fire Time': 'Sprint to Fire Time',
        'ADS Time': 'ADS Time',
        'Aiming Stability': 'Aiming Stability',
        'Move Speed': 'Movement Speed',
        'ADS Move Speed': 'ADS Move Speed',
        'Hipfire Spread': 'Hipfire Spread',
        'adsFlinch': 'adsFlinch',
        'Mag Size': 'Mag Size',
        'Reload Time': 'Reload Time',
        'Extra Mags': 'Extra Mags',
        'Bullet Penetration': 'Bullet Penetration',
        //Highlights
        damageRange: 'Damage Range',
        recoilControl: 'Recoil Control',
        adsSpeed: 'ADS Speed',
        sprintToFire: 'Sprint to Fire',
        moveSpeed: 'Movement Speed',
        adsMoveSpeed: 'ADS Move Speed',
        recoilRecovery: 'Recoil Recovery'
    },
    builder: {
        range: 'Range',
        advanced: 'Advanced',
        compare: 'Compare Loadouts',
        loadSavedLoadouts: 'Load Saved|\n|Loadout',
        randomWeapon: 'Random Weapon',
        loadoutName: 'Loadout Name',
        share: 'Share',
        resetAttachments: 'Reset Attachments',
        randomAttachments: 'Randomise Attachments',
        statsXdi: 'Weapon stats in collaboration with',
        comparisonFull: 'You are already comparing the max number of weapons.',
        comparisonSelect: 'Select up to 5 saved loadouts to compare',
        stock: 'Stock',
        savedLoadouts: 'Saved Loadouts',
        stockWeapons: 'Stock Weapons',
        multipliers: 'Multipliers',
        headshots: 'Headshots',
        chestshots: 'Chestshots',
        multipliersTooltip: 'Choose shot locations to use for calculating TTK and STK. Headshots will hit first, then chestshots, then the rest will be body shots.',
        accuracy: 'Accuracy',
        cleanerDamage: 'Cleaner incendiary damage',
        cleanerDamageTooltip: 'Includes 8 incendiary tick damage when calculating STK and TTK (does not include the tick time)',
        cleanerRange: 'Cleaner range reduction',
        cleanerRangeTooltip: 'Includes 10% damage range reduction',
        phantomHealth: 'Phantom health',
        phantomHealthTooltip: 'Uses 120 health for calculating STK and TTK',
        includeAds: 'Include ADS Time',
        includeStf: 'Include Sprint to Fire Time',
        includeAdsTooltip: 'Include ADS time when calculating TTK',
        includeStfTooltip: 'Include Sprint to Fire time when calculating TTK',
        pelletsMissed: 'Pellets Missed',
        pelletsMissedTooltip: '(applies only to shotguns)',
        h1Text: 'XDefiant Weapon Builder - Create your loadout and view full detailed stats time to kill, shots to kill and damage ranges.',
        h2Text: 'Save your weapon builds and compare up to 5 of them on the graph.',
        viewInBuilder: 'View in Builder',
        selectALoadout: 'Select a saved loadout to view and edit',
        noSavedLoadouts: "You don't have any saved loadouts",
        tweetLoadout: 'Tweet Loadout',
        saveImage: 'Save Image',
    },
    homepage: {
        welcome: 'Welcome to',
        h1Text: 'Your #1 source for detailed weapon stats and the best meta loadouts.<br><br/> Compare weapons and attachment stats to build your own loadout, or check out the meta builds from the top XDefiant players.',
        builderTitle: 'Weapon Builder',
        builderDesc: 'Build and compare your loadouts and view full detailed stats including time to kill (TTK), headshot multipliers and more!',
        metaTitle: 'Meta Loadouts',
        metaDesc: 'See our meta tierlist of the best weapons for both comp and casual modes. Get the best loadouts created by select competitive XDefiant players and content creators.',
        statsTitle: 'Stats Tracker',
        statsDesc: "Get detailed player stats, including stats you can't find anywhere else like K/D, historical and seasonal stats.",
        profileTitle: 'Your Profile',
        profileDesc: 'View and edit your profile',
        h2Text: 'Create a profile to see your player stats, save your own loadouts and save your favourite meta builds from other users!'
    },
    meta: {
        casual: 'Casual',
        ranked: 'Ranked',
        collaboration: 'in collaboration with',
        rankedTitle: 'XDefiant Ranked Meta Tierlist',
        rankedDesc: "In collaboration with XDefiant's competitive hub XDI, we consulted with many of the top players to rank the meta weapons for ranked XDefiant. Expect to see the meta shift soon as players get more reps. Click on the weapons to see some comp players' favourite loadouts!",
        rankedToggle: "For a meta more focused on public matches/casual playlists, select 'Casual'.",
        casualTitle: 'XDefiant Meta Tierlist',
        casualDesc: "Check out the meta tier list and find the best meta XDefiant weapon loadouts and attachments from the top competitive players and best content creators. Choose any weapon to view featured loadouts.",
        casualToggle: "For a meta more focused on Ranked mode, select 'Ranked'.",
        featuredLoadouts: 'Featured Loadouts',
        noFeaturedLoadouts: 'No featured loadouts yet.',
        xdlRecommended: 'XDLoadout Recommendations',
        //Build Types
        Aggressive: 'Aggressive',
        'Main AR': 'Main AR',
        'Heavy': 'Heavy',
        'Flex': 'Flex',
        'Long-Range': 'Long-Range',
        'Movement': 'Movement',
        'Quickscope': 'Quickscope',
        'Fast': 'Fast',
    },
    misc: {
        adblockText: 'Please consider removing XDLoadout from your AdBlocker. We will never have paywalls and ads help support the project.',
        bottomDisclaimer: 'XDLoadout has no affiliation with XDefiant or Ubisoft',
        loginMain: 'Track your game stats, Save your loadouts and share them with others by logging in!',
        loginWithDiscord: 'Login with Discord',
        loginPrivacy: `By logging in via Discord you agree to our`,
        privacyPolicy: 'privacy policy',
        profile: 'Profile',
        bookmarks: 'Bookmarks',
        logout: 'Logout'
    },
    profiles: {
        title: 'Featured Profiles',
        failedGet: "Couldn't retrieve featured profiles right now.",
        seeMore: 'See More',
        'Content Creators': 'Content Creators',
        'Comp Players': 'Comp Players',
        'Edit Socials': 'Edit Socials',
        add: 'Add',
        displayName: 'Display Name',
        pronouns: 'Pronouns',
        location: 'Location',
        bio: 'Bio',
        optional: "optional",
        userPanelText: "All info entered here will be public to everyone. Click the trash icon to remove a field.",
        urlCountdown: 'You can update your profile URL again in',
        urlUpdateLimit: 'Profile URLs can only be updated every 7 days.',
        //LoadoutMenu
        copyLink: 'Copy Link',
        saveImage: 'Save as Image',
    },
    playerStats: {
        //Leaderboards
        leaderboardLink: "To claim your spot on the leaderboards make sure you have an XDLoadout profile with your Discord account linked to your Ubisoft account.",
        how: "How?",
        leaderboardsTitle: "Leaderboards",
        rankTitle: 'Ranked',
        levelTitle: 'Level',
        killsTitle: 'Kills',
        kdRatioTitle: 'K/D Ratio',
        winsTitle: 'Wins',
        mvpsTitle: 'Times MVP',
        //How to Link Dialog
        howToLinkTitle: 'How to link Discord to Ubisoft Account',
        step1: 'Log in to',
        step2A: 'Scroll down to',
        step2B: '"Linked Accounts"',
        step3A: 'Under Discord, click',
        step3B: '"Link"',
        step4: 'Authorise with Discord (you may need to log in)',
        step5: 'Click the refresh button (it could take some minutes before everything updates)',
        //User Stats Panel
        level: 'Level',
        rank: 'Rank',
        kdr: 'K/D Ratio',
        wins: 'Wins',
        losses: 'Losses',
        winRate: 'Winrate',
        winRatio: 'W/L Ratio',
        mvpCount: 'Times MVP',
        mvpRate: 'MVP Rate',
        kills: 'Kills',
        deaths: 'Deaths',
        deathsPerGame: 'Deaths/Game',
        assists: 'Assists',
        assistRate: 'Assist Rate',
        killsPerGame: 'Kills/Game',
        assistsPerGame: 'Assists/Game',
        score: 'Score',
        scorePerMin: 'Score/Min',
        scorePerGame: 'Score/Game',
        damage: 'Damage',
        damagePerKill: 'Damage/Kill',
        damagePerGame: 'Damage/Game',
        winStreak: 'Best Win Streak',
        longestLifetime: 'Longest life',
        abilityKills: 'Ability Kills',
        abilitiesUsed: 'Abilities Used',
        ultrasUsed: 'Ultras Used',
        matches: 'Matches',
        mvp: 'MVP',
        abilities: 'Abilities',
        progression: 'Progression',
        favouriteFaction: 'Favourite Faction',
        "gamemode": "Gamemode",
        "Gamemode": "Gamemode",
        "gamemodes": "Gamemodes",
        "Domination": "Domination",
        "Team Deathmatch": "Team Deathmatch",
        "Capture the Flag": "Capture the Flag",
        "Zone Control": "Zone Control",
        "Hot Shot": "Hot Shot",
        "Escort": "Escort",
        "Occupy": "Occupy",
        weaponClass: "Weapon Class",
        weaponClasses: "Weapon Classes",
        unlinkStats: "Unlink Stats",
        lastUpdated: 'Last updated',
        career: 'Career',
        statsMayDiffer: 'Note that stats can differ from those shown ingame.',
        statsFromUbi: 'XDLoadout uses Ubisoft APIs which sometimes do not track correctly.',
        moreHere: 'More Here',
        date: 'Date',
        statTrackingTitle: 'XDLoadout Stat Tracker',
        season: 'Season',
        preseason: 'Preseason',
        session: 'Session',
        sessionTracker: 'Session Tracker',
        careerTracker: 'Career Tracker',
        startSession: 'Start New Session',
        endSession: 'End Session',
        lastSession: 'Last Session',
        currentSession: 'Current Session',
        nextUpdate: 'Next Update',
        trackingMainTitle: "The most advanced XDefiant stats tracker, featuring K/D, historical stats and match history.",
        trackingMainDesc: "XDLoadout tracks full detailed player stats over time, including kill/death ratio. Every day that you visit XDLoadout your stats will be tracked, allowing you to see how you've improved daily and from season to season.|Click below to see your career stats history or start a tracking session to see your match history as you play! (currently in development)",
        trackingCareerDesc: "Every day that you visit XDLoadout your stats will be tracked, allowing you to see how you've improved daily and from season to season.|Seasonal stats are shown for each season you have used XDLoadout. If you don't see any seasonal stats yet, you will once the next season starts.|Visiting before you play will allow you to see a 'Today' tab in your stats, showing your stats so far for this day (GMT).|Check out the graph below to see the evolution of your stats over time. You can also filter the graph by season.",
        trackingCareerNote: "Note: You may sometimes see inflated kill stats in the 'Today' tab when playing ranked. Please reach out via twitter if you do. These values are from Ubisoft's API, but with more data maybe they can be adjusted.",
        trackingSessionDesc: "Start a session and leave this page open and XDLoadout will check your stats regularly and show a match history for your session.|For each match you can see how your stats compare to your career average.|When you end your session, your session stats will still be available to see here. Sessions will end automatically if you don't play a game for 30 minutes.|All of your sessions will be saved so you can view them later via the session browser (coming soon)",
        trackingSessionNote: "Note: session tracking is still in beta. Match stats can always vary slightly due to how Ubisoft API works. There are still some bugs with how XDLoadout tracks session stats and there also seem to be some bugs with Ubisoft's API, especially when playing ranked matches (sometimes you may see inflated kill stats, these values are from Ubisoft's API and not XDLoadout).",
    }
}

export default translations