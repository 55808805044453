import {Stack} from "@mui/material";
import {useParams} from "react-router-dom";
import UserLoadout from "../../components/loadout/UserLoadout";
import MainBg from "../../assets/main-bg.png";
import {getLoadout} from "../../api/loadouts/Loadouts";
import {useEffect, useState} from "react";

function LoadoutCard({loadout, user, captureRef}) {

    const [cardLoadout, setCardLoadout] = useState(loadout)
    let loadoutId = useParams().loadoutId ?? cardLoadout.loadoutId

    useEffect(() => {
        if (loadout == null && loadoutId.includes('-')) {
            getLoadout(loadoutId).then(l => {
                setCardLoadout(l)
            })
        }
    }, [loadout])

    if (cardLoadout?.loadoutId) loadoutId = cardLoadout.loadoutId

    return (cardLoadout === 'loading' || loadoutId.includes('-')) ? null :
        <Stack ref={captureRef} sx={{
            position: 'absolute', left: 0, top: 0, zIndex: -100,
            overflow: 'clip',
            backgroundImage: 'linear-gradient(165deg, #3c3c40 20%, #1F1F21 85%)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'background.default',
        }} width={1125} height={600}>
            <Stack alignItems='center' sx={{
                width: '100%', height: '100%',
                backgroundImage: `url(${MainBg})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}>
                <UserLoadout loadout={cardLoadout} loadoutId={loadoutId} user={user} card/>
            </Stack>
        </Stack>
}

export default LoadoutCard