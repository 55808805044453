import {Stack, Typography} from "@mui/material";
import * as React from "react";
import BasePage from "./BasePage";
import {useIsMobileHook} from "../utils";
import {WeaponImage} from "../components/WeaponImage";
import DesktopHorizontalBannerAd from "../components/ads/DesktopHorizontalBannerAd";
import MobileAd from "../components/ads/MobileAd";
import {AdSizes} from "../components/ads/NitroAds";
import {siteUpdates} from "./updates/SiteUpdates";
import {patchUpdates} from "./updates/PatchNotes";

export const UpdatesPage = () => {
    return <BasePage title='XDLoadout Updates'>
        <Updates section='site'/>
    </BasePage>
}

export const PatchNotesPage = () => {
    return <BasePage title='Patch Notes'>
        <Typography color='white' variant='smallMid'>These updates are detailed breakdowns/summaries of weapon balance
            updates, not full patch notes. You can
            find <a target="_blank" href="https://www.ubisoft.com/en-gb/game/xdefiant/news" style={{color: 'white'}}>full patch notes here.</a></Typography>
        <Updates section='patch'/>
    </BasePage>
}

function Update({content, expanded}) {
    return <Stack p='10px 15px' m='10px 0' backgroundColor='rgb(0,0,0,0.2)' alignItems='stretch' borderRadius='10px'>
        <Stack direction='row' alignItems='baseline'>
            <Typography variant='midHead'>{content.title}</Typography>
            <Typography variant='small' m='0 15px' color='#aaa'>{content.date.toLocaleDateString()}</Typography>
        </Stack>
        {content.video ? <Stack m='10px'>
            <YoutubeEmbed embedId={content.video}/>
        </Stack> : null}
        {content.caption ?
            <Typography textAlign='center' variant='small' color='white'>{content.caption}</Typography> : null}
        <Typography variant='content' p='0 2%'>{content.description}</Typography>
    </Stack>
}


export const ContentWeaponImage = ({weapon}) => {
    const isMobile = useIsMobileHook()
    return <Stack m='10px 0' alignItems={isMobile ? 'center' : 'start'}>
        <WeaponImage weapon={weapon} style={{width: isMobile ? '95%' : '55%'}}/>
    </Stack>
}


const Updates = ({section}) => {
    const isMobile = useIsMobileHook()

    const sections = {
        site: siteUpdates,
        patch: patchUpdates()
    }

    return <Stack width='100%' alignItems='stretch'>
        {sections[section].map((update, i) => [
            <Update content={update} expanded={i === 0}/>,
            i < (sections[section].length - 1) ? <>
                    <DesktopHorizontalBannerAd adSlot={`${section}-updates-horizontal-${i}`}/>
                    <MobileAd nitroSlot={`${section}-updates-${i}`} nitroSize={AdSizes.MobileLarge}/>
                </> : null
        ])}
    </Stack>
}

const YoutubeEmbed = ({embedId}) => (
    <div className="video-responsive"
         style={{overflow: 'hidden', paddingBottom: '56.25%', position: 'relative', height: 0}}>
        <iframe
            style={{
                left: 0,
                top: 0,
                height: '100%',
                width: '100%',
                position: 'absolute'
            }}
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    </div>
);