import {useQuery} from "../utils";
import {API_URL} from "../api/API";
import {useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import {UserContext} from "../context/UserContext";
import {enrichLoadouts} from "../api/user/UserProfile";
import {decodeWeapons} from "../builder/WeaponBuilder";
import {FeedbackContext, FeedbackType} from "../context/UserFeedback";
import Cookies from "js-cookie";

export function enrichUser(user) {
    user.loadouts = decodeWeapons(user.loadouts ?? [])
    user.profile = enrichLoadouts(user.profile ?? {});
    user.likedLoadouts = user.likedLoadouts ?? []
    user.bookmarkedLoadouts = user.bookmarkedLoadouts ?? []
    return user
}

export const IS_LOGGED = 'xdl-is-logged';

export const getUser = () =>
    fetch(API_URL + 'user', {credentials: 'include'})
        .then((response) => {
            if (response.ok) {
                return response.json()
            }
            throw new Error(`Response status was ${response.status}`)
        })
        .then((user) => enrichUser(user))
        .catch((err) => {
        })

export const logoutUser = () =>
    fetch(API_URL + 'logout', {credentials: 'include'})
        .then((response) => {
            if (response.ok) {
                Cookies.remove(IS_LOGGED)
                return response.json()
            }
            throw new Error(`Response status was ${response.status}`)
        })
        .catch((err) => {
        })

const AuthHandler = () => {
    const {setUser} = useContext(UserContext)
    const {triggerFeedback} = useContext(FeedbackContext)

    const navigate = useNavigate()
    let query = useQuery()

    let authCode = query.get('code')

    const currentUrl = `${window.location.origin}${window.location.pathname}`

    useEffect(() => {
        fetch(API_URL + 'auth', {
            method: 'POST',
            body: JSON.stringify({
                redirect: currentUrl,
                authCode: authCode
            }),
            credentials: 'include'
        }).then((response) => {
            if (response.ok) {
                getUser().then((response) => setUser(response))
                Cookies.set(IS_LOGGED, true, {expires: 180})
                return
            }
            if (response.status === 403) {
                triggerFeedback(`XDLoadout login is in early-access. Stay tuned to @XDLoadout on twitter for the public release or to get your access code!`, FeedbackType.ERROR)
            }
            setUser(null)
        })
            .catch()
        navigate("/")
    }, [authCode]);

    return null;
}

export default AuthHandler