import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from "./languages/English"
import es from "./languages/Spanish"

export const Languages = {
    en: {
        name: 'English',
        locale: 'en',
        flag: 'gb'
    },
    es: {
        name: 'Español',
        locale: 'es',
        flag: 'es'
    },
}

export const Namespaces = {
    pages: 'pages',
    attachments: 'attachments',
    weapons: 'weapons',
    weaponStats: 'weaponStats',
    builder: 'builder',
    homepage: 'homepage',
    meta: 'meta',
    profiles: 'profiles',
    playerStats: 'playerStats',
    misc: 'misc',
}

i18n.use(initReactI18next)
    .init({
        ns: Object.values(Namespaces),
        lng: "en",
        fallbackLng: "en",
        // debug: true,
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en,
            es
        },
    })

export default i18n;