import {Stack, Typography} from "@mui/material";
import React from "react";

const errorText =
    <p>
        This page doesn't exist. If something isn't working properly please <a style={{color: '#ddd'}}
                                                                               href="https://twitter.com/XDLoadout">let
        me know</a>
    </p>

const AboutPage = () => {
    return (
        <Stack p='15px' alignItems='center'>
            <Typography variant='h5' color='text.primary'>
                404 - Spawned in Narnia
            </Typography>
            <Typography color='text.primary'>
                {errorText}
            </Typography>
        </Stack>
    )
}

export default AboutPage