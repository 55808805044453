import {BASE_URL} from "../../api/API";
import {useContext, useEffect} from "react";
import {useComponentToImage, useIsMobileHook} from "../../utils";
import {Button, Stack, TextField} from "@mui/material";
import ImageIcon from "@mui/icons-material/AddPhotoAlternate";
import LoadoutCard from "./LoadoutCard";
import {UserContext} from "../../context/UserContext";
import {useTranslation} from "react-i18next";
import {Namespaces} from "../../config/localisation/Localisation";
import XIcon from "@mui/icons-material/X";

const generateLoadoutUrl = (loadout) => {
    return `${BASE_URL}loadouts/${loadout.id ?? loadout.loadoutId}`
};
const generateTweetUrl = (loadout) => {
    const loadoutUrl = generateLoadoutUrl(loadout)
    return `https://twitter.com/intent/tweet?text=${encodeURIComponent(`Check out my ${loadout.weapon.name} build on @XDLoadout: \n\n${loadoutUrl}`)}`
}


const LoadoutShareMenu = ({loadout}) => {
    const {t} = useTranslation(Namespaces.builder)
    const {ref, convert} = useComponentToImage(loadout?.weapon?.name)
    const {user} = useContext(UserContext)

    useEffect(() => fetch(`${BASE_URL}preview/loadout/${loadout.id ?? loadout.loadoutId}`, {mode: 'no-cors'}).catch((e) => {}), [loadout])

    const isMobile = useIsMobileHook()
    return <Stack className='loadout-display-menu' direction={isMobile ? 'column' : 'row'}
                  alignItems='center'
                  justifyContent='center'
                  sx={{
                      paddingTop: isMobile ? 0 : '10px',
                      width: '100%',
                      height: {xs: 'auto', md: '40px'}
                  }}>
        <TextField
            id="loadout-share-link"
            defaultValue={generateLoadoutUrl(loadout)}
            InputProps={{
                readOnly: true,
            }}
            sx={{padding: {xs: '0 0 5px 0', md: '0 15px 0 0'}, width: {xs: '90%', md: '50%'}}}
        />
        <Button sx={{mr: '5px', mb: isMobile ? '10px' : 0}} variant="contained"
                onClick={() => window.open(generateTweetUrl(loadout), "_blank")}>
            <XIcon sx={{paddingRight: '5px'}}/> {t('tweetLoadout')}
        </Button>
        <Stack>
            <Button variant="contained" onClick={convert}>
                <ImageIcon sx={{paddingRight: '5px'}}/> {t('saveImage')}
            </Button>
            <LoadoutCard captureRef={ref} loadout={loadout} user={user}/>
        </Stack>
    </Stack>;
}

export default LoadoutShareMenu