import {Grid, IconButton, Paper, Stack, Typography} from "@mui/material";
import {displayAttachmentName} from "../attachments/MobileAttachments";
import {allStats} from "../WeaponStats";
import {displayDelta, getStatColor} from "../WeaponStat";
import {useEffect, useState} from "react";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import AnimateHeight from "react-animate-height";
import {useTranslation} from "react-i18next";
import {Namespaces} from "../../config/localisation/Localisation";

export const AttachmentEffects = ({effects, fontSize}) => {
    const {t} = useTranslation(Namespaces.weaponStats)
    if (effects == null) return
    fontSize = fontSize ?? 13
    return allStats
        .filter((stat) => stat.getter(effects) != null)
        .map((stat) => {
            let statValue = stat.getter(effects) * (stat.deltaType === 'absolute' ? 1 : 100)

            return (
                <Stack key={`attachment-effect-${stat.label}`} direction='row'>
                    <Typography fontSize={fontSize} sx={{pr: '5px'}}>{`${t(stat.label)}:`}</Typography>
                    <Typography fontSize={fontSize} color={getStatColor(stat.lowerIsBetter, statValue)}>
                        {`${displayDelta(Math.round(statValue * 10) / 10, stat.deltaType !== 'absolute')}`}
                    </Typography>
                </Stack>
            )
        })

}

const AttachmentsSummary = ({attachments, isMobile}) => {
    return (
        <Grid container direction='row' justifyContent='center'>
            {
                Object.keys(attachments)
                    .filter((slot) => attachments[slot] != null)
                    .map((slot) =>
                        <AttachmentSummary key={`attachment-summary-${slot}`} slot={slot} attachment={attachments[slot]} isMobile={isMobile}/>)
            }
        </Grid>
    )
}
const AttachmentSummary = ({slot, attachment, isMobile}) => {
    const {t} = useTranslation(Namespaces.attachments)

    const [expanded, setExpanded] = useState(true)

    useEffect(() => setExpanded(!isMobile),[isMobile])
    const getTitle = () => {
        return (
            <Stack direction='row' justifyContent='center'>
                <Typography sx={{
                    paddingRight: '5px',
                    fontStyle: 'oblique',
                    lineHeight: 1,
                }}>
                    {`${t(slot)} - ${t(displayAttachmentName(attachment))}`}
                </Typography>
            </Stack>
        )
    }

    return (
        <Grid item xs={isMobile ? 12 : 4} flexGrow={1}>
            <Paper elevation={3} sx={{
                backgroundColor: 'rgb(0,0,0,0.35)',
                m: isMobile ? '5px 0' : '5px',
                p: isMobile ? '8px 10px' : '10px',
                position: 'relative',
                transition: 'all 0.3s ease',
            }}>
                {getTitle()}
                {isMobile && Object.keys(attachment.effects).length > 0 ? <IconButton onClick={(e) => {setExpanded(!expanded); e.stopPropagation()}}
                            sx={{color: 'white', position: 'absolute', right: 0, top: 0, p: '5px'}}>
                    {expanded ? <ExpandLess/> : <ExpandMore/>}
                </IconButton> : null}
                <AnimateHeight
                    duration={ 250 }
                    height={ expanded ? 'auto' : 0 }
                >
                    <div style={{height: '5px'}}/>
                    <AttachmentEffects condensed effects={attachment.effects} isMobile={isMobile}/>
                </AnimateHeight>
            </Paper>
        </Grid>
    )
}

export default AttachmentsSummary