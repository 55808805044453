import {Avatar, Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";

const UserChip = ({user, isMobile}) => {
    return user.id == null ? null :
        <Link target="_blank" to={`/profile/${user.profileUrl ?? user.id}`} style={{textDecoration: 'none'}}>
            <Stack direction='row'
                   sx={{
                       m: '0 5px',
                       p: isMobile ? '3px 5px' : '5px 8px',
                       color: 'text.primary',
                       borderRadius: '5px',
                       backgroundColor: 'rgba(0,0,0,0.4)',
                       alignItems: 'center',
                       justifyContent: 'center'
                   }}>
                <Avatar imgProps={{crossOrigin: 'anonymous'}} src={user.icon}
                        sx={{height: isMobile ? 24 : 32, width: isMobile ? 24 : 32}}/>
                <Stack sx={{pl: '8px'}}>
                    <Typography
                        fontSize={isMobile ? '0.9rem' : '1rem'}>{user.displayName ?? user.profile.displayName}</Typography>
                    {/*<Typography fontSize='0.75rem'>{`@${user.socials.twitter}`}</Typography>*/}
                </Stack>
            </Stack>
        </Link>
}

export default UserChip