import React from "react";
import {MobileCloseDialogButton} from "./Dialog";

export const Overlay = ({active, setActive, z, children}) => {
    return active ?
        <div style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            WebkitBackdropFilter: 'blur(2px)',
            backdropFilter: 'blur(2px)',
            cursor: 'pointer',
            zIndex: z ?? '1100'
        }} onClick={(e) => {
            setActive(false)
            e.stopPropagation()
        }}>
            <MobileCloseDialogButton setOpen={setActive}/>
            {children}
        </div>
        : null
}