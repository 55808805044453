import {Stack, Typography} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {useIsMobileHook} from "../utils";


const countdownSubstring = (value, label) => value > 0 ? `${value} ${label}${value === 1 ? '' : 's'} ` : ''

const countdownString = (timeLeft) => {
    const days = Math.floor(timeLeft / (60 * 60 * 24));
    const hours = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((timeLeft % (60 * 60)) / 60);
    const seconds = Math.floor(timeLeft % 60);
    return countdownSubstring(days, 'day') + countdownSubstring(hours, 'hr') + countdownSubstring(minutes, 'min') + countdownSubstring(seconds, 'sec')
};

const ReleaseCountdown = () => {
    const isMobile = useIsMobileHook()
    const [countdown, setCountdown] = useState()
    const countdownRef = useRef();

    useEffect(() => {
        countdownRef.current = countdown;
    }, [countdown]);

    useEffect(() => {
        setCountdown(1716310800 - new Date().getTime() / 1000)
        const interval = setInterval(() => setCountdown(countdownRef.current - 1), 1000)
        return () => clearInterval(interval)
    }, [])

    return isMobile || countdown < 1 ? null :
        <Stack>
            <Typography fontWeight='bold' fontSize='1.2rem' sx={{minWidth: '500px', textShadow: '#ffffff77 0 0 10px'}}>
                {`XDefiant Release: ${countdownString(countdown)}`}
            </Typography>
        </Stack>
}

export default ReleaseCountdown