import {API_URL} from "../API";
import {stripData} from "../../builder/WeaponBuilder";
import {enrichUser} from "../../auth/AuthHandler";

const innerUpdateUser = (loadouts, operation) => updateUser('updateLoadouts', {loadouts: loadouts.map(l => stripData(l)), operation})
export const addLoadouts = (loadouts) => innerUpdateUser(loadouts, 'ADD')
export const deleteLoadouts = (loadouts) => innerUpdateUser(loadouts, 'DELETE')
export const updateLoadouts = (loadouts) => innerUpdateUser(loadouts, 'UPDATE')

export const updateProfile = (profile) => {
    let profileToPersist = {...profile}
    profileToPersist.loadouts = profileToPersist.loadouts.map((w)=> stripData(w))
    return updateUser('updateProfile', { profile: profileToPersist })
}

export const updateSocials = (socials) => updateUser('updateSocials', { socials })
export const updateProfileUrl = (profileUrl) => updateUser('updateProfileUrl', { profileUrl })
export const retryStatsLink = () => updateUser('updateStatsLink', { retry: true })
export const unlinkStats = () => updateUser('updateStatsLink', { unlink: true })

const updateUser = (operation, body) => {
    return fetch(API_URL + 'updateUser', {
        method: 'POST',
        body: JSON.stringify({
            operation,
            body
        }),
        credentials: 'include'
    }).then((response) => {
        if (response.ok) {
            return response.json()
        }
        return response.json().then(response => { throw new Error(response.code) })
    }).then(user => enrichUser(user))
}