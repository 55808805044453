import PlayerStats1 from "../../assets/updates/PlayerStats1.PNG";
import * as React from "react";

export const siteUpdates = [
    {
        title: 'Player Stats',
        date: new Date(1719138322189),
        video: 'CWnL8fXNiV4?si=Oer3DW0ZWwijSoEv',
        caption: <>Huge thanks to <a style={{color: '#ccc'}} target='_blank' rel='noopener noreferrer'
                                     href='https://youtube.com/@ClueFPS'>Clue</a> for putting together this
            interview about the update!</>,
        mainImage: PlayerStats1,
        description: <>
            <p>
                I'm super happy to bring player stats to XDLoadout! I've been asked about this a lot but never had
                any
                info about APIs that Ubisoft provide for stats tracking. Well, I found the APIs and now by linking
                your
                Discord account to your Ubisoft account you will automatically see stats on your profile!
            </p>
            <p>
                <i style={{fontSize: '1rem', lineHeight: 2}}><b>Known Issues</b></i><br/>
                Most of the stats are accurate, but some may look incorrect compared to the stats you see in the
                ingame
                menus. For example, <i>Score per Minute</i> is always lower on XDLoadout compared to ingame. This is
                probably due to the ingame Score/Minute using <i>Total Score/Total Time Alive</i> while what the
                Ubisoft
                API provides is <i>'ingame playtime'</i> (doesn't include time in menus but includes time dead and
                maybe
                time loading into game) so that is the best stat available to calculate S/M, but it's not ideal.
                Still,
                it can be useful for <i>comparing</i> players because the stat is lower for everyone.
            </p>
            <p>
                I was really excited to discover a way to compute <i>Kill/Death Ratio</i>, something I haven't seen
                on
                other
                stat trackers. The reason it isn't available on other trackers is that you need to piece it together
                from other stats (the API doesn't provide total deaths). For this reason, the stat doesn't seem to
                be
                perfectly accurate and can be higher or lower than what you see in game but it is usually pretty
                close.
                You will especially be able to see that after a string of good/bad games your K/D will rise/drop
                accordingly. There's a small possibility that the K/D on XDLoadout is more accurate than the one
                ingame
                (some people report the ingame stats not changing for many days) but I am going to assume ingame is
                the
                truth.
            </p>
            <p>
                <i style={{fontSize: '1rem', lineHeight: 2}}><b>Future</b></i><br/>
                Of course, I will be looking for ways to improve the accuracy of stats. A lot of this depends on
                Ubisoft updating their API, as some stats are clearly broken (kills by weapon doesn't track well at
                all)
                and some stats are missing (total deaths would surely make K/D more accurate). I think including
                these
                imperfect stats for now is better than nothing anyway. Please let me know on twitter how your stats
                compare to your ingame stats and that could help me fine tune the calculations!
            </p>
            <p>
                This is just the beginning, in the future there will be ways to compare your stats against other
                XDLoadout users, find loadouts based on player stats and much more.
            </p>
        </>
    }
]