import {
    AppBar,
    Avatar,
    Badge,
    Box,
    Button,
    Container,
    IconButton,
    Stack,
    Toolbar,
    Typography,
    useMediaQuery
} from "@mui/material";
import {withStyles} from "@mui/styles";
import TwitterIcon from '@mui/icons-material/Twitter';
import XDLoadoutLogo from "./components/XDLoadoutLogo";
import {useIsMobileHook} from "./utils";
import MobileMenu from "./MobileMenu";
import InfoIcon from '@mui/icons-material/Info';
import PeopleIcon from '@mui/icons-material/People';
import MainBg from './assets/main-bg.png'
import LoginButton from "./LoginButton";
import React, {useEffect, useState} from "react";
import {Link, Outlet} from "react-router-dom";
import ReleaseCountdown from "./components/ReleaseCountdown";
import {Construction, EmojiEvents, MilitaryTech, QueryStats, Update} from "@mui/icons-material";
import DesktopMainBannerAds from "./components/ads/DesktopMainBannerAds";
import AdBlockMessage from "./components/ads/AdBlockMessage";
import {useTranslation} from "react-i18next";
import {Languages, Namespaces} from "./config/localisation/Localisation";
import MobileAnchorAd from "./components/ads/MobileAnchorAd";
import DesktopHorizontalBannerAd from "./components/ads/DesktopHorizontalBannerAd";


const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    pageButton: {
        backgroundColor: 'rgb(0,0,0,0)',

    }
});

const style = withStyles(styles)

const pages = (full = true) => [
    {
        name: "weaponBuilder",
        path: "/builder",
        icon: <Construction/>
    },
    {
        name: "meta",
        path: "/meta",
        highlight: "New",
        icon: <EmojiEvents/>
    },
    {
        name: "statsTracker",
        path: "/stats",
        highlight: "New",
        icon: <QueryStats/>
    },
    {
        name: "leaderboards",
        path: "/leaderboards/rank",
        highlight: "New",
        icon: <MilitaryTech/>
    },
    ...(full ? [
            {
                name: "patchNotes",
                path: "/patch-notes",
                icon: <PeopleIcon/>
            },
            {
                name: "profiles",
                path: "/profiles/featured",
                icon: <PeopleIcon/>
            },
            {
                name: "more",
                // highlight: "DOT",
                children: [
                    {
                        name: "updates",
                        path: "/updates",
                        // highlight: "DOT",
                        icon: <Update/>
                    },
                    {
                        name: "about",
                        path: "/about",
                        icon: <InfoIcon/>
                    },
                ]
            }
        ] :
        [{
            name: "more",
            highlight: "DOT",
            children: [
                {
                    name: "profiles",
                    path: "/profiles/featured",
                    icon: <PeopleIcon/>
                },
                {
                    name: "patchNotes",
                    path: "/patch-notes",
                    icon: <PeopleIcon/>
                },
                {
                    name: "updates",
                    path: "/updates",
                    highlight: "DOT",
                    icon: <Update/>
                },
                {
                    name: "about",
                    path: "/about",
                    icon: <InfoIcon/>
                },
            ]
        }])
]
const NavItem = ({page, child = false}) => {
    const {t} = useTranslation(Namespaces.pages)
    const [expanded, setExpanded] = useState(false)

    const hasChildren = (page.children ?? []).length > 0

    return <Badge key={`page-${t(page.name)}`} badgeContent={page.highlight}
                  color="error" variant={page.highlight === 'DOT' ? 'dot' : null}
                  invisible={expanded}
                  sx={{
                      mr: page.highlight ? '10px' : 0,
                      "& .MuiBadge-badge": {
                          m: '10px 10px 0 0',
                          fontSize: page.highlight === 'DOT' ? null : 11,
                          height: page.highlight === 'DOT' ? null : 15,
                          minWidth: page.highlight === 'DOT' ? null : 15
                      }
                  }}>
        <Link to={page.path ?? null}>
            <Button variant='text' key={t(page.name)}
                    onMouseEnter={hasChildren ? () => setExpanded(true) : null}
                    onMouseLeave={hasChildren ? () => setExpanded(false) : null}
                    sx={{
                        m: child ? '0 5px' : '5px',
                        background: 'none',
                        color: 'white',
                        fontSize: '1rem',
                        textTransform: page.name === 'statTrack' ? 'none' : 'none',//: null,
                        transition: 'text-shadow 0.3s ease',
                        '&:hover': {
                            textShadow: '#ffffffcc 0 0 10px',
                            background: 'none'
                        },

                    }}>
                {t(page.name)}
                {hasChildren ?
                    <Stack position='absolute' top={0} left='105%' backgroundColor='rgba(0,0,0,0.5)'
                           sx={{transition: 'max-height 0.3s ease', WebkitBackdropFilter: 'blur(2px)', backdropFilter: 'blur(2px)'}}
                           borderRadius='15px' width='max-content' height='max-content' maxHeight={expanded ? '180px' : 0} overflow='clip'>
                        {page.children.map(p => <NavItem child page={p} key={`nav-child-${p.name}`}/>)}
                    </Stack> : null}
            </Button>
        </Link>
    </Badge>;
}

const NavBar = () => {
    const isMobile = useIsMobileHook()
    const fullNavBar = useMediaQuery('(min-width: 1200px)')

    return isMobile ? null :
        <Stack direction='row' sx={{pl: '25px'}}>
            {pages(fullNavBar).map((page) =>
                <NavItem page={page}/>)}
        </Stack>
}

export const MainGradient = 'linear-gradient(170deg, #3c3c40 0%, #1F1F21 80%)';

const LanguageSelect = () => {
    const {i18n} = useTranslation()
    const [expanded, setExpanded] = useState(false)
    const current = Languages[i18n.resolvedLanguage]

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang)
        localStorage.setItem('XDL_LANG', lang)
    }

    useEffect(() => {
        const savedLanguage = localStorage.getItem('XDL_LANG')
        if (Languages[savedLanguage] != null) i18n.changeLanguage(savedLanguage)
    }, [])

    return <Stack direction='row' alignItems='center' backgroundColor='rgba(20,20,20,0.8)' p='5px 4px' mt='8px'
                  borderRadius='15px'
                  position='relative' sx={{cursor: 'pointer'}} onClick={() => setExpanded(!expanded)}>
        <Avatar src={`https://hatscripts.github.io/circle-flags/flags/${current.flag}.svg`}
                sx={{height: '25px', width: '25px', m: '0 5px'}}/>
        <Typography variant='smallHead' color='white' p='0 5px'>{current.name}</Typography>
        <Stack direction='row' position='absolute' top={0} left={'110%'}
               width={'max-content'} maxWidth={expanded ? '600px' : 0} overflow='clip'
               sx={{transition: 'max-width 0.3s'}}>
            {Object.values(Languages).filter(l => l.locale !== current.locale)
                .map(l => {
                    return <Stack direction='row' alignItems='center' borderRadius='15px' p='5px 4px'
                                  backgroundColor='rgba(20,20,20,0.8)'
                                  sx={{cursor: 'pointer'}} onClick={() => changeLanguage(l.locale)}>
                        <Avatar src={`https://hatscripts.github.io/circle-flags/flags/${l.flag}.svg`}
                                sx={{height: '25px', width: '25px', m: '0 5px'}}/>
                        <Typography variant='smallHead' color='white' p='0 5px'>{l.name}</Typography>
                    </Stack>
                })}
        </Stack>
    </Stack>;
}

function App({classes}) {
    const {t} = useTranslation(Namespaces.misc)
    const isMobile = useIsMobileHook()
    const [secretCounter, setSecretCounter] = useState(0)
    const [adsBlocked, setAdsBlocked] = useState(false)

    useEffect(() =>
        setTimeout(() => {
            setAdsBlocked((window.aimDownSightBlocked) && !isMobile)
        }, 2000), [isMobile, setAdsBlocked]
    )

    return (
        <Box sx={{
            backgroundImage: 'linear-gradient(170deg, #3c3c40 0%, #1F1F21 95%)',
            height: '100%',
            minHeight: '100vh'
        }}>
            <Box sx={{
                height: '100%', width: '100%', position: 'absolute', backgroundImage: `url(${MainBg})`,
                backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%',
                overflow: 'auto', '&::-webkit-scrollbar': {display: 'none'}
            }}>
                <Box sx={{flexGrow: 1, paddingBottom: isMobile ? 0 : '10px'}}>
                    <AppBar component='nav' sx={{backgroundColor: '#28282b',}}>
                        <div style={{position: 'relative'}}>
                            <div style={{
                                position: 'absolute',
                                top: 0, left: 0,
                                backgroundImage: 'linear-gradient(135deg, #3c3c40 10%, #1F1F21 90%, #000000 100%)',
                                clipPath: 'polygon(0% 0%, 100% 0%, calc(100% - 64px) 100%, 0% 100%)',
                                width: isMobile ? '30vw' : '1200px',
                                height: isMobile ? '56px' : '64px',
                            }}/>
                            <div style={{
                                position: 'absolute',
                                top: 0, right: 0,
                                backgroundImage: 'linear-gradient(315deg, #3c3c40 0%, #1F1F21 85%, #000000 100%)',
                                clipPath: 'polygon(64px 0%, 100% 0%, 100% 100%, 0% 100%)',
                                width: isMobile ? '30vw' : '200px',
                                height: isMobile ? '56px' : '64px',
                            }}/>
                        </div>
                            <Toolbar>
                                <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
                                    <Stack direction='row' alignItems='center'>
                                        <MobileMenu navItems={pages()}/>
                                        <XDLoadoutLogo height={isMobile ? '32px' : '40px'}/>
                                        <IconButton aria-label="twitter" disableRipple
                                                    onClick={() => window.open('https://twitter.com/XDLoadout')}>
                                            <TwitterIcon sx={{color: 'white', ml: '5px'}}/>
                                        </IconButton>
                                        <NavBar/>
                                    </Stack>
                                    <ReleaseCountdown/>
                                    <LoginButton/>
                                </Stack>
                            </Toolbar>
                            <AdBlockMessage adsBlocked={adsBlocked}/>
                    </AppBar>
                </Box>
                <Container sx={{p: {xs: '0 10px', md: '0 24px'}, maxWidth: {md: '1350px'}}}>
                    <DesktopMainBannerAds/>
                    <Stack display='relative'>
                        <div className={classes.appBarSpacer}></div>
                        {adsBlocked ? <div style={{height: '25px'}}/> : null}
                        <Stack sx={{minHeight: {xs: '80vh', md: '85vh'}}}>
                            <Outlet/>
                        </Stack>
                        <DesktopHorizontalBannerAd adSlot='footer'/>
                        <Stack alignItems='center' alignSelf='center' position='relative'>
                            <LanguageSelect/>
                            <Typography variant='body2' textAlign='center'
                                        onClick={() => setSecretCounter(secretCounter + 1)}
                                        sx={{padding: '5px 0 0 0', fontStyle: 'oblique', color: 'text.primary'}}>
                                © {new Date().getYear() + 1900} - XDLoadout<br/>
                            </Typography>
                            <Typography sx={{
                                pb: '5px',
                                textAlign: 'center',
                                fontStyle: 'oblique',
                                color: 'text.primary',
                                fontSize: '0.7rem'
                            }}>
                                {t('bottomDisclaimer')}
                            </Typography>
                            {window.config?.ccpa ? <span data-ccpa-link="1"></span> : null}
                        </Stack>
                    </Stack>
                    <MobileAnchorAd/>
                </Container>
            </Box>
        </Box>
)
}

export default style(App)
