import {Stack} from "@mui/material";
import RandomiseIcon from '@mui/icons-material/Casino';
import {randomInt} from "../../utils";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import GlowButton from "../../components/GlowButton";
import * as PropTypes from "prop-types";
import {Namespaces} from "../../config/localisation/Localisation";
import {useTranslation} from "react-i18next";

const randomiseAttachments = (weapon, availableAttachments, setSelectedAttachments) => {
    const slots = Object.keys(availableAttachments).filter((key) => availableAttachments[key].length > 0)
    while (slots.length > 5) {
        slots.splice(randomInt(slots.length), 1);
    }

    if (weapon.type === 'Sniper Rifle' && !slots.includes('Optic')) {
        slots[0] = 'Optic'
    }

    const randomAttachments = {}
    slots.forEach((slot) => randomAttachments[slot] = availableAttachments[slot][randomInt(availableAttachments[slot].length)])
    setSelectedAttachments({...randomAttachments})
}

function ResetAttachments(props) {
    const {t} = useTranslation(Namespaces.builder)
    return <GlowButton sx={{mr: "20px"}} onClick={props.onClick} label={t('resetAttachments')} icon={<RestartAltIcon/>}/>;
}

ResetAttachments.propTypes = {onClick: PropTypes.func};
const GunsmithBottomBar = ({selectedLoadout, setSelectedAttachments, availableAttachments}) => {
    const {t} = useTranslation(Namespaces.builder)
    return <Stack direction='row' sx={{width: '100%', justifyContent: 'flex-end'}}>
        <Stack direction='row' sx={{display: selectedLoadout?.weapon == null ? 'none' : null, alignItems: 'center', p: '4px 10px'}}>
            <ResetAttachments onClick={() => setSelectedAttachments({})}/>
            <GlowButton onClick={() => randomiseAttachments(selectedLoadout?.weapon, availableAttachments, setSelectedAttachments)} label={t('randomAttachments')} icon={<RandomiseIcon/>}/>
        </Stack>
    </Stack>
}

export default GunsmithBottomBar;
