import InfoIcon from "@mui/icons-material/InfoOutlined";
import {Tooltip} from "@mui/material";

function InfoTooltip({text}) {
    return (
        <Tooltip disableFocusListener title={text}>
            <InfoIcon sx={{fontSize: '16px', padding: '0 0 10px 3px'}}/>
        </Tooltip>
    )
}

export default InfoTooltip