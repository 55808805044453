import {Stack, Typography} from "@mui/material";
import DamageRanges from "./DamageRanges";
import Head from "../assets/multi/head.png";
import Chest from "../assets/multi/chest.png";
import Body from "../assets/multi/body.png";
import {useState} from "react";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';


const BodyPartMultiplier = ({part, multi, onClick, selected}) => {
    return <div onClick={onClick} style={{
        backgroundImage: `url(${part})`,
        height: '25px',
        width: '95%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        textAlign: 'center',
        userSelect: 'none',
        display: 'flex',
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        {selected ? <ArrowRightIcon sx={{height: '20px', width: '20px', pt: '4px', mr:'-3px', textShadow: '0 0 10px black'}}/> : null}
        <Typography fontSize='0.75rem' sx={{pt: '5px', mr: selected ? '17px' : null, textShadow: selected ? '0 0 10px black, 0 0 10px black, 0 0 10px black, 0 0 10px black' : null}}>{`${multi}x`}</Typography>
    </div>
}


const DamageRangeMultipliers = ({customWeapon, baseWeapon}) => {
    const [selected, setSelected] = useState('body')
    return <Stack direction='row' alignItems='center' justifyContent={'center'} sx={{
        p: '5px 0',
        backgroundColor: 'rgba(100,100,100,0.25)',
        borderRadius: '6px',
    }}>
        <DamageRanges customWeapon={customWeapon} baseWeapon={baseWeapon} selectedPart={selected}/>
        <Stack alignItems='center' width='35%'>
            <BodyPartMultiplier part={Head} multi={customWeapon?.multipliers?.head ?? 1} onClick={() => setSelected('head')} selected={selected==='head'}/>
            <BodyPartMultiplier part={Chest} multi={customWeapon?.multipliers?.chest ?? 1} onClick={() => setSelected('chest')} selected={selected==='chest'}/>
            <BodyPartMultiplier part={Body} multi={customWeapon?.multipliers?.body ?? 1} onClick={() => setSelected('body')} selected={selected==='body'}/>
        </Stack>
    </Stack>
}

export default DamageRangeMultipliers