import { useState, useContext } from "react"
import { Stack } from '@mui/material';
import UserStats, { StatsSpans, Stats } from "./UserStats";
import { StatsTrackingContext, StatsButtons } from './StatsTracking';
import CareerGraphPanel from "./CareerGraph";
import DesktopHorizontalBannerAd from './../../../components/ads/DesktopHorizontalBannerAd';
import MobileAd from "../../../components/ads/MobileAd";
import { Panel } from './../../StatTrackPage';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespaces } from "../../../config/localisation/Localisation";

const CareerPanel = () => {
  const { t } = useTranslation(Namespaces.playerStats)
  const { stats, setStats } = useContext(StatsTrackingContext)

  const [currentGraphStat, setCurrentGraphStat] = useState(Stats[0])
  const [statsSpan, setStatsSpan] = useState(StatsSpans.CAREER)

  return <Stack width='100%' alignItems='center'>
    <Panel>
      <Typography variant='small' textAlign='center'>{t('trackingCareerNote')}</Typography>
    </Panel>
    <UserStats expanded isOwnProfile={true} stats={stats} statsSpan={statsSpan}
      setStatsSpan={setStatsSpan} />
    {/*<Target data={statsData}/>*/}
    <DesktopHorizontalBannerAd adSlot='stat-tracking' />
    <MobileAd nitroSlot='stat-tracking-career' />
    <CareerGraphPanel stats={stats} currentStat={currentGraphStat} statsSpan={statsSpan} />
    <StatsButtons setStat={setCurrentGraphStat} stat={currentGraphStat} filters={['panel']} />
  </Stack>
}

export default CareerPanel
