import {Grid, Paper, Stack, styled, Typography} from "@mui/material";
import {useContext, useEffect, useState, useMemo} from "react";
import WeaponStats from "./WeaponStats";
import MobileAttachments from "./attachments/MobileAttachments";
import MobileWeapons from "./MobileWeapons";
import TTKGraph from "./TTKGraph";
import xdiLogo from "../assets/partners/xdi.png";
import {allAttachments} from "./AttachmentData";
import {allWeapons} from "./WeaponData";
import {Base64} from "js-base64";
import {useIsMobileHook} from "../utils";
import {UserContext} from "../context/UserContext";
import _ from "underscore";
import {FeedbackContext, FeedbackType} from "../context/UserFeedback";
import Gunsmith from "./gunsmith/Gunsmith";
import {addLoadouts, deleteLoadouts, updateLoadouts} from "../api/user/UpdateUser";
import {SettingsContext} from "../context/SettingsContext";
import {IS_LOGGED} from "../auth/AuthHandler"
import Cookies from "js-cookie";
import {getLoadout} from "../api/loadouts/Loadouts";
import {useTranslation} from "react-i18next";
import {Namespaces} from "../config/localisation/Localisation";

export const ItemPaper = styled(Paper)({
    elevation: 2,
    margin: '5px',
    padding: '8px',
})

const emptyAttachment = () => {
    return {
        rateOfFire: 1,
        dmgRanges: [{range: 1, modifier: 1}, {range: 2, modifier: 1}],
        adsTime: 1,
        sprintToFire: 1,
        moveSpeed: 1,
        adsMoveSpeed: 1,
        magSize: 0,
        reloadTime: 1,
        recoil: {vertical: 1, horizontal: 1, recovery: 1},
        aimStability: 1,
        hipfire: 1,
        minimapVisibilityDuration: 1,
        penetration: 1,
        damage: 1,
        headshotDamage: 0
    }
}

export function decodeLoadoutId(loadoutId) {
    const loadout = decodeURIComponent(Base64.decode(loadoutId)).split('$')
    let loadoutWeapon = allWeapons.find((w) => w.name === loadout[0]);

    let attachmentIds = loadout[1].split(',')
    let loadoutAttachments = {}
    Object.keys(allAttachments).forEach((slot) => {
        let attachment = allAttachments[slot].find((a) => attachmentIds.includes(a.id))
        if (attachment != null && loadoutWeapon.attachments.includes(attachment.id)) loadoutAttachments[slot] = attachment
    })
    return {loadoutAttachments, loadoutWeapon}
}

const SAVED_WEAPONS = 'xdl-saved-weapons';

function getSavedWeaponsFromLocalStorage() {
    return localStorage.getItem(SAVED_WEAPONS) != null ? JSON.parse(localStorage.getItem(SAVED_WEAPONS)) : []
}

export function compoundAttachment(attachments) {
    return Object.values(attachments)
        .filter((a) => a != null)
        .reduce((prev, current) => {
                let compound = {...prev}
                compound.rateOfFire += (current.effects?.rateOfFire ?? 0)
                compound.dmgRanges.forEach((dmgRange) => dmgRange.modifier += (current?.effects?.dmgRanges?.find((r) => r.range === dmgRange.range)?.modifier ?? 0))
                compound.damage += (current.effects?.damage ?? 0)
                compound.minimapVisibilityDuration = Math.max(0, compound.minimapVisibilityDuration + (current.effects?.minimapVisibilityDuration ?? 0))
                compound.sprintToFire += (current.effects?.sprintToFire ?? 0)
                compound.adsTime += (current.effects?.adsTime ?? 0)
                compound.aimStability += (current.effects?.aimStability ?? 0)
                compound.moveSpeed += (current.effects?.moveSpeed ?? 0)
                compound.adsMoveSpeed += (current.effects?.adsMoveSpeed ?? 0)
                compound.hipfire += (current.effects?.hipfire ?? 0)
                compound.reloadTime += (current.effects?.reloadTime ?? 0)
                compound.magSize += (current.effects?.magSize ?? 0)
                compound.recoil.vertical += (current.effects?.recoil?.vertical ?? 0)
                compound.recoil.horizontal += (current.effects?.recoil?.horizontal ?? 0)
                compound.recoil.recovery += (current.effects?.recoil?.recovery ?? 0)
                compound.headshotDamage += (current.effects?.headshotDamage ?? 0)
                return compound
            },
            emptyAttachment());
}

function applyCompoundedAttachment(weapon, compoundedAttachment, settings) {
    if (weapon == null) return {}
    let customWeapon = {...weapon}
    customWeapon.damageRanges = weapon.damageRanges.map((dmgRange, index) => {
        let newDmgRange = {...dmgRange}
        newDmgRange.modifier = (compoundedAttachment.damage ?? 1)
        newDmgRange.range *= (compoundedAttachment.dmgRanges[index]?.modifier ?? 1) - settings.rangePenalty
        newDmgRange.range = Math.round(newDmgRange.range)
        newDmgRange.damages = {base: 0, body: 0, chest: 0, head: 0}
        Object.keys(newDmgRange.damages).forEach((part) => {
            const bodyPartMultiplier = customWeapon.multipliers != null ? (customWeapon.multipliers[part] ?? 1) : 1;
            newDmgRange.damages[part] = newDmgRange.damage * bodyPartMultiplier
            newDmgRange.damages[part] *= ((compoundedAttachment.damage ?? 1) + (part === 'head'? compoundedAttachment.headshotDamage : 0))
            newDmgRange.damages[part] = Math.round(newDmgRange.damages[part])
            if (customWeapon.pellets && part !== 'base') newDmgRange.damages[part] *= (customWeapon.pellets - settings.pelletsMissed)
        })
        newDmgRange.damage *= (customWeapon.multipliers?.body ?? 1)
        newDmgRange.damage *= (compoundedAttachment.damage ?? 1)
        newDmgRange.damage = Math.round(newDmgRange.damage)
        if (customWeapon.pellets) newDmgRange.damage *= (customWeapon.pellets - settings.pelletsMissed)
        return newDmgRange
    })
    customWeapon.rateOfFire = weapon.rateOfFire * compoundedAttachment.rateOfFire
    customWeapon.minimapVisibilityDuration = weapon.minimapVisibilityDuration * compoundedAttachment.minimapVisibilityDuration
    customWeapon.sprintToFire = weapon.sprintToFire * compoundedAttachment.sprintToFire
    customWeapon.adsTime = weapon.adsTime * compoundedAttachment.adsTime
    customWeapon.aimStability = weapon.aimStability * compoundedAttachment.aimStability
    customWeapon.moveSpeed = weapon.moveSpeed * compoundedAttachment.moveSpeed
    customWeapon.adsMoveSpeed = weapon.adsMoveSpeed * compoundedAttachment.adsMoveSpeed
    customWeapon.aimStability = weapon.aimStability * compoundedAttachment.aimStability
    customWeapon.hipfire = weapon.hipfire * compoundedAttachment.hipfire
    customWeapon.reloadTime = weapon.reloadTime * compoundedAttachment.reloadTime
    customWeapon.magSize = weapon.magSize + compoundedAttachment.magSize
    customWeapon.recoil = {...weapon.recoil}
    customWeapon.recoil.vertical = weapon.recoil.vertical * compoundedAttachment.recoil.vertical
    customWeapon.recoil.horizontal = weapon.recoil.horizontal * compoundedAttachment.recoil.horizontal
    customWeapon.recoil.recovery = weapon.recoil.recovery * compoundedAttachment.recoil.recovery
    return customWeapon
}

export const buildWeapon = (weapon, attachments, settings) => {
    if (weapon == null) return {}
    let compoundedAttachment = compoundAttachment(attachments)
    let customWeapon = applyCompoundedAttachment(weapon, compoundedAttachment, settings);
    return {compoundedAttachment, customWeapon};
}

export const decodeWeapons = (weapons) => {
    return weapons.map((w) => {
        if (w.loadoutId != null) {
            const {loadoutAttachments, loadoutWeapon} = decodeLoadoutId(w.loadoutId)
            return {...w, weapon: loadoutWeapon, attachments: loadoutAttachments}
        }
        return w
    });
}

function loadUserSavedWeapons(user, setSavedWeapons, savedWeapons) {
    let weapons
    if (user) {
        if (user.loadouts) {
            weapons = [...user.loadouts]
        } else {
            weapons = [...savedWeapons]
        }
    } else if (!Cookies.get(IS_LOGGED)) {
        weapons = [...getSavedWeaponsFromLocalStorage()]
    }
    if (weapons) setSavedWeapons(decodeWeapons(weapons))
}

export const stripData = (loadout) => {
    return {...loadout, weapon: {name: loadout.weapon.name}, attachments: undefined, user: undefined}
}

export const generateLoadoutId = (loadout) => {
    if (loadout == null) return
    let loadoutId = `${loadout.weapon?.name}$${Object.values(loadout.attachments ?? {})?.filter((a) => a != null).map((a) => a.id)}`
    return Base64.encodeURI(loadoutId).replace('=', '');
}

function WeaponBuilder({loadoutId, debugMode}) {
    const {t} = useTranslation(Namespaces.builder)
    const {user, setUser} = useContext(UserContext)
    const {triggerFeedback} = useContext(FeedbackContext)
    const {settings} = useContext(SettingsContext)
    const [selectedLoadout, setSelectedLoadoutState] = useState()
    const [savedWeapons, setSavedWeaponsState] = useState([])
    const [sharedLoadoutHandled, setSharedLoadoutHandled] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)

    const isMobile = useIsMobileHook();
    const setSelectedLoadout = (loadout) => {
        loadout.loadoutId = generateLoadoutId(loadout)
        setSelectedLoadoutState(loadout)
    }

    const updateUserLoadouts = (newLoadouts, oldLoadouts) => {
        newLoadouts = newLoadouts.map(l => stripData(l))
        oldLoadouts = oldLoadouts.map(l => stripData(l))

        if (_.isEqual(newLoadouts, oldLoadouts)) return

        if (oldLoadouts.length < newLoadouts.length) {
            const oldIds = oldLoadouts.map(l => l.id)
            newLoadouts = newLoadouts.filter(loadout => !oldIds.includes(loadout.id))
            addLoadouts(newLoadouts)
                .then(u => {
                    setUser({...u, icon: user.icon});
                    triggerFeedback('Loadout successfully added!', FeedbackType.SUCCESS)
                })
                .catch(() => {
                    setSavedWeaponsState([...user.loadouts])
                    triggerFeedback('Failed to update loadout. Please report this to @XDLoadout on twitter. ):', FeedbackType.ERROR)
                })
            return
        }

        if (oldLoadouts.length > newLoadouts.length) {
            const newIds = newLoadouts.map(l => l.id)
            oldLoadouts = oldLoadouts.filter(loadout => !newIds.includes(loadout.id))
            deleteLoadouts(oldLoadouts)
                .then(u => {
                    setUser({...u, icon: user.icon});
                    triggerFeedback('Loadout successfully deleted!', FeedbackType.SUCCESS)
                })
                .catch(() => {
                    setSavedWeaponsState([...user.loadouts])
                    triggerFeedback('Failed to update loadout. Please report this to @XDLoadout on twitter. ):', FeedbackType.ERROR)
                })
            return
        }

        newLoadouts = newLoadouts.filter(loadout => oldLoadouts.find(oldLoadout => _.isEqual(loadout, oldLoadout)) == null)
        if (newLoadouts.length < 1) return
        updateLoadouts(newLoadouts)
            .then(u => {
                setUser({...u, icon: user.icon});
                triggerFeedback('Loadout successfully updated!', FeedbackType.SUCCESS)
            })
            .catch(() => {
                setSavedWeaponsState([...user.loadouts])
                triggerFeedback('Failed to update loadout. Please report this to @XDLoadout on twitter. ):', FeedbackType.ERROR)
            })
    }

    const setSavedWeapons = (weapons) => {
        weapons.forEach(w => {
            if (w.id == null) w.id = crypto.randomUUID()
            if (w.loadoutId == null) w.loadoutId = generateLoadoutId(w)
        })
        setSavedWeaponsState(weapons)
        if (user) {
            updateUserLoadouts(weapons, user.loadouts)
        } else {
            if (getSavedWeaponsFromLocalStorage().length < weapons.length) {
                localStorage.setItem(SAVED_WEAPONS, JSON.stringify(weapons.map((w) => stripData(w))))
                triggerFeedback('Loadout successfully added!', FeedbackType.SUCCESS)
            }
        }
    }

    useEffect(() => {
        loadUserSavedWeapons(user, setSavedWeapons, savedWeapons)
    }, [user?.id])

    const setSelectedWeapon = (weapon) => {
        setSelectedLoadout({weapon, attachments: weapon?.type === 'Sniper Rifle' ? {Optic: allAttachments.Optic.find(o => o.id === 'o7')} : {}})
    }

    const setSelectedAttachments = (attachments = {}) => {
        setSelectedLoadout({...selectedLoadout, attachments})
    }

    if (loadoutId != null && !sharedLoadoutHandled) { //TODO: this should be useEffect
        if (loadoutId.includes('-')) {
            getLoadout(loadoutId).then(loadout => {
                let {loadoutAttachments, loadoutWeapon} = decodeLoadoutId(loadout.loadoutId);
                setSelectedLoadout({...loadout, weapon: loadoutWeapon, attachments: loadoutAttachments})
                setSharedLoadoutHandled(true)
                setDialogOpen(true)
            })
        } else {
            let {loadoutAttachments, loadoutWeapon} = decodeLoadoutId(loadoutId);
            setSelectedLoadout({weapon: loadoutWeapon, attachments: loadoutAttachments, loadoutId})
            setSharedLoadoutHandled(true)
            setDialogOpen(true)
        }
    }

    let {customWeapon} = useMemo(() => buildWeapon(selectedLoadout?.weapon, selectedLoadout?.attachments, settings), [selectedLoadout, settings])

    return (
        <div>
            <Grid container spacing={{xs: 0, md: 1}} width='100%' margin='0'>
                {
                    !isMobile ?
                        [<Grid item xs={12} md={9} key='gunsmith-grid'>
                            <Gunsmith setSelectedWeapon={setSelectedWeapon}
                                      setSelectedAttachments={setSelectedAttachments}
                                      dialogOpen={dialogOpen}
                                      setDialogOpen={setDialogOpen}
                                      savedLoadouts={savedWeapons}
                                      setSavedLoadouts={setSavedWeapons}
                                      selectedLoadout={selectedLoadout}
                                      setSelectedLoadout={setSelectedLoadout}
                            />
                        </Grid>,
                        <Grid item xs={12} md={3} key='stats-grid'>
                            <WeaponStats customWeapon={customWeapon} baseWeapon={selectedLoadout?.weapon}/>
                        </Grid>
                        ]
                        :
                        [
                            <Grid item xs={12} md={3} key='weapons-grid'>
                                <MobileWeapons setSelectedWeapon={setSelectedWeapon}
                                               selectedLoadout={selectedLoadout} setSelectedLoadout={setSelectedLoadout}
                                               savedLoadouts={savedWeapons} setSavedLoadouts={setSavedWeapons}/>
                            </Grid>,
                            <Grid item xs={12} md={6} key='attachments-grid'>
                                <MobileAttachments selectedLoadout={selectedLoadout} setSelectedAttachments={setSelectedAttachments}
                                                   dialogOpen={dialogOpen} setDialogOpen={setDialogOpen}/>
                            </Grid>,
                            <Grid item xs={12} md={4} key='stats-grid'>
                                <WeaponStats customWeapon={customWeapon} baseWeapon={selectedLoadout?.weapon}/>
                            </Grid>
                        ]
                }
                <Grid item xs={12} >
                    <TTKGraph loadout={selectedLoadout} savedLoadouts={savedWeapons}/>
                </Grid>
            </Grid>
            <Stack direction='row' alignItems='center' justifyContent='center' sx={{paddingTop: '5px', opacity: '80%'}}>
                <Typography fontStyle='oblique' textAlign='center' fontSize='0.9rem' sx={{color: 'text.primary', padding: '0 0 7px 10px'}}>
                    {t('statsXdi')}:
                </Typography>
                <a target='_blank' href='https://xdi.gg'>
                    <img draggable={false} src={xdiLogo} height='28px' style={{padding: '8px'}} alt='xdi.gg logo'/>
                </a>
            </Stack>
        </div>
    )
}

export default WeaponBuilder;
