import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import {FeedbackContext, FeedbackType} from "../../../context/UserFeedback";
import {UserContext} from "../../../context/UserContext";
import {string} from "yup";
import {updateProfileUrl} from "../../../api/user/UpdateUser";
import {IconButton, Stack, TextField, Typography} from "@mui/material";
import {Save} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {Namespaces} from "../../../config/localisation/Localisation";

const initCountdown = (user) => {
    if (user.profileUrlUpdated == null) return 0
    return Math.min(7*24*60*60, Math.max(0, (user.profileUrlUpdated + 5000) - new Date(new Date().setDate(new Date().getDate() - 7)).getTime()) / 1000)
}

const countdownSubstring = (value, label) => value > 0 ? `${value} ${label}${value === 1 ? '' : 's'} ` : ''

const countdownString = (timeLeft) => {
    const days = Math.floor(timeLeft / (60 * 60 * 24));
    const hours = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((timeLeft % (60 * 60)) / 60);
    const seconds = Math.floor(timeLeft % 60);
    return countdownSubstring(days, 'day') + countdownSubstring(hours, 'hr') + countdownSubstring(minutes, 'min') + countdownSubstring(seconds, 'sec')
};
const ProfileUrlField = () => {
    const {t} = useTranslation(Namespaces.profiles)
    const {triggerFeedback} = useContext(FeedbackContext)
    const {user, setUser} = useContext(UserContext)
    const stringValidation = string().required().min(3).max(25).matches(/^[a-zA-Z0-9_]+$/)
    const Errors = { FAILED_VALIDATION: 0, URL_TAKEN: 1}

    const [profileUrl, setProfileUrl] = useState(user.profileUrl)
    const [focused, setFocused] = useState(false)
    const [error, setError] = useState(null)
    const [countdown, setCountdown] = useState(initCountdown(user))
    const countdownRef = useRef(initCountdown(user));

    useEffect(() => {
        countdownRef.current = countdown;
    }, [countdown]);

    useEffect(() => {
        setCountdown(initCountdown(user))
        const interval = setInterval(() => setCountdown(countdownRef.current - 1), 1000)
        return () => clearInterval(interval)
    }, [user.profileUrlUpdated])

    const update = () => {
        if (!stringValidation.isValidSync(profileUrl)) {
            setError(Errors.FAILED_VALIDATION)
            return
        }
        setError(null)
        updateProfileUrl(profileUrl)
            .then(u => {
                setUser({...u, icon: user.icon});
                triggerFeedback('Profile URL successfully updated!', FeedbackType.SUCCESS)
            })
            .catch((error) => {
                if (error.message === 'URL_TAKEN') {
                    setError(Errors.URL_TAKEN)
                } else {
                    triggerFeedback('Failed to update Profile URL. Please report this to @XDLoadout on twitter. ):', FeedbackType.ERROR)
                }
            })
    }

    const hideWhenlocked = countdown > 0 ? 'none' : null;

    return <Stack alignItems='center'>
        <Stack direction='row' justifyContent='center' alignItems='center' sx={{width: '100%', mt: '10px'}}>
            <Typography sx={{color: 'white', fontSize: '0.75rem', mr: '10px'}}>URL:</Typography>
            <Typography sx={{
                color: '#ccc',
                fontSize: '0.8rem',
                mr: '3px'
            }}>{`xdloadout.pro/profile/${countdown > 0 ? profileUrl : ''}`}</Typography>
            <TextField size='small' variant='outlined' autoComplete='off'
                       value={`${profileUrl ?? (focused ? '' : 'Profile URL')}`}
                       onFocus={() => setFocused(true)}
                       onBlur={() => setFocused(false)}
                       onChange={e => {
                           let newProfileUrl = e.target.value.trim()
                           newProfileUrl = newProfileUrl === '' ? null : newProfileUrl
                           setProfileUrl(newProfileUrl)
                       }}
                       sx={{
                           display: hideWhenlocked,
                           margin: '3px',
                           minWidth: {xs: '130px', md: '180px'},
                           maxWidth: {xs: '130px', md: '180px'},
                           input: {
                               color: profileUrl == null ? '#999' : 'white',
                               fontSize: '0.9rem',
                               padding: {xs: '2px 5px', md: null}
                           },
                           '& .MuiOutlinedInput-root': {
                               '& fieldset': {borderColor: '#aaa'},
                               '&.Mui-focused fieldset': {
                                   borderColor: 'white'
                               }
                           }
                       }} error={error}
            />
            <IconButton sx={{p: 0, display: hideWhenlocked}} onClick={update}>
                <Save sx={{color: 'white'}}/>
            </IconButton>
        </Stack>
        <Typography sx={{color: error != null ? 'error.main' : '#ccc', fontSize: {xs: '0.7rem', md: '0.8rem'}, lineHeight: {xs: '0.8rem', md: 'auto'}, mr: '10px', textAlign: 'center'}}>
            {error === Errors.FAILED_VALIDATION ? 'Must be 3-25 characters. Only letters, numbers and underscores allowed.'
                : error === Errors.URL_TAKEN ? 'URL already in use. Try another one.'
                    : countdown > 0 ? `${t('urlCountdown')}: ${countdownString(countdown)}`
                        : t('urlUpdateLimit')}
        </Typography>
    </Stack>
}

export default ProfileUrlField