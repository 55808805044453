import {List, ListItem, ListItemButton, ListItemText, Stack} from "@mui/material";
import {buildWeapon} from "../WeaponBuilder";
import {useOutsideClick} from "../../utils";
import {displayAttachmentName} from "../attachments/MobileAttachments";
import {useContext, useMemo, useState} from "react";
import {AttachmentEffects} from "../share/AttachmentsSummary";
import WeaponStats from "../WeaponStats";
import {SettingsContext} from "../../context/SettingsContext";
import {useTranslation} from "react-i18next";
import {Namespaces} from "../../config/localisation/Localisation";

const AttachmentMenu = ({
                            slot, //TODO: probably change this
                            setOpenSlot,
                            selectedLoadout,
                            availableAttachments,
                            handleAttachmentSelection,
                            flipEffects
                        }) => {
    const {t} = useTranslation(Namespaces.attachments)
    const {settings} = useContext(SettingsContext)
    const [hoveredAttachment, setHoveredAttachment] = useState(null)

    const outsideClick = useOutsideClick(() => setOpenSlot(null))

    const weaponWithHoveredAttachment = useMemo(() => {
        const newAttachments = {...selectedLoadout.attachments}
        newAttachments[slot] = hoveredAttachment
        return buildWeapon(selectedLoadout.weapon, newAttachments, settings).customWeapon
    }, [slot, selectedLoadout, hoveredAttachment, settings])

    return slot != null ?
        <Stack ref={outsideClick} sx={{
            position: 'absolute',
            top: '15px',
            left: '-8px',
            mt: '10px',
            width: '300px',
            borderRadius: '5px',
            backgroundColor: 'background.paper',
            // boxShadow: '#02020288 1px 1px 5px 3px',
            zIndex: 1200,
            '&::-webkit-scrollbar': {display: 'none'} //TODO: change
        }}>
            <div style={{position: 'relative', maxWidth: 'none', overflowX: 'visible'}}>
                <Stack sx={{
                    position: 'absolute',
                    left: flipEffects ? '-360px' : 'calc(100% + 10px)',
                    top: 0,
                    // boxShadow: '#02020288 1px 1px 5px 3px',
                    zIndex: 10,
                    width: '350px'
                }}>
                    <WeaponStats noMargin baseWeapon={selectedLoadout.weapon} customWeapon={weaponWithHoveredAttachment}/>
                </Stack>
            </div>
            <List sx={{p: 0, maxHeight: '50vh', overflowY: 'scroll'}}>
                {availableAttachments[slot].map((attachment) => (
                    <div style={{}}
                         onMouseEnter={() => setHoveredAttachment(attachment)}
                         onMouseLeave={() => setHoveredAttachment(null)}>
                        <ListItemButton
                            selected={selectedLoadout.attachments[slot] === attachment}
                            onClick={(e) => {
                                handleAttachmentSelection(attachment, slot)
                                setOpenSlot(null)
                                e.stopPropagation()
                            }}
                            sx={{p: 0}}
                        >
                            <ListItem sx={{
                                flexDirection: 'column',
                                alignItems: 'start',
                                m: '5px 10px',
                                p: '10px',
                                backgroundColor: 'rgb(100,100,100,0.1)',
                                borderRadius: '5px',
                                '&:hover': {backgroundColor: 'rgb(100,100,100,0.3)'}
                            }}
                                      key={`item-attachment-${slot}-${(displayAttachmentName(attachment))}`}>
                                <ListItemText sx={{alignSelf: 'center', fontSize: '1rem', m: 0}}
                                              primary={t(displayAttachmentName(attachment))}/>

                                <AttachmentEffects effects={attachment.effects} fontSize='0.7rem'/>
                            </ListItem>
                        </ListItemButton>
                    </div>
                ))}
            </List>
        </Stack> : null
}

export default AttachmentMenu;
