import {Box} from "@mui/material";
import RootContextProvider from "../../context/RootContextProvider";
import MainBg from "../../assets/main-bg.png";
import ProfilePage from "./ProfilePage";

const ProfilePreview = () => {

    return (
        <RootContextProvider>
            <Box sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: -100,
                overflow: 'clip',
                backgroundColor: 'background.default'
            }} width={1125} height={600}>
                <Box sx={{
                    height: '100%', width: '100%', position: 'absolute', backgroundImage: `url(${MainBg})`,
                    backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%',
                    overflow: 'clip', '&::-webkit-scrollbar': {display: 'none'}
                }}>
                    <ProfilePage/>
                </Box>
            </Box>
        </RootContextProvider>
    )
}

export default ProfilePreview