import {Skeleton, Stack, Typography} from "@mui/material";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../context/UserContext";
import LoadoutsByClass from "./profile/loadouts/LoadoutsByClass";
import {getLoadouts} from "../api/loadouts/Loadouts";
import {LoginScreen} from "../LoginButton";
import {TitleText} from "../components/Text";


const BookmarksPage = ({}) => {

    return <Stack width='100%' alignItems='center'>
        <Stack sx={{mt: {xs: 0, md: '10px'}, width: {xs: '97%', md: '80%'}}}>
            <TitleText>
                Bookmarked Loadouts
            </TitleText>
            <Stack alignItems='center'>
                <Bookmarks/>
            </Stack>
        </Stack>
    </Stack>
}

const Bookmarks = () => {
    const {user, userLoading} = useContext(UserContext)
    const [bookmarks, setBookmarks] = useState('loading')


    useEffect(() => {
        if (userLoading) {
            setBookmarks('loading')
            return
        }
        if (!user) {
            setBookmarks('noUser')
            return
        }
        if (user.bookmarkedLoadouts.length < 1) {
            setBookmarks([])
            return
        }
        getLoadouts(user.bookmarkedLoadouts).then(l => setBookmarks(l))
    }, [user?.bookmarkedLoadouts, userLoading])

    return bookmarks === 'loading' ? <BookmarkSkeleton/> :
        bookmarks === 'noUser' ?
            <Stack alignItems='center'>
                <Typography color='white'>To view bookmarks you must be signed in.</Typography>
                <Stack p='15px 20px' mt='10px' borderRadius='5px' bgcolor='background.paper' color='white'>
                    <LoginScreen/>
                </Stack>
            </Stack>
            :
            bookmarks.length < 1 ?
                <Typography color='white'>You don't have any bookmarked loadouts yet.</Typography>
                :
                <LoadoutsByClass loadouts={bookmarks} isOwnProfile={false}/>
}

const BookmarkSkeleton = () => <Stack width='100%'>
    <Skeleton variant="rounded" height='50px' sx={{mb: '15px'}}/>
    <Skeleton variant="rounded" height='50px' sx={{mb: '15px'}}/>
    <Skeleton variant="rounded" height='50px' sx={{mb: '15px'}}/>
</Stack>

export default BookmarksPage