import {lowerOffset, randomInt, useIsMobileHook} from "../../utils";
import {FormControl, InputBase, ListSubheader, MenuItem, Select, Stack, styled, Tooltip} from "@mui/material";
import {allWeapons} from "../WeaponData";
import RandomiseIcon from "@mui/icons-material/Casino";
import _ from "underscore";
import {Namespaces} from "../../config/localisation/Localisation";
import {useTranslation} from "react-i18next";

const weaponsByClass = _.groupBy(allWeapons, (weapon) => weapon.type);

const DesktopSelect = styled(InputBase)(({theme}) => ({
    width: '185px',
    '& .MuiSelect-iconOutlined': {
        color: 'white'
    },
    '& .MuiInputBase-input': {
        textAlign: 'center',
        fontSize: '1.2rem',
        padding: '5px 10px 0 10px',
        border: 'solid 1px #ddd',
        borderRadius: '5px',
        '&:focus': {
            borderColor: '#ddd',
        },
    },
}))

const MobileSelect = styled(InputBase)(({theme}) => ({
    width: '180px',
    '& .MuiSelect-iconOutlined': {
        color: 'white'
    },
    '& .MuiInputBase-input': {
        textAlign: 'center',
        fontSize: '1rem',
        padding: '0 0 0 15px',
        border: 'solid 1px #ddd',
        borderRadius: '5px',
        '&:focus': {
            borderColor: '#ddd',
        },
    },
}));


const RandomiseButton = ({label, onClick}) => {
    return <Tooltip title={label} slotProps={lowerOffset}>
        <RandomiseIcon onClick={onClick} sx={{
            transition: 'filter 0.3s ease',
            '&:hover': {
                filter: 'drop-shadow(0px 0px 6px rgb(200 200 200))',
                background: 'none'
            }
        }}/>
    </Tooltip>
}


const WeaponSelector = ({selectedLoadout, setSelectedWeapon}) => {
    const {t} = useTranslation([Namespaces.weapons, Namespaces.builder])
    const isMobile = useIsMobileHook()
    return <Stack direction='row' alignItems='center' sx={{mr: '15px'}}>
        <FormControl sx={{minWidth: 120, m: '2px 5px 5px 5px'}} size="small">
            <Select
                id="weapon-select"
                value={selectedLoadout?.weapon?.name}
                onChange={(event) => setSelectedWeapon(event.target.value)}
                renderValue={() => t(selectedLoadout?.weapon?.name ?? 'selectWeapon')}
                displayEmpty
                sx={{borderRadius: '10px'}}
                input={isMobile ? <MobileSelect/> : <DesktopSelect/>}
            >
                {
                    Object.keys(weaponsByClass).flatMap((type) => [
                        <ListSubheader key={type} dense sx={{lineHeight: '35px', pl: '10px'}}>
                            {t(type)}
                        </ListSubheader>,
                        weaponsByClass[type].map((weapon) =>
                            <MenuItem key={weapon.name} value={weapon} sx={{p: '3px 5px 3px 20px', fontSize: '0.9rem'}}>
                                {t(weapon.name)}
                            </MenuItem>
                        )]
                    )
                }
            </Select>
        </FormControl>
        <RandomiseButton label={t('randomWeapon', {ns: Namespaces.builder})}
                         onClick={() => {
                             const weapon = allWeapons[randomInt(allWeapons.length)]
                             setSelectedWeapon(weapon)
                         }}/>
    </Stack>
}

export default WeaponSelector