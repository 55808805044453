import {Stack, Typography} from "@mui/material";
import {useIsMobileHook} from "../../utils";
import XDLoadoutLogo from "../../components/XDLoadoutLogo";
import FeaturedLoadouts from "../featured/FeaturedLoadouts";
import {WeaponImage} from "../../components/WeaponImage";
import Dialog from "../../components/Dialog";

function MetaLoadouts({weapon, category, open, setOpen}) {

    const isMobile = useIsMobileHook();
    if (!weapon?.name) return null
    return (
        <Dialog open={open} setOpen={setOpen}>
            <Stack direction={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'center' : null}
                   sx={{pb: '10px', alignItems: {xs: 'center', md: 'baseline'}}}>
                <XDLoadoutLogo height='43px'
                               style={{padding: isMobile ? '5px 15px 10px 15px' : '0 60px 0 0'}}/>
                <Typography variant={isMobile ? 'h4' : 'h3'} fontStyle='oblique' fontWeight='bold'
                            sx={{color: 'white'}}>{weapon.name}</Typography>
            </Stack>
            <WeaponImage weapon={weapon.name} isMobile={isMobile} style={{
                height: 'auto',
                maxHeight: isMobile ? '130px' : '200px',
                width: isMobile ? '100%' : 'auto',
                maxWidth: isMobile ? '90%' : '800px',
                objectFit: 'contain',
                padding: isMobile ? '0 0 10px 0' : '0 30px 15px 30px'
            }}/>
            <FeaturedLoadouts weapon={weapon} category={category}/>
        </Dialog>
    )
}

export default MetaLoadouts