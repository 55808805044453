import {Stack, Typography} from "@mui/material";
import Dialog from "../../components/Dialog"
import ShareIcon from "@mui/icons-material/Share";
import {useContext} from "react";
import LoadoutShareMenu from "./LoadoutShareMenu";
import UserLoadout from "../../components/loadout/UserLoadout";
import {UserContext} from "../../context/UserContext";
import {useIsMobileHook} from "../../utils";
import {Namespaces} from "../../config/localisation/Localisation";
import {useTranslation} from "react-i18next";

function ShareLoadout({loadout, open, setOpen, label}) {
    const {t} = useTranslation(Namespaces.builder)
    const {user} = useContext(UserContext)
    const isMobile = useIsMobileHook()
    return <Stack direction='row' onClick={() => setOpen(true)}>
        {label ? <Typography
            sx={{mr: '6px', color: 'white', fontSize: '0.9rem', userSelect: 'none'}}>{t('share')}</Typography> : null}
        <ShareIcon sx={{paddingRight: '10px'}}/>
        <Dialog open={open} setOpen={setOpen}>
            <UserLoadout loadout={loadout} user={user} showImage showLogo showUserChip/>
            <LoadoutShareMenu loadout={loadout}/>
        </Dialog>
    </Stack>
}

export default ShareLoadout