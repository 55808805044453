import NitroAd, {AdSizes} from "./NitroAds";
import React from "react";
import {useIsMobileHook} from "../../utils";
import {Stack} from "@mui/material";
import {useLocation} from "react-router-dom";

const DesktopHorizontalBannerAd = ({adSlot}) => {
    const isMobile = useIsMobileHook()
    const location = useLocation()
    return isMobile ? null :
        <Stack height='100px' alignItems='center' alignSelf='center' justifyContent='center' width='100%'>
            <NitroAd key={`desk-${adSlot}-${location.key}`} adSlot={`desktop-${adSlot}`} adSizes={AdSizes.DesktopHorizontalBanner}/>
        </Stack>
}

export default DesktopHorizontalBannerAd