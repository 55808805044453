import {Grid, Typography} from "@mui/material";
import {getStatColor} from "./WeaponStat";
import {useTranslation} from "react-i18next";
import {Namespaces} from "../config/localisation/Localisation";

const rangeTitles = ["short", "mid", "long"]

const DamageRanges = ({customWeapon, baseWeapon, selectedPart}) => {
    const {t} = useTranslation(Namespaces.weaponStats)
    return (
        <Grid container sx={{ml: '16px', pb: '5px'}}>
            <Grid item display='flex' justifyContent='center' xs={12}>
                <Typography variant='smallMidHead' alignSelf='center' textAlign='center'>{t('damageRanges')}</Typography>
            </Grid>
            {baseWeapon.damageRanges?.map((rng, i) => {
                let customDamage = customWeapon.damageRanges[i].damages[selectedPart];
                return [
                    <Grid item xs={3}>
                        <Typography variant='h7'>{`${t(rangeTitles[i])}:`}</Typography>
                    </Grid>,
                    <Grid item xs={5}>
                        {
                            rng.range === customWeapon.damageRanges[i].range ?
                                <Typography variant='h7'>{`${rng.range}m`}</Typography>
                                :
                                [
                                    <Typography variant='h7' sx={{
                                        mr: '5px',
                                        color: '#888',
                                        textDecoration: 'line-through'
                                    }}>{`${rng.range}m`}</Typography>,
                                    <Typography variant='h7'
                                                color={getStatColor(false, customWeapon.damageRanges[i].range - rng.range)}>{`${customWeapon.damageRanges[i].range}m`}</Typography>
                                ]

                        }
                    </Grid>,
                    <Grid item xs={4}>
                        {
                            rng.damage === customWeapon.damageRanges[i].damages.base ?
                                <Typography variant='h7'>{`${customDamage}`}</Typography>
                                :
                                [
                                    <Typography variant='h7' sx={{
                                        mr: '5px',
                                        color: '#888',
                                        textDecoration: 'line-through'
                                    }}>{`${rng.damage}`}</Typography>,
                                    <Typography variant='h7'
                                                color={getStatColor(false, customDamage - rng.damage)}>{`${customDamage}`}</Typography>
                                ]

                        }
                    </Grid>];
            })
            }
        </Grid>
    )
}

export default DamageRanges