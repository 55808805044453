import {Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu'
import {useState} from "react";
import {useIsMobileHook} from "./utils";
import {Link} from "react-router-dom";
import {Namespaces} from "./config/localisation/Localisation";
import {useTranslation} from "react-i18next";

const MobileMenu = ({navItems}) => {
    const {t} = useTranslation(Namespaces.pages)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const isMobile = useIsMobileHook();
    navItems = navItems.flatMap(item => item.children ? item.children : item)
    return (isMobile ?
            <div>
                {<IconButton
                    size="large"
                    aria-label="menu"
                    edge="start"
                    disableRipple
                    onClick={() => setDrawerOpen(true)}
                >
                    <MenuIcon sx={{color: 'white'}}/>
                </IconButton>
                }
                <Drawer
                    anchor='left'
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                >
                    <List sx={{minWidth: '30vw'}}>
                        {navItems.map((item) =>
                            <ListItem key={item.name} disablePadding>
                                <Link to={item.path} style={{textDecoration: 'none'}}>
                                    <ListItemButton >
                                        <ListItemIcon sx={{minWidth: 0, mr: '15px', color: 'white'}}>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={t(item.name)} sx={{pl: '5px', pr: '10px', color: 'white'}}/>
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                        )}
                    </List>
                </Drawer>
            </div>
            : null
    )
}

export default MobileMenu

