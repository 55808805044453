import { Avatar, Stack } from "@mui/material"
import * as React from "react"
import { useContext, useState } from "react"
import { UserContext } from "../../context/UserContext"
import { updateProfile } from "../../api/user/UpdateUser"
import { profanityFilter, useIsMobileHook } from "../../utils"
import ProfileText from "./ProfileText"
import ProfileLoadouts from "./ProfileLoadouts"
import LocationIcon from '@mui/icons-material/LocationOn'
import UserSocials from "./UserSocials"
import { FeedbackContext, FeedbackType } from "../../context/UserFeedback"
import UserProfilePanel from "./panel/UserProfilePanel"
import UserStats, { StatsSpans } from "./stats/UserStats"
import MobileAd, { MobileSlots } from "../../components/ads/MobileAd"
import { Mouse, SportsEsports } from "@mui/icons-material"

const AVA_SIZE = { xs: 90, md: 120 }
const UserAvatar = ({ icon }) => <Avatar src={icon}
    sx={{ height: AVA_SIZE, width: AVA_SIZE, boxShadow: '0 0 10px 0 white' }} />

const ProfileInfo = ({ data, isOwnProfile }) => {

    const isMobile = useIsMobileHook()
    const { user, setUser } = useContext(UserContext)
    const { triggerFeedback } = useContext(FeedbackContext)

    const [statsSpan, setStatsSpan] = useState(StatsSpans.CAREER)

    const toggleInput = (value) => {
        user.profile.input = value
        updateProfile(user.profile)
            .then(u => {
                setUser({ ...u, icon: user.icon })
                triggerFeedback('Profile successfully updated!', FeedbackType.SUCCESS)
            })
            .catch(() => triggerFeedback('Failed to update profile. Please report this to @XDLoadout on twitter. ):', FeedbackType.ERROR))
    }

    const updateUserValue = (field) =>
        (value) => {
            if (value) value = profanityFilter.censor(value)
            user.profile[field] = value
            updateProfile(user.profile)
                .then(u => {
                    setUser({ ...u, icon: user.icon })
                    triggerFeedback('Profile successfully updated!', FeedbackType.SUCCESS)
                })
                .catch(() => triggerFeedback('Failed to update profile. Please report this to @XDLoadout on twitter. ):', FeedbackType.ERROR))
        }
    const DisplayName = <ProfileText editable={isOwnProfile} value={data.displayName}
        mandatory
        onSave={updateUserValue('displayName')} fieldName='displayName'
        maxChars={18}
        extraStringValidation={(string) => string.replace(/[^\w\sà-üÀ-Ü\-._!]+/g, '')}
        sx={{
            color: 'white',
            fontSize: isMobile && data.displayName ? data.displayName.length > 12 ? '1.6rem' : '2rem' : '3rem',
            fontWeight: 'bold',
            fontStyle: 'oblique',
            marginRight: '10px',
        }} />

    const Pronouns = <ProfileText editable={isOwnProfile} value={data.pronouns}
        onSave={updateUserValue('pronouns')} fieldName='pronouns'
        maxChars={12}
        extraStringValidation={(string) => string.replace(/[^\wà-üÀ-Ü/]+/g, '')}
        margin={'0 0 10px 0'}
        sx={{
            color: '#ccc',
            fontSize: isMobile ? '0.9rem' : '1rem',
            marginBottom: isMobile ? '10px' : 0,
            fontStyle: 'oblique'
        }} />

    const Location = <Stack direction='row' m='3px 0'>
        <LocationIcon sx={{
            color: 'white',
            pb: '3px',
            height: '20px',
            display: (data.location != null || isOwnProfile) ? null : 'none'
        }} />
        <ProfileText
            editable={isOwnProfile} value={data.location}
            onSave={updateUserValue('location')} fieldName='location'
            extraStringValidation={string => string.replace(/[^\w\sà-üÀ-Ü\-,)(]+/g)}
            maxChars={60}
            sx={{
                marginBottom: '5px',
                color: 'white',
                fontSize: isMobile ? '0.9rem' : '1rem',
                fontWeight: 'bold',
                fontStyle: 'oblique',
            }} />
    </Stack>

    const Bio = <ProfileText editable={isOwnProfile} value={data.bio} onSave={updateUserValue('bio')}
        fieldName='bio' maxChars={500}
        sx={{
            color: 'white',
            fontSize: isMobile ? '0.9rem' : '1rem',
            fontWeight: 'bold',
            fontStyle: 'oblique',
        }} />

    const Input = <Stack sx={{ marginLeft: '15px' }} onClick={() => toggleInput(data.input === 'MNK' ? 'CONTROLLER' : 'MNK')}>
        <InputIcon input={data.input}/>
    </Stack>

    return <Stack sx={{ width: { xs: '100%', md: '80%' }, mt: '10px' }}>
        <Stack direction='row' sx={{
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            width: '100%',
        }}>
            <Stack sx={{ pr: '10px', maxWidth: { xs: 'calc(100% - 90px)', md: 'calc(100% - 150px)' } }}>
                <Stack direction={{ xs: 'column', md: 'row' }} sx={{ alignItems: { xs: 'start', md: 'baseline' } }}>
                    {DisplayName}
                    {Pronouns}
                </Stack>
                <Stack direction='row'>
                    {Location}
                    {Input}

                </Stack>
                {Bio}
            </Stack>
            <UserAvatar icon={data.icon} />
        </Stack>
        <UserSocials socials={data.socials} isOwnProfile={isOwnProfile} />
        <UserStats isOwnProfile={isOwnProfile} stats={data.stats} statsSpan={statsSpan} setStatsSpan={setStatsSpan} />
    </Stack >
}

const UserProfile = ({ data, isOwnProfile }) => {
    return (
        <Stack sx={{ alignItems: 'center', mt: '5px' }}>
            <MobileAd slot={MobileSlots.HORIZONTAL_1} nitroSlot='profile-1' />
            {isOwnProfile ? <UserProfilePanel /> : null}
            <ProfileInfo data={data} isOwnProfile={isOwnProfile} />
            <ProfileLoadouts data={data} isOwnProfile={isOwnProfile} />
        </Stack>
    )
}

export default UserProfile

export function InputIcon({input}) {
    const isMobile = useIsMobileHook()
    return input === 'MNK' ?
        <Mouse sx={{ color: 'white', fontSize: isMobile ? '20px' : '26px' }} />
        :
        <SportsEsports sx={{ color: 'white', fontSize: isMobile ? '26px' : '32px' }} />
}
