import { Share } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import Chart from "chart.js/auto";
import annotationPlugin from "chartjs-plugin-annotation";
import zoomPlugin from "chartjs-plugin-zoom";
import i18n from "i18next";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import _ from "underscore";
import { Namespaces } from "../../../config/localisation/Localisation";
import { useIsMobileHook } from "../../../utils";
import { useComponentToImage } from './../../../utils';
import { StatsSpans } from "./UserStats";


function sortByDate(stats, startDate, endDate) {
    const sorted = Object.entries(stats.daily)
        .map(e => [new Date(e[0]).getTime(), e[1]])
        .filter(e => {
            return e[0] >= startDate && e[0] <= endDate
        })
        .sort((a, b) => a[0] - b[0])
    return { xValues: sorted.map(a => a[0]), yValues: sorted.map(a => a[1]) };
}

const CareerGraph = ({ stats, currentStat, statsSpan }) => {
    const isMobile = useIsMobileHook()
    const { t } = useTranslation(Namespaces.playerStats)

    const startDate = new Date(statsSpan.startDate ?? 0).getTime()
    const endDate = new Date(statsSpan.getter(stats).updated * 1000).getTime()

    const { xValues, yValues } = sortByDate(stats, startDate, endDate)

    const graphRef = useRef()


    useEffect(() => {
        Chart.register(zoomPlugin, annotationPlugin)
        const chart = new Chart(graphRef.current, {
            type: 'line',
            data: {
                labels: xValues.map(x => `${new Date(x).toDateString()}`),
                datasets: [
                    {
                        label: t(currentStat.label),
                        data: yValues.map(currentStat.graphValue ?
                            y => currentStat.graphValue(_.get(y, currentStat.path.split('.')))
                            : y => _.get(y, currentStat.path.split('.'))),
                        borderColor: 'rgba(17,123,197)',
                        fill: false,
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    // point: {
                    //     pointStyle: 'line',
                    //     radius: 0
                    // }
                },
                interaction: {
                    intersect: false,
                    axis: 'x'
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => `${context.dataset.label}: ${currentStat.revGraphValue ? currentStat.revGraphValue(context.parsed.y)
                                : Math.round(context.parsed.y * 1000) / 1000}`,
                        },
                        usePointStyle: true,
                    },
                    legend: {
                        display: false,
                        position: 'bottom',
                        labels: {
                            pointStyle: 'line',
                            usePointStyle: true,
                            color: '#ccc'
                        }
                    },
                    title: { display: false },
                    zoom: {
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            pinch: {
                                enabled: true
                            },
                            mode: 'x',
                        },
                        pan: {
                            enabled: true,
                        },
                        // limits: {
                        //     x: {min: 0, max: 150},
                        //     y: {min: 0, max: 5000}
                        // }
                    },
                    annotation: {
                        annotations: {
                            line1: {
                                type: 'line',
                                display: statsSpan === StatsSpans.CAREER && StatsSpans.S0.getter(stats) != null,
                                xMin: new Date(StatsSpans.S1.startDate).toDateString(),
                                xMax: new Date(StatsSpans.S1.startDate).toDateString(),
                                borderColor: 'rgba(255, 0, 0, 0.5)',
                                opacity: '75%',
                                borderWidth: 3,
                                z: 0,
                                label: {
                                    content: "S1",
                                    display: true,
                                    position: 'start',
                                    yAdjust: 5
                                }
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: t('date'),
                            color: '#ccc'
                        },
                        grid: {
                            color: '#333'
                        },
                        ticks: {
                            color: '#ccc'
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: `${t(currentStat.label)} ${currentStat.unit ? `(${currentStat.unit})` : ''}`,
                            color: '#ccc'
                        },
                        grid: {
                            color: '#666'
                        },
                        ticks: {
                            callback: (label, index, labels) => currentStat.revGraphValue == null ? (Math.round(label * 1000) / 1000).toLocaleString() : currentStat.revGraphValue(label),
                            color: '#ccc'
                        }
                    }
                }
            }
        })
        return () => {
            chart.destroy()
        }
    },
        [stats, startDate, endDate, currentStat, i18n.resolvedLanguage]
    )

    return <div style={{ marginTop: '10px', height: isMobile ? 300 : 500, width: isMobile ? 325 : '100%' }}>
        <canvas ref={graphRef} id="user-stats-chart" style={{ width: 750 }}></canvas>
    </div>
}

export const CareerGraphPanel = (props) => {
    return <Stack p='10px' m='10px 0' backgroundColor='rgb(0,0,0,0.25)' borderRadius='15px' border='1px solid #aaa'
        width='calc(100% - 22px)'>
        <CareerGraph {...props} />
    </Stack>
}

export default CareerGraphPanel