import {Stack, Typography} from "@mui/material";

const GlowButtonText = ({children}) =>
    <Typography sx={{
        mr: '6px',
        color: 'white',
        fontSize: '0.8rem',
        userSelect: 'none',
        textAlign: 'center',
        lineHeight: 1.1
    }}>
        {children}
    </Typography>

const GlowButtonContainer = ({sx, onClick, children}) =>
    <Stack direction='row'
           sx={{
               alignItems: 'center',
               transition: 'filter 0.3s ease',
               '&:hover': {filter: 'drop-shadow(0px 0px 5px rgb(200 200 200))', background: 'none'},
               ...sx ?? {}
           }}
           onClick={onClick}>
        {children}
    </Stack>

const GlowButton = ({sx, onClick, label, icon}) =>
    <GlowButtonContainer sx={sx} onClick={onClick}>
        <GlowButtonText>
            {label.split('|').map(l => l === '\n' ? <br key='break'/> : l)}
        </GlowButtonText>
        {icon}
    </GlowButtonContainer>

export default GlowButton