import React, {createContext, useLayoutEffect, useState} from "react";
import {getUser, IS_LOGGED} from "../auth/AuthHandler";
import Cookies from "js-cookie";

export const UserContext = createContext(null)

export const UserContextProvider = ({children}) => {
    const [user, setUser] = useState(null)
    const [userLoading, setUserLoading] = useState(false)
    const isLogged = Cookies.get(IS_LOGGED);
    useLayoutEffect(() => {
        if (window.location.pathname === '/oauth/discord' || !isLogged) return
        setUserLoading(true)
        getUser().then(response => setUser(response)).then(() => setUserLoading(false))
    }, [isLogged])
    return <UserContext.Provider value={{user, setUser, userLoading, setUserLoading}}>
        {children}
    </UserContext.Provider>
}
