import {Stack, Typography} from "@mui/material";
import React from "react";
import {Namespaces} from "../../config/localisation/Localisation";
import {useTranslation} from "react-i18next";

const AdBlockMessage = ({adsBlocked}) => {
    const {t} = useTranslation(Namespaces.misc)
    return adsBlocked ? <Stack width='100vw' height='25px' alignItems='center' justifyContent='center'
                               sx={{background: 'linear-gradient(0deg, rgba(17,123,197,1) 0%, rgba(17,123,197,1) 80%, rgba(12,50,78,1) 100%)'}}>
        <Typography variant='small' pt='2px'>{t('adblockText')}</Typography>
    </Stack> : null
}

export default AdBlockMessage