import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export const AdSizes = {
    MobileBanner: [
        [320, 100],
        [320, 50],
    ],
    MobileLarge: [
        [320, 100],
        [300, 250],
        [320, 50],
    ],
    MobileAnchor: [
        [320, 100],
        [320, 50],
    ],
    DesktopMainBanner: [
        [160, 600],
        [300, 600],
        [300, 250],
    ],
    DesktopSmallBanner: [
        [160, 600]
    ],
    DesktopHorizontalBanner: [
        [728, 90],
        [970, 90]
    ],
    NitroAnchor: 'NITRO_ANCHOR'
}

const NitroAd = ({adSlot, adSizes}) => {
    const location = useLocation()
    const slotName = `nitro-${adSlot}`

    useEffect(() => {
        try {
            if (adSizes === AdSizes.NitroAnchor) {
                window['nitroAds'].createAd(slotName, {
                    refreshLimit: 0,
                    refreshTime: 30,
                    format: "anchor",
                    sizes: [[320, 100]],
                    anchor: "bottom",
                    anchorPersistClose: false,
                    anchorBgColor: 'rgba(0,0,0,0.4)',
                    targeting: { page: location?.pathname },
                })
            } else {
                window["nitroAds"].createAd(slotName, {
                    refreshLimit: 0,
                    refreshTime: 30,
                    sizes: adSizes,
                    report: {
                        enabled: false,
                    },
                    targeting: { page: location?.pathname }
                })
            }
        } catch (e) {
            console.log(e)
        }
    }, [location, slotName, adSizes])

    return adSizes === AdSizes.NitroAnchor ? null : <div style={{pointerEvents: 'auto'}}><div  id={slotName}></div></div>

}

export default NitroAd

